import { inject, injectable } from 'inversify';

import { History } from '@vk-hr-tek/core/history';

import { SettingsCompanyUnepTypesRouter } from '../../settings/types';

@injectable()
export class SettingsCompanyUnepTypesRouterService
  implements SettingsCompanyUnepTypesRouter
{
  constructor(@inject(History) private history: History) {}

  goToUnepTypes() {
    this.history.push('/settings/unep-types');
  }
}
