import React, { Suspense } from 'react';

import { Switch } from 'react-router-dom';

import { SentryRoute as Route } from '@vk-hr-tek/core/monitoring/SentryRoute';

import { AppAbilities } from '@app/ability/ability';

import { PrivateRoute, PageLoading } from './layout';

const Auth = React.lazy(async () => import('./auth'));
const Candidates = React.lazy(async () => import('./candidates'));
const Employees = React.lazy(async () => import('./employees'));
const Events = React.lazy(async () => import('./events'));
const Organization = React.lazy(async () => import('./organization'));
const Settings = React.lazy(async () => import('./settings'));
const Vacations = React.lazy(async () => import('./vacations'));
const Policy = React.lazy(async () => import('./policy'));
const User = React.lazy(async () => import('./user'));
const Payslips = React.lazy(async () => import('./payslips'));

const resources: {
  path: string;
  resource: AppAbilities[1];
  Component: React.ComponentType;
}[] = [
  {
    path: 'profile',
    resource: 'User',
    Component: User,
  },
  {
    path: 'employees',
    resource: 'Employees',
    Component: Employees,
  },
  {
    path: 'candidates',
    resource: 'Candidates',
    Component: Candidates,
  },
  {
    path: 'policy',
    resource: 'Policy',
    Component: Policy,
  },
  {
    path: 'organization',
    resource: 'Organization',
    Component: Organization,
  },
  {
    path: 'settings',
    resource: 'Settings',
    Component: Settings,
  },
  {
    path: 'vacations',
    resource: 'Vacations',
    Component: Vacations,
  },
  {
    path: 'payslips',
    resource: 'Payslips',
    Component: Payslips,
  },
  {
    path: '',
    resource: 'Events',
    Component: Events,
  },
];

function App({ page = 'all' }: { page?: string }) {
  const routes =
    page === 'all'
      ? resources
      : resources
          .filter(({ path }) =>
            page !== 'events' ? path === page : path === '',
          )
          .map((resource) => ({ ...resource, path: '' }));

  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        <Route path="/auth">
          <Auth />
        </Route>
        <PrivateRoute path="/create-certificate" resource="User">
          <User />
        </PrivateRoute>
        {routes.map(({ path, resource, Component }) => (
          <PrivateRoute path={`/${path}`} resource={resource} key={path}>
            <Component />
          </PrivateRoute>
        ))}
      </Switch>
    </Suspense>
  );
}

export default App;
