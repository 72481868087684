import { useEffect } from 'react';

import { useLocation, useRouteMatch } from 'react-router-dom';

/** Установить заголовок страницы в браузере
 * @param {{ title: string; href: string }[]} metaTitles
 * @returns {void}
 *  */
export const useMetaTitles = (
  metaTitles: { title: string; href: string }[],
) => {
  const { pathname } = useLocation();

  const { title = '', href = 'not-found' } =
    metaTitles.find((item) => {
      if (item.href === '/') {
        return pathname === item.href;
      } else {
        return pathname.startsWith(item.href);
      }
    }) || {};

  const match = useRouteMatch(href);

  useEffect(() => {
    if (!match) {
      document.title = `VK HR Tek - Страница не найдена`;
    } else {
      document.title = `VK HR Tek${title ? ` - ${title}` : ''}`;
    }
  }, [pathname, title, href, match]);
};
