import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ breakpoints, tokens, px }) => ({
  toggleButton: {
    flex: '1 1 50%',
    overflow: 'hidden',
    position: 'relative',

    [breakpoints.up('md')]: {
      width: px(171),
    },

    '&.MuiToggleButton-root': {
      backgroundColor: tokens.colors.original.brand.secondary,
      border: 'none',
      borderRadius: tokens.radius.m,
      color: tokens.colors.text.light.primary,
      paddingBottom: tokens.spacing['8'],
      paddingLeft: tokens.spacing['16'],
      paddingRight: tokens.spacing['16'],
      paddingTop: tokens.spacing['8'],
      textTransform: 'none',

      [breakpoints.up('md')]: {
        paddingBottom: tokens.spacing['4'],
        paddingLeft: tokens.spacing['20'],
        paddingRight: tokens.spacing['20'],
        paddingTop: tokens.spacing['4'],
      },

      '&.Mui-selected': {
        backgroundColor: tokens.colors.original.brand.primary,
        color: tokens.colors.text.dark.primary,
        marginRight: px(1), // TODO - fix нет в токенах

        '& .MuiTypography-root': {
          color: tokens.colors.text.dark.primary,
        },

        '& .MuiSvgIcon-root': {
          color: tokens.colors.text.dark.primary,
        },
      },
    },

    '&.MuiToggleButtonGroup-groupedHorizontal:not(:first-child)': {
      borderRadius: tokens.radius.m,
    },

    '&.MuiToggleButtonGroup-groupedHorizontal:not(:last-child)': {
      borderRadius: tokens.radius.m,
    },
  },

  active: {
    '&:before': {
      content: "''",
      position: 'absolute',
      right: tokens.spacing['8'],
      top: tokens.spacing['4'],
      height: px(6),
      width: px(6),
      borderRadius: '50%',
      background: tokens.colors.accent.text.error,
    },
  },

  disabled: {
    '&.MuiToggleButton-root': {
      backgroundColor: tokens.colors.bg.greayscale.primary,
      color: tokens.colors.text.light.tertirary,

      '&.Mui-selected': {
        backgroundColor: tokens.colors.stroke.primary,
        color: tokens.colors.text.light.tertirary,

        '& .MuiTypography-root': {
          color: tokens.colors.text.light.tertirary,
        },
      },
    },
  },
}));
