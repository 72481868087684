/* istanbul ignore file */
import { inject, injectable } from 'inversify';

import { LocalStorage } from '@vk-hr-tek/core/local-storage';

import { UserRoleEnum } from '@app/types';

@injectable()
export class UserRoleService {
  private readonly key = 'userRole';

  constructor(
    @inject(LocalStorage) private readonly localStorage: LocalStorage,
  ) {}

  save(role: UserRoleEnum): void {
    this.localStorage.setItem(this.key, role);
  }

  get(): UserRoleEnum {
    const item = this.localStorage.getItem(this.key);
    if (item === UserRoleEnum.Company) {
      return UserRoleEnum.Company;
    }

    return UserRoleEnum.Employee;
  }
}
