import React from 'react';

import { SvgIcon } from '@material-ui/core';

export const SystemMessageIcon = () => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
        fill="#226DFE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 5.66634C11.6319 5.66634 11.3334 5.96482 11.3334 6.33301C11.3334 6.7012 11.6319 6.99967 12 6.99967C12.3682 6.99967 12.6667 6.7012 12.6667 6.33301C12.6667 5.96482 12.3682 5.66634 12 5.66634ZM12.6667 8.2192C13.4435 7.94465 14 7.20382 14 6.33301C14 5.22844 13.1046 4.33301 12 4.33301C10.8955 4.33301 10 5.22844 10 6.33301C10 7.20382 10.5566 7.94465 11.3334 8.2192V9.66634H7.33337C6.2288 9.66634 5.33337 10.5618 5.33337 11.6663V15.6663C5.33337 16.7709 6.2288 17.6663 7.33337 17.6663H16.6667C17.7713 17.6663 18.6667 16.7709 18.6667 15.6663V11.6663C18.6667 10.5618 17.7713 9.66634 16.6667 9.66634H12.6667V8.2192ZM12 10.9997H7.33337C6.96518 10.9997 6.66671 11.2982 6.66671 11.6663V15.6663C6.66671 16.0345 6.96518 16.333 7.33337 16.333H16.6667C17.0349 16.333 17.3334 16.0345 17.3334 15.6663V11.6663C17.3334 11.2982 17.0349 10.9997 16.6667 10.9997H12ZM10.3334 13.6663C10.3334 14.0345 10.0349 14.333 9.66671 14.333C9.29852 14.333 9.00004 14.0345 9.00004 13.6663C9.00004 13.2982 9.29852 12.9997 9.66671 12.9997C10.0349 12.9997 10.3334 13.2982 10.3334 13.6663ZM14.3334 14.333C14.7016 14.333 15 14.0345 15 13.6663C15 13.2982 14.7016 12.9997 14.3334 12.9997C13.9652 12.9997 13.6667 13.2982 13.6667 13.6663C13.6667 14.0345 13.9652 14.333 14.3334 14.333Z"
        fill="white"
      />
    </SvgIcon>
  );
};
