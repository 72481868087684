import { injectable } from 'inversify';
import { plainToClass } from 'class-transformer';

import { HttpService } from '@vk-hr-tek/core/http';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { Calendar } from '@vk-hr-tek/core/calendar';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';
import {
  FiltersResponse,
  Filter,
  FilterService,
  FilterType,
} from '@vk-hr-tek/core/filter';

import {
  CreateEventResponse as CreateResponse,
  EventListResponse,
  CreateEventOptionsList as OptionsResponse,
  CreateEventCompanyOptionsList as OptionsCompanyResponse,
  EmployeesList as OptionsCompanyEmployeesResponse,
  EmployeeCreatableEventTypes,
  GetEventFileHashesResponse,
  BatchEventOperationResponse as BatchSignResponse,
  CreateEventOptionsEventTypes,
  EventBatchEmployeesResponse,
  EventBatchListResponse,
  CreateEventTypeOptions,
  CreateEventTypeParticipantOptions,
} from '@app/gen/events';

import {
  GetEventsDto,
  CreateEventDto,
  UkepBatchSignDto,
  GetHashesDto,
  GetOptionsDto,
  GetOptionsEmployees,
  GetEventBatchOptionsDto,
  CreateEventBatchDto,
  GetFilterOptionsByUrlDto,
  GetCreateEventTypeParticipantOptionsDto,
  GetCreatableEventTypesForEmployeeDto,
} from '../dto';
import { GetCreateEventTypeOptionsDto } from '../dto/create/get-create-event-type-options.dto';

@injectable()
export class EventsListService {
  constructor(
    private validator: ValidationService,
    private calendar: Calendar,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
    private filterService: FilterService,
  ) {}

  async get(getEventsDto: GetEventsDto, availableFilters: Filter[]) {
    const eventsDto = plainToClass(
      this.filterService.buildDto(GetEventsDto, availableFilters),
      getEventsDto,
    );

    await this.validator.validateOrReject(eventsDto);

    const filters: FilterType = {};

    for (const filter in eventsDto.filters) {
      const filterValue = eventsDto.filters[filter];
      this.filterService.sanitizeDates(filterValue);
      filters[filter] = filterValue;
    }

    const {
      limit = 50,
      offset = 0,
      sortBy = 'created_at',
      sortOrder = 'desc',
    } = eventsDto;

    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/event/list',
        {
          pagination: {
            limit,
            offset,
            sort_by: sortBy,
            sort_order: sortOrder,
          },
          filters,
        },
        { withSide: true, reloadIfOldVersion: true },
      ),
      EventListResponse,
    );

    return {
      data: result.events,
      total: result.total_count,
      totalMy: result.total_count_my,
      totalMyTeam: result.total_count_my_group,
      finished: result.finished_count || 0,
    };
  }

  async getFilters() {
    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/available_filters',
        {},
        { withSide: true, reloadIfOldVersion: true },
      ),
      FiltersResponse,
    );

    return result.filters;
  }

  async getFilterOptionsByUrl(
    getFilterOptionsByUrlDto: GetFilterOptionsByUrlDto,
  ) {
    const { url, ...filter } = getFilterOptionsByUrlDto;

    const result = await this.http.get(url, filter, { withSide: true });

    return result;
  }

  async getCreateOptions(getOptionsDto?: GetOptionsDto) {
    return this.unmarshaller.unmarshall(
      await this.http.get(
        `/event/create_options`,
        {
          ...(getOptionsDto?.eventTypeGroup
            ? { event_type_group: getOptionsDto.eventTypeGroup }
            : {}),
          ...(getOptionsDto?.parentEventId
            ? { parent_event_id: getOptionsDto.parentEventId }
            : {}),
        },
        { withSide: true },
      ),
      OptionsResponse,
    );
  }

  async getCreateOptionsCompany() {
    return this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/create_options/companies',
        {},
        {
          withSide: true,
        },
      ),
      OptionsCompanyResponse,
    );
  }

  async getCreatableEventTypesForEmployee(
    getCreatableEventTypesForEmployeeDto: GetCreatableEventTypesForEmployeeDto,
  ) {
    return this.unmarshaller.unmarshall(
      await this.http.get(
        `/event/create_options/employees/${getCreatableEventTypesForEmployeeDto.id}/event_types`,
        {
          ...(getCreatableEventTypesForEmployeeDto?.parentEventId
            ? {
                parent_event_id:
                  getCreatableEventTypesForEmployeeDto.parentEventId,
              }
            : {}),
        },
        {
          withSide: true,
        },
      ),
      EmployeeCreatableEventTypes,
    );
  }

  async getCreateEventTypeOptions(
    getCreateEventTypeOptionsDto: GetCreateEventTypeOptionsDto,
  ) {
    await this.validator.validateOrReject(
      getCreateEventTypeOptionsDto,
      GetCreateEventTypeOptionsDto,
    );

    return this.unmarshaller.unmarshall(
      await this.http.get(
        `/event/create_options/event_type`,
        {
          event_type_id: getCreateEventTypeOptionsDto.event_type_id,
          parent_event_id: getCreateEventTypeOptionsDto.parent_event_id,
        },
        {
          withSide: true,
        },
      ),
      CreateEventTypeOptions,
    );
  }

  async getCreateEventTypeParticipantOptions(
    getCreateEventTypeParticipantOptionsDto: GetCreateEventTypeParticipantOptionsDto,
  ) {
    await this.validator.validateOrReject(
      getCreateEventTypeParticipantOptionsDto,
      GetCreateEventTypeParticipantOptionsDto,
    );

    return this.unmarshaller.unmarshall(
      await this.http.get(
        `/event/create_options/event_type/participant_options`,
        {
          event_type_id: getCreateEventTypeParticipantOptionsDto.eventTypeId,
          role_id: getCreateEventTypeParticipantOptionsDto.roleId,
          ...(typeof getCreateEventTypeParticipantOptionsDto.limit === 'number'
            ? {
                limit: getCreateEventTypeParticipantOptionsDto.limit,
              }
            : {}),
          ...(getCreateEventTypeParticipantOptionsDto.offset
            ? {
                offset: getCreateEventTypeParticipantOptionsDto.offset,
              }
            : {}),
          ...(getCreateEventTypeParticipantOptionsDto.search
            ? {
                search: getCreateEventTypeParticipantOptionsDto.search,
              }
            : {}),
        },
        {
          withSide: true,
        },
      ),
      CreateEventTypeParticipantOptions,
    );
  }

  async getCreateBatchEventTypes(getOptionsEmployees: GetOptionsEmployees) {
    await this.validator.validateOrReject(
      getOptionsEmployees,
      GetOptionsEmployees,
    );

    return this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/create_options/event_types',
        {
          company_id: getOptionsEmployees.companyId,
          batch_enabled: getOptionsEmployees.batchEnabled,
          no_employee: getOptionsEmployees.noEmployee,
          parent_event_id: getOptionsEmployees.parentEventId,
        },
        {
          withSide: true,
        },
      ),
      CreateEventOptionsEventTypes,
    );
  }

  async getCreateBatchEmployees(getBatchEmployees: GetEventBatchOptionsDto) {
    await this.validator.validateOrReject(
      getBatchEmployees,
      GetEventBatchOptionsDto,
    );

    return this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/batch/employees',
        {
          event_type_id: getBatchEmployees.eventTypeId,
          ...(getBatchEmployees.unitId
            ? { unit_id: getBatchEmployees.unitId }
            : {}),
          ...(getBatchEmployees.parentEventId
            ? { parent_event_id: getBatchEmployees.parentEventId }
            : {}),
        },
        {
          withSide: true,
        },
      ),
      EventBatchEmployeesResponse,
    );
  }

  async getExistingBatchEvents(getBatchEmployees: GetEventBatchOptionsDto) {
    await this.validator.validateOrReject(
      getBatchEmployees,
      GetEventBatchOptionsDto,
    );

    return this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/batch',
        { company_id: getBatchEmployees.companyId },
        {
          withSide: true,
        },
      ),
      EventBatchListResponse,
    );
  }

  async createBatchEvents(createEventBatchDto: CreateEventBatchDto) {
    await this.validator.validateOrReject(
      createEventBatchDto,
      CreateEventBatchDto,
      createEventBatchDto.parentEventId ? ['child'] : ['default'],
    );

    await this.http.post(
      '/event/batch',
      {
        event_type_id: createEventBatchDto.eventTypeId,
        employee_ids: createEventBatchDto.employees,
        event_batch_name: createEventBatchDto.eventBatchName,
        ...(createEventBatchDto.document
          ? { document: createEventBatchDto.document.value }
          : {}),
        ...(createEventBatchDto.documentDate && {
          document_date: this.calendar.formatISO(
            new Date(createEventBatchDto.documentDate),
            { representation: 'date' },
          ),
        }),
        ...(createEventBatchDto.documentNumber && {
          document_number: createEventBatchDto.documentNumber,
        }),
        ...(createEventBatchDto.assigned_roles && {
          assigned_roles: createEventBatchDto.assigned_roles,
        }),
        ...(createEventBatchDto.parentEventId && {
          parent_event_id: createEventBatchDto.parentEventId,
        }),
      },
      {
        withSide: true,
        isJson: false,
      },
    );
  }

  async getEventEmployees(getOptionsEmployees: GetOptionsEmployees) {
    await this.validator.validateOrReject(
      getOptionsEmployees,
      GetOptionsEmployees,
    );

    return this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/create_options/employees',
        {
          company_id: getOptionsEmployees.companyId,
          limit: getOptionsEmployees.limit,
          offset: getOptionsEmployees.offset,
          query: getOptionsEmployees.query,
        },
        {
          withSide: true,
        },
      ),
      OptionsCompanyEmployeesResponse,
    );
  }

  async createEvent(createEventDto: CreateEventDto) {
    await this.validator.validateOrReject(createEventDto, CreateEventDto, [
      'submit',
    ]);

    const employeeId =
      createEventDto.employeeId === 'no_employee'
        ? undefined
        : createEventDto.employeeId;

    const response = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/event',
        {
          event_type_id: createEventDto.eventTypeId,
          employee_id: employeeId,
          ...(createEventDto.assigned_roles && {
            assigned_roles: createEventDto.assigned_roles,
          }),
          ...(createEventDto.eventTypeId && {
            event_type_id: createEventDto.eventTypeId,
          }),
          ...(createEventDto.parentEventId && {
            parent_event_id: createEventDto.parentEventId,
          }),
        },
        { withSide: true },
      ),
      CreateResponse,
    );

    return {
      event_id: response.event_id,
    };
  }

  async getHashes(getHashesDto: GetHashesDto) {
    const result = await this.unmarshaller.unmarshall(
      await this.http.post(`/event/batch/document/hash`, getHashesDto, {
        withSide: true,
      }),
      GetEventFileHashesResponse,
    );
    return result as GetEventFileHashesResponse;
  }

  async ukepBatchSign(ukepBatchSignDto: UkepBatchSignDto) {
    const result = await this.unmarshaller.unmarshall(
      await this.http.post('/event/batch/ukep_sign', ukepBatchSignDto, {
        withSide: true,
      }),
      BatchSignResponse,
    );
    return result as BatchSignResponse;
  }

  async getDocumentCount(
    getEventsDto: GetEventsDto,
    availableFilters: Filter[],
  ) {
    const eventsDto = plainToClass(
      this.filterService.buildDto(GetEventsDto, availableFilters),
      getEventsDto,
    );

    await this.validator.validateOrReject(eventsDto);

    const filters: FilterType = {};

    for (const filter in eventsDto.filters) {
      const filterValue = eventsDto.filters[filter];
      this.filterService.sanitizeDates(filterValue);
      filters[filter] = filterValue;
    }

    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/event/document_count',
        {
          ...(filters ? { filters } : {}),
        },
        { withSide: true, reloadIfOldVersion: true },
      ),
      EventListResponse,
    );

    return {
      totalCount: result.total_count,
    };
  }
}
