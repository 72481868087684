import React, { useCallback, useState } from 'react';

import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import CancelIcon from '@material-ui/icons/Cancel';
import { Button, useTheme } from '@material-ui/core';

import { useInject } from '@vk-hr-tek/core/ioc';
import { FilesService } from '@vk-hr-tek/core/files/files.service';

import { useIsDesktop } from '../../hooks';
import { Box } from '../../Box';
import { Paper } from '../../Paper';
import { CircularProgress } from '../../CircularProgress';
import { FileInputValue } from '../FileInput';
import { FilePreview } from '../../FilePreview';
import { Typography } from '../../Typography';
import { FileError, Label } from '../common';
import { FileIcon } from '../../icons';

import useStyles from './MultipleFileInput.styles';

interface MultipleFileInputProps {
  value: FileInputValue[];
  onChange: (value: FileInputValue[]) => void;
  onBlur?: () => void;
  label: string;
  tooltip?: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  loading?: boolean;
  validate?: (
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    value: any,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    allValues?: any,
  ) => string | undefined | Promise<string | undefined>;
  cache?: (file: File) => Promise<string>;
  error?: boolean;
  helperText?: string;
}

export const MultipleFileInput = ({
  value,
  onChange,
  onBlur = () => {},
  label,
  tooltip,
  required = false,
  disabled = false,
  loading = false,
  validate,
  cache,
  error = false,
  helperText = '',
  ...rest
}: MultipleFileInputProps) => {
  const classes = useStyles();
  const filesService = useInject(FilesService);
  const isDesktop = useIsDesktop();
  const theme = useTheme();

  const [isFileLoading, setIsFileLoading] = useState(false);
  const [dropError, setDropError] = useState('');

  const isLoading = loading || isFileLoading;
  const isDisabled = disabled || isLoading;

  const load: (source: string | File) => () => Promise<Blob> = useCallback(
    (source) => async () => {
      if (typeof source === 'string') {
        const fileData = await filesService.getFile(source);
        const file = await fileData.file;

        return file;
      } else {
        return source;
      }
    },
    [filesService],
  );

  const { getRootProps, getInputProps } = useDropzone({
    disabled: isDisabled,
    onDrop: async (files) => {
      if (files && files.length) {
        if (cache) {
          setIsFileLoading(true);
          setDropError('');

          const errorText =
            (await validate?.(
              files.map((file) => ({ source: file })),
              {},
            )) || '';
          if (errorText) {
            setDropError(errorText);
            setIsFileLoading(false);
            return;
          }

          const cachedFiles = [];
          for await (const file of files) {
            try {
              const cacheId = await cache(file);
              cachedFiles.push({
                value: JSON.stringify({ type: 'file_cache', id: cacheId }),
                source: file,
              });
            } catch (e) {
              cachedFiles.push({ value: file, source: file });
            }
          }

          onChange([...value, ...cachedFiles]);

          setIsFileLoading(false);
        } else {
          const cachedFiles = files.map((file) => ({
            value: file,
            source: file,
          }));

          onChange([...value, ...cachedFiles]);
        }
      } else {
        onChange([]);
      }

      onBlur();
    },
  });

  const removeDocument = (index: number) => () =>
    onChange([...value.slice(0, index), ...value.slice(index + 1)]);

  const isInputHasFiles = !!value?.length;

  return (
    <Box className="aqa_multiple_file_input">
      {label && <Label label={label} required={required} tooltip={tooltip} />}
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        gap={isDesktop ? '24' : '8'}
        width="100%"
      >
        {isInputHasFiles &&
          value.map((file: FileInputValue, index: number) => (
            <Box
              key={index}
              position="relative"
              width={{ xs: 92, md: 140 }}
              height={{ xs: 92, md: 140 }}
            >
              {!disabled && (
                <Box
                  position="absolute"
                  top={7}
                  right={7}
                  zIndex={1}
                  width={24}
                  height={24}
                  overflow="hidden"
                  bgcolor="text.dark.primary"
                  border={2}
                  borderColor="text.dark.primary"
                  radius="round"
                  onClick={removeDocument(index)}
                >
                  <Box
                    position="absolute"
                    width={24}
                    height={24}
                    top={-2}
                    left={-2}
                  >
                    <CancelIcon color="disabled" />
                  </Box>
                </Box>
              )}
              <Paper className={classes.filePreviewPaper}>
                <FilePreview onLoad={load(file.source)} isClickable />
              </Paper>
            </Box>
          ))}
        <Box flexGrow={1}>
          {isLoading ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width={isInputHasFiles ? { xs: 92, md: 140 } : '100%'}
              height={{ xs: 92, md: 140 }}
              bgcolor="bg.light.primary"
              border={`${theme.tokens.border.s}px dashed`}
              borderColor="stroke.primary"
              radius="l"
            >
              <CircularProgress size={isInputHasFiles ? 30 : 50} />
            </Box>
          ) : (
            <div {...getRootProps()}>
              <input {...rest} {...getInputProps()} />
              <div
                className={classNames(
                  classes.dropzone,
                  isInputHasFiles && classes.dropzoneMini,
                  isDisabled && classes.disabled,
                  (error || dropError) && classes.error,
                )}
              >
                <Box mb="4" fontSize={32} height={32}>
                  <FileIcon />
                </Box>
                <Box mb={{ xs: '0', md: '8' }}>
                  <Typography
                    display="block"
                    variant="caption"
                    color={isDesktop ? 'textTertiary' : 'primary'}
                    align="center"
                  >
                    {isInputHasFiles
                      ? 'Загрузить еще'
                      : 'Загрузите изображение, PDF или DOCX файл'}
                  </Typography>
                </Box>
                {isDesktop && (
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    disabled={disabled}
                  >
                    Загрузить
                  </Button>
                )}
              </div>
            </div>
          )}
        </Box>
      </Box>
      {(error || dropError) && <FileError text={helperText} />}
    </Box>
  );
};
