import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';

import { LayoutState } from '../layout.state';

export const setServiceUnavailable = createAction<boolean>(
  'layout/setServiceUnavailable',
);

export const layoutReducers = (
  builder: ActionReducerMapBuilder<LayoutState>,
) => {
  builder.addCase(setServiceUnavailable, (state, action) => {
    state.isServiceUnavailable = action.payload;
  });
};
