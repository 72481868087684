/* eslint-disable react-hooks/exhaustive-deps */
import React, { createRef, useCallback, useEffect, useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { useInject } from '@vk-hr-tek/core/ioc';
import { Box } from '@vk-hr-tek/ui/Box';

import { BackLink, Page } from '../../../layout';
import { useDispatch, useSelector } from '../../../app/hooks';
import {
  selectUser,
  selectError,
  selectStatus,
  profileView,
  getSubstitutes,
} from '../../slice';
import { UserRouter } from '../../types';

import { GeneralInfo } from './GeneralInfo';
import { Roles } from './Roles';
import { Company } from './Company';
import { Substitutes } from './Substitutes';

enum ProfileSectionsEnum {
  SUBSTITUTES = 'substitutes',
}

export const Profile = () => {
  const user = useSelector(selectUser);
  const status = useSelector(selectStatus);
  const error = useSelector(selectError);
  const dispatch = useDispatch();
  const { hash } = useLocation();

  const location =
    useLocation<{ prev?: { pathname: string; search: string }[] }>();

  const userRouter = useInject<UserRouter>(UserRouter);

  const onClick = () => {
    if (location.state?.prev) {
      userRouter.goBack(location.state.prev?.[0]);
    } else {
      userRouter.redirectToHome();
    }
  };

  const activeProfileSection = hash.substring(1);

  const refs = useMemo(
    () =>
      Object.values(ProfileSectionsEnum).reduce((acc, profileSection) => {
        acc[profileSection] = createRef();

        return acc;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }, {} as Record<string, any>),
    [],
  );

  const handleScrollToProfileSection = useCallback(
    (profileSection: string) =>
      refs[profileSection]?.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      }),
    [refs],
  );

  useEffect(() => {
    dispatch(profileView());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSubstitutes());
  }, [dispatch]);

  useEffect(() => {
    if (activeProfileSection) {
      handleScrollToProfileSection(activeProfileSection);
    }
  }, [handleScrollToProfileSection, activeProfileSection]);

  return (
    <Page
      error={error}
      link={<BackLink onClick={onClick} title="Назад" />}
      title="Профиль"
      showTitleMobile
      status={status}
    >
      {user && (
        <>
          <Box pb="20" className="aqa_profile_general_info">
            <GeneralInfo />
          </Box>
          {user.has_company_side && user.groups && !!user.groups.length && (
            <Box pb="20" className="aqa_profile_roles">
              <Roles />
            </Box>
          )}
          <Box ref={refs[ProfileSectionsEnum.SUBSTITUTES]}>
            <Substitutes />
          </Box>
          {user.employees.map((employee, index) => (
            <Box
              pb="20"
              id={`companyId-${employee.company.id}`}
              key={employee.id}
              className="aqa_profile_company"
            >
              <Company expanded={!index} employee={employee} />
            </Box>
          ))}
        </>
      )}
    </Page>
  );
};
