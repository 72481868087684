import { IsNotEmpty, IsString } from 'class-validator';

import {
  FilterText as TextFilter,
  FilterSelect as SelectFilter,
  FilterSearch as SearchFilter,
  FilterMultiple as MultipleFilter,
  FilterBool as BoolFilter,
  FilterTimeRange as TimeRangeFilter,
  FilterMultipleSearch as MultipleSearchFilter,
} from './gen/filter';

class BaseFilter {
  @IsNotEmpty()
  @IsString()
  key: string;

  @IsNotEmpty()
  @IsString()
  name: string;
}

export {
  BaseFilter,
  TextFilter,
  SelectFilter,
  MultipleFilter,
  BoolFilter,
  TimeRangeFilter,
};

export type Filter = (
  | TextFilter
  | SelectFilter
  | SearchFilter
  | MultipleSearchFilter
  | MultipleFilter
  | BoolFilter
  | TimeRangeFilter
) & {
  fullWidth: boolean;
  width?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  newLine?: boolean;
  twoLine?: boolean;
  minInputValueLength?: number;
  disabled?: boolean;
};

export type Dateable = { start?: Date; end?: Date };

export type FilterType = Record<string, number | boolean | string | Dateable>;
