import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ breakpoints, tokens, px }) => ({
  root: {
    position: 'relative',
  },

  square: {
    paddingTop: '100%',
  },

  document: {
    width: '100%',
    '@global': {
      canvas: {
        border: 'none',
      },
    },
    borderRadius: tokens.radius.l,
    overflow: 'hidden',
    transform: 'translate3d(0,0,0)',
  },

  document_square: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    overflow: 'hidden',
  },

  image: {
    width: '100%',
  },

  image_square: {
    position: 'absolute',
    top: 0,
    left: 0,
  },

  previewDocx: {
    minHeight: px(104),
    height: '100%',
  },

  docIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: px(80),
    width: px(80),
    transform: 'translate(-50%, -50%)',
  },

  hiddenPage: {
    display: 'none',
  },

  pagination: {
    position: 'absolute',
    top: tokens.spacing['12'],
    left: tokens.spacing['12'],

    [breakpoints.up('md')]: {
      right: tokens.spacing['12'],
      left: 'auto',
    },
  },

  collapseBox: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: `${tokens.border.s}px solid ${tokens.colors.stroke.primary}`,
    padding: tokens.spacing['4'],
    background: tokens.colors.bg.light.primary,
    transform: 'translateY(100%)',
    transition: 'transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  collapseWrapper: {
    position: 'relative',

    '&:hover $collapseBox': {
      transform: 'translate(0)',
    },
  },
}));
