import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  linkButton: {
    color: theme.tokens.colors.original.brand.primary,
    textDecoration: 'none',
    background: 'none',
    border: 'none',
    outline: 'none',
    fontSize: theme.tokens.typography.variant.body3.fontSize,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default styles;
