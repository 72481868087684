import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ breakpoints, zIndex, tokens, px }) => ({
  myAppBar: {
    zIndex: zIndex.drawer + 1,
    position: 'fixed',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    position: 'relative',
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: px(64), // TODO - fix нет в токенах
    [breakpoints.up('md')]: {
      paddingTop: tokens.name === 'vk' ? px(105) : px(66), // TODO - fix нет в токенах
      paddingBottom: tokens.spacing['20'],
    },
  },
  appBar: {
    paddingLeft: tokens.spacing['20'],
    paddingRight: tokens.spacing['20'],
    [breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  vkHeader: {
    display: 'none',
    ['@media (min-width:820px)']: {
      padding: `0 ${tokens.spacing['12']}px`,
      display: 'flex',
      fontFamily: ['VK Sans Text', 'Inter', 'Arial', 'sans-serif'].join(','),
      fontSize: '0.813rem',
      borderBottom: `${tokens.border.s}px solid ${tokens.colors.stroke.primary}`,
      borderTop: `${tokens.border.s}px solid transparent`,
      height: px(37),
      alignItems: 'center',
    },
  },
  vkHeaderLogo: {
    textDecoration: 'none',
    marginRight: tokens.spacing['8'],
    marginTop: tokens.spacing['4'],
  },
  vkHeaderLink: {
    color: tokens.colors.text.light.tertirary,
    borderRadius: tokens.radius.xs,
    padding: `${tokens.spacing['4']}px ${tokens.spacing['8']}px`,
    textDecoration: 'none',
    '&:hover': {
      background: tokens.colors.bg.greayscale.secondary,
    },
  },
  header: {
    paddingLeft: tokens.spacing['20'],
    paddingRight: tokens.spacing['20'],
    [breakpoints.up('md')]: {
      paddingLeft: tokens.spacing['32'],
      paddingRight: tokens.spacing['32'],
    },
  },
  content: {
    [breakpoints.up('md')]: {
      paddingLeft: tokens.spacing['20'],
      paddingRight: tokens.spacing['20'],
    },
  },
  container: {
    flexGrow: 1,
    padding: 0,
    position: 'relative',
  },
}));
