/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject, injectable } from 'inversify';
import { serialize } from 'object-to-formdata';

import { Logger } from '../logger';
import { QueryService } from '../query';
import { UpdatorService } from '../updator';
import { AppError } from '../error';

import { RoleService, TokenService } from './types';

const RELOAD_DELAY_AFTER_REQUEST = 400;

interface RequestOptions {
  withAuth: boolean;
  withSide: boolean;
  isJson: boolean;
  withIndicies: boolean;
  timeout: number;
  reloadIfOldVersion: boolean;
}

type HeadersRequestOptions = Omit<
  RequestOptions,
  'withIndicies' | 'timeout' | 'reloadIfOldVersion'
>;

const retryOn = (status: number) => {
  return (target: HttpService, key: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;

    descriptor.value = async function (
      resource: RequestInfo,
      options:
        | (RequestInit & { timeout?: number; reloadIfOldVersion?: boolean })
        | undefined = {},
    ) {
      let response = await originalMethod.apply(this, [resource, options]);

      if (response.status === status) {
        await target.restoreToken.apply(this);

        response = await originalMethod.apply(this, [
          resource,
          {
            ...options,
            headers: {
              ...options.headers,
              Authorization: `Bearer ${target.getToken.apply(this)}`,
            },
          },
        ]);
      }

      return response;
    };

    return descriptor;
  };
};

@injectable()
export class HttpService {
  private host = window.REACT_APP_VKHRTEK_API_URL;

  private numberRequests = 0;

  private requiredReloadUrlExists = false;

  constructor(
    private query: QueryService,
    @inject(TokenService) private token: TokenService,
    @inject(RoleService) private role: RoleService,
    private logger: Logger,
    private updator: UpdatorService,
  ) {}

  /** Читает body ответа (которое в видете текста JSON-формата) и преобразует к объекту
   * @param {Response} response Интерфес Fetch API представляет собой ответ на запрос
   * @returns {Promise<any>}
   *  */
  async parseJson(response: Response) {
    try {
      return await response.json();
    } catch {
      return {};
    }
  }

  /** Расширить объект ответа контекстным значением
   * Для расширения используется Proxy
   * @param response Проксируемый/расширяемый объект
   * @param {Object.<string,any>} context Контекстный объект
   * @returns {any}
   * */
  extendResponse(response: any, context: Record<string, any>) {
    return new Proxy(response, {
      get(target, prop) {
        if (prop === '__context__') {
          return context;
        }

        return target[prop];
      },
    });
  }

  /** Базовый метод для выполнения запросов к бэкенду
   * Является оберткой над браузерным fetch
   * Позволяет прервать запрос по истечении timeout
   * Управляет принудительным обновлением страницы в браузере при помощи reloadIfOldVersion из опций
   * @param {string} resource Адрес ресурса
   * @param [options={}] Опции, среди которых часто встречаются body, headers, method, timeout, reloadIfOldVersion
   * @param [log={}]
   * @returns {Promise<Response>}
   *  */
  @retryOn(401)
  async fetch(
    resource: string,
    options:
      | (RequestInit & { timeout?: number; reloadIfOldVersion?: boolean })
      | undefined = {},
    log: {
      body?: Record<string, any>;
    } = {},
  ): Promise<Response> {
    const { timeout = 0, reloadIfOldVersion = false } = options;

    const controller = new AbortController();
    const id = setTimeout(() => {
      if (timeout) {
        controller.abort();

        this.logger.info(
          `[http] [CANCELED BY TIMEOUT] ${
            options.method?.toUpperCase() || 'GET'
          } ${resource.split('?')[0]}`,
          {
            tags: {
              vkdoc_error_type: 'http',
              http_method: options.method?.toUpperCase() || 'GET',
              http_url: resource.split('?')[0],
              http_status: 'canceled',
            },
            context: {
              http_query: resource.split('?')[1]
                ? { value: resource.split('?')[1] }
                : null,
            },
          },
        );
      }
    }, timeout);

    this.numberRequests++;

    if (reloadIfOldVersion) {
      this.requiredReloadUrlExists = true;
    }

    const response = await fetch(resource, {
      ...options,
      signal: controller.signal,
    });

    clearTimeout(id);

    const version = response.headers?.get('X-App-Version');

    if (version) {
      this.updator.updateVersion(version);
    }

    this.numberRequests--;

    setTimeout(() => {
      if (this.numberRequests === 0 && this.requiredReloadUrlExists) {
        this.updator.run();
      }
    }, RELOAD_DELAY_AFTER_REQUEST);

    if (!response.ok) {
      let errorResponse: Record<string, any> = {};

      try {
        errorResponse = await response.clone().json();
      } catch (e) {}

      const traceId = response.headers.get('X-Mayday-Trace-Id');

      this.logger.info(
        `[http] [${response.status}] ${
          options.method?.toUpperCase() || 'GET'
        } ${resource.split('?')[0]}`,
        {
          tags: {
            vkdoc_error_type: 'http',
            http_method: options.method?.toUpperCase() || 'GET',
            http_status: response.status,
            http_url: resource.split('?')[0],
            http_trace_id: traceId || 'empty',
            http_error_code: errorResponse?.data?.error_code || 'empty',
            http_side: (options.headers as any)['X-Side'] || 'empty',
            http_version: (options.headers as any)['X-App-Version'] || 'empty',
          },
          context: {
            http_body: log.body
              ? {
                  value: log.body,
                }
              : null,
            http_query: resource.split('?')[1]
              ? { value: resource.split('?')[1] }
              : null,
            http_response: {
              value: errorResponse,
            },
          },
        },
      );

      throw new AppError(
        'server',
        {
          code: errorResponse.code || response.status,
          message: errorResponse.message,
          data: errorResponse.data,
        },
        traceId,
      );
    }

    return response;
  }

  /** Обновить токен
   * @returns {Promise<void>}
   * */
  async restoreToken() {
    await this.token.restoreToken();
  }

  /** Получить токен
   * Если токен отсутсвует выбросить исключение
   * @returns {string}
   *  */
  getToken(): string {
    const token = this.token.get();

    if (!token) {
      throw new AppError('client', {
        code: 401,
        message: 'Unauthorized',
        error: 'Unauthorized',
      });
    }

    return token;
  }

  /** Получить роль
   * Допустимые роли: employee (сотрудник компании) и company (предстователь со стороны компании)
   * @returns {string}
   *  */
  getRole(): string {
    const role = this.role.get();

    if (!role) {
      return 'employee';
    }

    return role;
  }

  /**
   * Представляет опции для установки заголовков
   * @typedef HeadersRequestOptionsDocs
   * @type {object}
   * @property {boolean} withAuth Определяет будет ли передаваться авторизационный заголовок, содержащий Bearer-токен
   * @property {boolean} withSide Определяет будет ли передаваться заголовок, содержащий роль
   * @property {boolean} isJson Определяет будет ли передаваться заголовок, указывающий тип отправляемых на сервер данных
   */

  /** Сформировать объект заголовков, которые будут отправлены с запросом
   * @param {HeadersRequestOptionsDocs} options
   * @returns {Object.<string, string>}
   * */
  private createHeaders({
    withAuth,
    withSide,
    isJson,
  }: HeadersRequestOptions): Record<string, string> {
    const headers: { [key: string]: string } = isJson
      ? {
          'Content-Type': 'application/json',
        }
      : {};

    if (withAuth) {
      headers.Authorization = `Bearer ${this.getToken()}`;
    }
    if (withSide) {
      headers['X-Side'] = this.getRole();
    }

    return headers;
  }

  /** Сформировать тело запроса
   * @param {Object.<string, any>} [data]
   * @param {boolean} [toJson=true] Определяет требуется ли преобразовать объект данных к JSON-формату
   * @param {boolean} [withIndicies=false]
   * @returns {string | FormData}
   *  */
  createBody(
    data?: { [key: string]: any },
    toJson = true,
    withIndicies = false,
  ): string | FormData {
    if (toJson) {
      return data ? JSON.stringify(data) : '';
    }

    return serialize(data, { indices: withIndicies });
  }

  /**
   * Представляет допустимые опции для выполнения GET запроса
   * @typedef GetRequestOptionsDocs
   * @type {object}
   * @property {boolean} [withAuth=true]
   * @property {boolean} [withSide=false]
   * @property {boolean} [isJson=true]
   * @property {number} [timeout=0]
   * @property {boolean} [reloadIfOldVersion=false]
   */

  /** Выполнить GET запрос
   * @param {string} url
   * @param {Object.<string, any>} [query]
   * @param {GetRequestOptionsDocs} [options={}]
   * */
  async get(
    url: string,
    query?: { [key: string]: any },
    {
      withAuth = true,
      withSide = false,
      isJson = true,
      timeout = 0,
      reloadIfOldVersion = false,
    }: Partial<RequestOptions> = {},
  ): Promise<unknown> {
    const headers = this.createHeaders({
      withAuth,
      withSide,
      isJson,
    });

    const response = await this.fetch(
      `${this.host}${url}${
        query && Object.keys(query).length
          ? `?${this.query.stringify(query)}`
          : ''
      }`,
      {
        headers,
        timeout,
        reloadIfOldVersion,
      },
    );

    return isJson
      ? this.extendResponse(await this.parseJson(response), {
          vkdoc_error_type: 'validation',
          http_url: url,
          http_method: 'GET',
          http_trace_id: response.headers.get('X-Mayday-Trace-Id'),
          http_side: headers['X-Side'] || 'empty',
          http_version: headers['X-App-Version'] || 'empty',
        })
      : null;
  }

  /**
   * Представляет допустимые опции для выполнения GET запроса на загрузку файла
   * @typedef GetFileRequestOptionsDocs
   * @type {object}
   * @property {boolean} [withAuth=true]
   * @property {boolean} [withSide=false]
   * @property {number} [timeout=0]
   */

  /** Выполнить запрос на загрузку файла с сервера
   * @param {string} url
   * @param {GetFileRequestOptionsDocs} [options={}]
   * @param {Object.<string, any>} [query]
   * @returns {Promise<{ file: Promise<Blob>; filename: string }>}
   *  */
  async getFile(
    url: string,
    {
      withAuth = true,
      withSide = false,
      isJson = false,
      timeout = 0,
    }: Partial<RequestOptions> = {},
    query?: { [key: string]: any },
  ): Promise<{ file: Promise<Blob>; filename: string }> {
    const headers = this.createHeaders({
      withAuth,
      withSide,
      isJson,
    });

    const response = await this.fetch(
      `${this.host}${url}${
        query && Object.keys(query).length
          ? `?${this.query.stringify(query)}`
          : ''
      }`,
      {
        headers,
        timeout,
      },
    );

    const contentDisposition =
      response &&
      response.headers &&
      response.headers.get('Content-Disposition');

    const decodedContentDisposition = decodeURI(
      contentDisposition || '',
    )?.replace(/\*|UTF-8|\"|\'\'/g, '');

    const filename = (decodedContentDisposition?.match(/filename=(.+)/) ||
      [])[1];

    return { file: response.blob(), filename: filename };
  }

  /**
   * Представляет допустимые опции для загрузки файла на сервер
   * @typedef UploadFileRequestOptionsDocs
   * @type {object}
   * @property {boolean} [withAuth=true]
   * @property {boolean} [withSide=false]
   * @property {'post'|'put'} [method=post]
   * @property {boolean} [withIndicies=false]
   * @property {number} [timeout=0]
   * @property {boolean} [isJson=false]
   */

  /** Загрузить файл на сервер
   * @param {string} url
   * @param {Object.<string, any>} [data]
   * @param {UploadFileRequestOptionsDocs} [options={}]
   * @returns {Promise<Blob>}
   *  */
  async generateFile(
    url: string,
    data?: { [key: string]: any },
    {
      withAuth = true,
      withSide = false,
      method = 'post',
      withIndicies = false,
      timeout = 0,
      isJson = false,
    }: Partial<RequestOptions> & { method?: 'post' | 'put' } = {},
  ): Promise<Blob> {
    const headers = this.createHeaders({
      withAuth,
      withSide,
      isJson,
    });

    const response = await this.fetch(`${this.host}${url}`, {
      method: method.toUpperCase(),
      body: this.createBody(data, isJson, withIndicies) as any,
      headers,
      timeout,
    });

    return response.blob();
  }

  /**
   * Представляет допустимые опции для выполнения POST запроса
   * @typedef PostRequestOptionsDocs
   * @type {object}
   * @property {boolean} [withAuth=true]
   * @property {boolean} [withSide=false]
   * @property {boolean} [isJson=true]
   * @property {boolean} [withIndicies=false]
   * @property {number} [timeout=0]
   * @property {boolean} [reloadIfOldVersion=false]
   */

  /**
   * @param {string} url
   * @param {Object.<string, any>} [data]
   * @param {PostRequestOptionsDocs} [options={}]
   * @returns {Promise<unknown>}
   * */
  async post(
    url: string,
    data?: { [key: string]: any },
    {
      withAuth = true,
      withSide = false,
      isJson = true,
      withIndicies = false,
      timeout = 0,
      reloadIfOldVersion = false,
    }: Partial<RequestOptions> = {},
  ): Promise<unknown> {
    const headers = this.createHeaders({
      withAuth,
      withSide,
      isJson,
    });

    const response = await this.fetch(`${this.host}${url}`, {
      method: 'POST',
      body: this.createBody(data, isJson, withIndicies) as any,
      headers,
      timeout,
      reloadIfOldVersion,
    });

    try {
      const res = this.extendResponse(await this.parseJson(response), {
        vkdoc_error_type: 'validation',
        http_url: url,
        http_method: 'POST',
        http_trace_id: response.headers.get('X-Mayday-Trace-Id'),
        http_side: headers['X-Side'] || 'empty',
        http_version: headers['X-App-Version'] || 'empty',
      });

      return res;
    } catch (e) {
      return null;
    }
  }

  /**
   * Представляет допустимые опции для выполнения PUT запроса
   * @typedef PutRequestOptionsDocs
   * @type {object}
   * @property {boolean} [withAuth=true]
   * @property {boolean} [withSide=false]
   * @property {boolean} [isJson=true]
   * @property {boolean} [withIndicies=false]
   * @property {number} [timeout=0]
   */

  /**
   * @param {string} url
   * @param {Object.<string, any>} [data]
   * @param {PutRequestOptionsDocs} [options={}]
   * @returns {Promise<unknown>}
   * */
  async put(
    url: string,
    data?: { [key: string]: any },
    {
      withAuth = true,
      withSide = false,
      isJson = true,
      withIndicies = false,
      timeout = 0,
    }: Partial<RequestOptions> = {},
  ): Promise<unknown> {
    const headers = this.createHeaders({
      withAuth,
      withSide,
      isJson,
    });

    const response = await this.fetch(`${this.host}${url}`, {
      method: 'PUT',
      body: this.createBody(data, isJson, withIndicies) as any,
      headers,
      timeout,
    });

    return isJson
      ? this.extendResponse(await this.parseJson(response), {
          vkdoc_error_type: 'validation',
          http_url: url,
          http_method: 'PUT',
          http_trace_id: response.headers.get('X-Mayday-Trace-Id'),
          http_side: headers['X-Side'] || 'empty',
          http_version: headers['X-App-Version'] || 'empty',
        })
      : null;
  }

  /**
   * Представляет допустимые опции для выполнения DELETE запроса
   * @typedef DeleteRequestOptionsDocs
   * @type {object}
   * @property {boolean} [withAuth=true]
   * @property {boolean} [withSide=false]
   * @property {boolean} [isJson=true]
   * @property {boolean} [withIndicies=false]
   * @property {number} [timeout=0]
   */

  /**
   * @param {string} url
   * @param {Object.<string, any>} [data]
   * @param {DeleteRequestOptionsDocs} [options={}]
   * @returns {Promise<unknown>}
   * */
  async delete(
    url: string,
    data?: { [key: string]: any },
    {
      withAuth = true,
      withSide = false,
      isJson = true,
      withIndicies = false,
      timeout = 0,
    }: Partial<RequestOptions> = {},
  ): Promise<unknown> {
    const headers = this.createHeaders({
      withAuth,
      withSide,
      isJson,
    });

    const response = await this.fetch(`${this.host}${url}`, {
      method: 'DELETE',
      body: this.createBody(data, isJson, withIndicies) as any,
      headers,
      timeout,
    });

    return isJson
      ? this.extendResponse(await this.parseJson(response), {
          vkdoc_error_type: 'validation',
          http_url: url,
          http_method: 'DELETE',
          http_trace_id: response.headers.get('X-Mayday-Trace-Id'),
          http_side: headers['X-Side'] || 'empty',
          http_version: headers['X-App-Version'] || 'empty',
        })
      : null;
  }
}
