/** Нормализовать путь
 * @param {string} path Исходный путь
 * @returns {string}
 * */
export const normalizePath = (path: string) =>
  path.startsWith('/') ? path : `/${path}`;

/** Получить из пути "название модуля"
 * @param {string} path Исходный путь
 * @returns {string}
 * */
export const getBasename = (path: string) =>
  normalizePath(path).split('/')[1].split('?')[0].split('#')[0];

/** Убрать "название модуля" из пути
 * @returns {string}
 * */
export const removeBasename = (haystack: string, basename?: string) => {
  if (!basename) {
    return haystack;
  }

  const normalizedBasename = normalizePath(basename);

  if (!haystack.startsWith(normalizedBasename)) {
    return haystack;
  }

  return haystack.replace(normalizedBasename, '/').replace('//', '/');
};
