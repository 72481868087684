import { inject, injectable } from 'inversify';

import { History } from '@vk-hr-tek/core/history';

import { SettingsVacationsRouter } from '../../settings/types';

@injectable()
export class SettingsVacationsRouterService implements SettingsVacationsRouter {
  constructor(@inject(History) private history: History) {}

  goToCreate(companyId: string) {
    this.history.push(`/settings/vacations/create?company_id=${companyId}`);
  }

  goToList(query?: string) {
    if (!query || query === '?') {
      this.history.push('/settings/vacations');
      return;
    }

    this.history.push({
      pathname: '/settings/vacations',
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }

  redirectToHome() {
    this.history.push('/');
  }

  goToScheduleDetail({ id, companyId }: { id: string; companyId: string }) {
    this.history.push(`/settings/vacations/${id}?company_id=${companyId}`);
  }

  goToStartSchedule({ id, companyId }: { id: string; companyId: string }) {
    this.history.push(
      `/settings/vacations/${id}/start?company_id=${companyId}`,
    );
  }
}
