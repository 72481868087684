import { inject, injectable } from 'inversify';

import { History } from '@vk-hr-tek/core/history';

import { SettingsAttorneysUsersRouter } from '../../settings/types';

@injectable()
export class SettingsAttorneysUsersRouterService
  implements SettingsAttorneysUsersRouter
{
  constructor(@inject(History) private history: History) {}

  goToList(query?: string) {
    if (!query || query === '?') {
      this.history.push('/settings/attorneys-users');
      return;
    }

    this.history.push({
      pathname: '/settings/attorneys-users',
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }
}
