import { makeStyles, Theme } from '@material-ui/core';

import {
  CounterSize,
  SizeScheme,
  CounterColor,
  ColorScheme,
  CounterBackgroundColor,
  BackgroundColorScheme,
} from './Counter.types';

const sizeScheme: (px: Theme['px']) => SizeScheme = (px) => ({
  [CounterSize.large]: {
    height: px(20),
  },
  [CounterSize.medium]: {
    height: px(20),
  },
  [CounterSize.small]: {
    height: px(16),
  },
  [CounterSize.extraSmall]: {
    height: px(16),
  },
});

const colorScheme: ColorScheme = {
  [CounterColor.green]: (p) => p.success.main,
  [CounterColor.orange]: (p) => p.warning.main,
  [CounterColor.red]: (p) => p.error.main,
  [CounterColor.blue]: (p) => p.blue.main,
  [CounterColor.purple]: (p) => p.review.main,
  [CounterColor.black]: (p) => p.text.primary,
};

const backgroundScheme: BackgroundColorScheme = {
  [CounterBackgroundColor.gray]: (p) => p.background.greyscalePrimary,
  [CounterBackgroundColor.green]: (p) => p.success.main,
  [CounterBackgroundColor.orange]: (p) => p.warning.main,
  [CounterBackgroundColor.red]: (p) => p.error.main,
  [CounterBackgroundColor.blue]: (p) => p.blue.main,
  [CounterBackgroundColor.lightBlue]: (p) => p.blue.light,
  [CounterBackgroundColor.purple]: (p) => p.review.main,
  [CounterBackgroundColor.white]: (p) => p.background.paper,
};

export const useStyles = makeStyles<
  Theme,
  {
    backgroundColor: CounterBackgroundColor;
    color?: keyof typeof CounterColor;
    signed: boolean;
    size: CounterSize;
  }
>(({ palette, tokens, px }) => ({
  counter: ({ backgroundColor, color, signed, size }) => ({
    fontWeight: tokens.typography.variant.caption.fontWeight,
    fontSize: tokens.typography.variant.caption.fontSize,
    lineHeight: tokens.typography.variant.caption.lineHeight,
    borderRadius: 8,
    color: color ? colorScheme[color](palette) : 'inherit',
    minWidth: sizeScheme(px)[size].height,
    height: sizeScheme(px)[size].height,
    backgroundColor: backgroundScheme[backgroundColor](palette),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: signed ? tokens.spacing['4'] : px(7), // TODO - fix нет в токенах
    paddingRight: signed ? tokens.spacing['4'] : px(7), // TODO - fix нет в токенах
    paddingTop: signed ? 0 : tokens.spacing['2'],
    paddingBottom: signed ? 0 : tokens.spacing['2'],
    boxSizing: 'border-box',
  }),
}));
