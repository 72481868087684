import { merge, cloneDeep } from 'lodash';

import { Tokens } from '../types';

import { generalTokens } from './general.tokens';

export const generalDarkTokens: Tokens = merge(cloneDeep(generalTokens), {
  name: 'general-dark',
  colors: {
    original: {
      brand: {
        secondary: '#20252d',
        quaternary: '#233044',
      },
    },
    text: {
      light: {
        primary: '#fbfbfb',
        secondary: '#aeaeae',
        tertirary: '#797979',
      },
    },
    stroke: {
      primary: '#404143',
    },
    bg: {
      light: {
        primary: '#171717',
        secondary: '#1f1f1f',
        tertirary: '#121212',
      },
      greayscale: {
        primary: '#212224',
        secondary: '#202020',
        tertirary: '#20252d',
      },
    },
    accent: {
      bg: {
        error: '#2b2121',
        success: '#20251d',
        warning: '#252018',
        review: '#2a232f',
        blue: '#20252d',
      },
    },
  },
});
