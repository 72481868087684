import { injectable } from 'inversify';

import { FormatService } from '@vk-hr-tek/core/format';
import { Filter } from '@vk-hr-tek/core/filter';

import {
  CompanyPolicyListItem as CompanyPolicyListEntity,
  EmployeePolicyListItem as EmployeePolicyListEntity,
} from '@app/gen/policy';

@injectable()
export class PolicyMapper {
  constructor(private format: FormatService) {}

  processCompanyListEntity(policy: CompanyPolicyListEntity) {
    return {
      id: policy.policy_id,
      name: policy.name,
      companyName: policy.company.name,
      version: this.processVersion(policy),
      employeesCount: {
        accepted: policy.accepted_empoyees,
        total: policy.total_employees,
      },
      signatureType: this.processSignatureType(policy),
    };
  }

  processEmployeeListEntity(
    policy: EmployeePolicyListEntity,
    variant: 'desktop' | 'mobile',
  ) {
    return {
      idMixed: `${policy.policy_version_id}${policy.employee_id}`,
      policyId: policy.policy_id,
      companyName: policy.company.name,
      companyId: policy.company.company_id,
      companyTsp: policy.company.tsp_url || '',
      hashSource: policy.company.hash_source,
      policyVersionId: policy.policy_version_id,
      name: policy.name,
      accepted: policy.status === 'signed',
      status: policy.status,
      label: this.processSignatureTypeLabel(policy),
      notification: this.processNotification(policy, variant),
      error: this.processError(policy),
      signatureType: policy.signature_type,
      isRead: !!policy.read_at,
      employeeId: policy.employee_id,
      hideIfAccepted: !!policy.hide_if_accepted,
    };
  }

  processListFilters(filters: Filter[]): Filter[] {
    const activeFilters = filters.filter((filter) => {
      const { key, type } = filter;

      if (type === 'select' || type === 'multiple') {
        if (filter?.options.length === 0) {
          return false;
        }
      }
      return key !== 'tag';
    });

    if (activeFilters.length === 1) {
      return filters.map((filter) => {
        return {
          ...filter,
          width: 12,
        };
      });
    }

    return filters.map((filter) => {
      if (filter.key === 'unit_ids' || filter.key === 'positions') {
        return {
          ...filter,
          width: 8,
          fullWidth: false,
        };
      }

      if (filter.type === 'search' && filter.key === 'unit_id') {
        return {
          ...filter,
          width: 8,
          fullWidth: false,
        };
      }

      if (filter.key === 'signature_type' && activeFilters.length === 2) {
        return {
          ...filter,
          width: 8,
          fullWidth: false,
        };
      }

      return {
        ...filter,
        width: 4,
        fullWidth: false,
      };
    });
  }

  processSignatureType(policy: CompanyPolicyListEntity) {
    const signatureType = policy.signature_type;

    switch (signatureType) {
      case 'pep':
        return 'ПЭП';
      case 'unep':
        return 'УНЭП';
      default:
        return signatureType;
    }
  }

  processSignatureTypeLabel = (policy: EmployeePolicyListEntity) => {
    const signatureType = policy.signature_type;

    switch (signatureType) {
      case 'unep_goskey':
        return 'УНЭП Госключ';
      case 'unep_kontur':
        return 'УНЭП СКБ Контур';
      case 'unep_cryptopro_simple':
        return 'УНЭП КриптоПро';
      case 'unep_cryptopro_local':
        return 'УНЭП Цифровой носитель';
    }
  };

  processVersion(policy: CompanyPolicyListEntity) {
    const from = new Date(policy.active_from);
    const now = new Date();

    if (from > now) {
      return {
        label: 'Будущая',
        date: this.getDateBetween(policy.active_from, policy.active_to),
      };
    }

    if (policy.active_to) {
      const to = new Date(policy.active_to);

      if (to < now) {
        return {
          label: 'Прошлая',
          date: this.getDateBetween(policy.active_from, policy.active_to),
        };
      }

      if (from <= now && to >= now) {
        return {
          label: 'Актуальная',
          date: this.getDateBetween(policy.active_from, policy.active_to),
        };
      }
    }

    return {
      label: 'Актуальная',
      date: this.getDateBetween(policy.active_from, policy.active_to),
    };
  }

  processNotification(
    policy: EmployeePolicyListEntity,
    variant: 'desktop' | 'mobile',
  ) {
    const { signature_type: signatureType, status } = policy;

    const desktopText =
      'Ознакомьтесь с документом и отправьте на подписание в приложение Госключ';
    const mobileText = 'Перейдите в приложение Госключ и подпишите документ';

    if (signatureType === 'unep_goskey' && status === 'not_signed') {
      return variant === 'desktop' ? desktopText : mobileText;
    }
  }

  processError(policy: EmployeePolicyListEntity) {
    const signatureType = policy.signature_type;
    const status = policy.status;
    const detailedError = policy.signature_detailed_error;

    if (signatureType === 'unep_goskey' && status === 'error') {
      switch (detailedError) {
        case 'snils_not_found':
          return {
            message: 'Не найдена учетная запись на Госуслугах.',
            description:
              'Чтобы использовать подписание через Госключ, подтвердите вашу учетную запись.',
          };
        default:
          return {
            message: 'Ошибка при соединении с Госключом.',
            description:
              'Попробуйте ещё раз и обратитесь в службу поддержки, если ошибка повторится',
          };
      }
    }
    if (signatureType === 'unep_goskey' && status === 'canceled') {
      return {
        message:
          'Мы получили ваш отказ от подписания документа в приложении Госключ.',
        description: 'Вы можете повторно отправить документ на подпись',
      };
    }
  }

  getDateBetween(from: string, to?: string) {
    return to
      ? `c ${this.format.toDate(from)} по ${this.format.toDate(to)}`
      : `c ${this.format.toDate(from)}`;
  }
}
