import {
  createAsyncThunk,
  createAction,
  ActionReducerMapBuilder,
  EntityState,
} from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';
import { Filter } from '@vk-hr-tek/core/filter';

import {
  CompanyPolicyListItem as CompanyPolicy,
  EmployeePolicyListItem as EmployeePolicy,
} from '@app/gen/policy';

import { ThunkExtra } from '../../../app/store';
import {
  completeLoading,
  policyAdapter,
  PolicyState,
  setError,
  startLoading,
} from '../policy.state';
import { GetPolicyDto, MarkReadDto } from '../../dto';
import { PolicyListService } from '../../services';

export const setFilters = createAction<Filter[] | null>('policy/setFilters');

export const getPolicies = createAsyncThunk<
  {
    data: CompanyPolicy[] | EmployeePolicy[];
    totalCount: number | null;
    totalVersionCount: number | null;
  },
  GetPolicyDto,
  ThunkExtra
>(
  'policy/getPolicies',
  async (
    getPolicyDto,
    { rejectWithValue, getState, dispatch, extra: { inject } },
  ) => {
    try {
      const state = getState().policy;
      const policyService = inject(PolicyListService);

      let filters = state.filters;

      if (!filters) {
        filters = await policyService.getFilters();
        dispatch(setFilters(filters));
      }

      return await policyService.get(getPolicyDto, filters);
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getEmployeeFilteredPolicies = createAsyncThunk<
  {
    data: CompanyPolicy[] | EmployeePolicy[];
  },
  {
    dto: GetPolicyDto;
    policies: {
      policyVersionId: string;
      employeeId: string;
    }[];
  },
  ThunkExtra
>(
  'policy/getFilterdPolicies',
  async (
    { dto: getPolicyDto, policies },
    { rejectWithValue, getState, dispatch, extra: { inject } },
  ) => {
    try {
      const state = getState().policy;
      const policyService = inject(PolicyListService);

      let filters = state.filters;

      if (!filters) {
        filters = await policyService.getFilters();
        dispatch(setFilters(filters));
      }

      const policyVersionIds = policies.map(
        ({ policyVersionId }) => policyVersionId,
      );

      const result = await policyService.get(
        getPolicyDto,
        filters,
        policyVersionIds,
      );

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getAdditionalPolicies = createAsyncThunk<
  {
    data: CompanyPolicy[] | EmployeePolicy[];
  },
  GetPolicyDto,
  ThunkExtra
>(
  'policy/getAdditionalPolicies',
  async (
    getPolicyDto,
    { rejectWithValue, getState, dispatch, extra: { inject } },
  ) => {
    try {
      const state = getState().policy;
      const policyListService = inject(PolicyListService);

      let filters = state.filters;

      if (!filters) {
        filters = await policyListService.getFilters();
        dispatch(setFilters(filters));
      }

      return await policyListService.get(getPolicyDto, filters);
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const markDocumentRead = createAsyncThunk<void, MarkReadDto, ThunkExtra>(
  'policy/markDocumentRead',
  async (markReadDto, { rejectWithValue, extra: { inject } }) => {
    try {
      await inject(PolicyListService).markRead(markReadDto);
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const policyListReducers = (
  builder: ActionReducerMapBuilder<
    EntityState<CompanyPolicy | EmployeePolicy> & PolicyState
  >,
) => {
  builder.addCase(setFilters, (state, action) => {
    state.filters = action.payload;
  });
  builder.addCase(getPolicies.pending, (state) => {
    startLoading(state);
  });
  builder.addCase(getPolicies.fulfilled, (state, { payload }) => {
    completeLoading(state);

    state.totalCount = payload.totalCount;
    state.totalVersionCount = payload.totalVersionCount;
    state.isNotLastItems = payload.data.length >= 50;
    state.currentIds = payload.data
      .sort((a, b) => {
        if ('status' in a && 'status' in b) {
          return (
            +(a.status === 'signed') - +(b.status === 'signed') ||
            a.name.localeCompare(b.name)
          );
        }

        return a.name.localeCompare(b.name);
      })
      .map((policy) => {
        if ('policy_version_id' in policy && 'employee_id' in policy) {
          const {
            policy_version_id: policyVersionId,
            employee_id: employeeId,
          } = policy as EmployeePolicy;

          return `${policyVersionId}${employeeId}`;
        }

        return policy.policy_id;
      });

    policyAdapter.upsertMany(state, payload.data);
  });
  builder.addCase(getPolicies.rejected, (state, { payload, error, meta }) => {
    if (!meta.aborted) {
      setError(state, { payload, error });
    }
  });
  builder.addCase(getAdditionalPolicies.fulfilled, (state, { payload }) => {
    completeLoading(state);

    state.isNotLastItems = payload.data.length >= 50;
    state.currentIds = [
      ...state.currentIds,
      ...payload.data
        .sort((a, b) => {
          if ('status' in a && 'status' in b) {
            return (
              +(a.status === 'signed') - +(b.status === 'signed') ||
              a.name.localeCompare(b.name)
            );
          }

          return a.name.localeCompare(b.name);
        })
        .map((policy) => {
          if ('policy_version_id' in policy && 'employee_id' in policy) {
            const {
              policy_version_id: policyVersionId,
              employee_id: employeeId,
            } = policy as EmployeePolicy;

            return `${policyVersionId}${employeeId}`;
          }

          return policy.policy_id;
        }),
    ];
    policyAdapter.upsertMany(state, payload.data);
  });

  builder.addCase(
    getEmployeeFilteredPolicies.fulfilled,
    (state, { payload, meta }) => {
      const employeePolicy = payload.data as EmployeePolicy[];

      state.accept.needConfirmPolicies = employeePolicy
        .filter((policy) => {
          if (meta.arg?.policies.length) {
            return meta.arg.policies.some((argPolicy) => {
              return (
                argPolicy.policyVersionId === policy.policy_version_id &&
                argPolicy.employeeId === policy.employee_id
              );
            });
          }

          return true;
        })
        .filter((policy) =>
          [
            'unep_kontur',
            'unep_goskey',
            'unep_cryptopro_simple',
            'unep_cryptopro_local',
          ].includes(policy.signature_type),
        )
        .map(({ policy_version_id, employee_id, name, status }) => ({
          policyVersionId: policy_version_id,
          employeeId: employee_id,
          name,
          status,
        }));

      policyAdapter.upsertMany(state, payload.data);
    },
  );

  builder.addCase(
    getEmployeeFilteredPolicies.rejected,
    (state, { payload, error }) => {
      setError(state, { payload, error });
    },
  );

  builder.addCase(markDocumentRead.fulfilled, (state, { meta }) => {
    policyAdapter.updateOne(state, {
      id: meta.arg.policyId,
      changes: { read_at: new Date().toISOString() },
    });
  });
};
