import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  clearButton: {},
  input: {
    width: '100%',
    marginBottom: theme.tokens.spacing['16'],
    position: 'relative',
    zIndex: 1,

    '&:hover $clearIcon': {
      display: 'block',
    },
  },

  textDateInput: {
    '& .textFieldInput': {
      backgroundColor: theme.tokens.colors.bg.light.primary,

      '&.Mui-focused': {
        '& fieldset': {
          borderColor: theme.tokens.colors.original.brand.primary,
          borderWidth: theme.tokens.border.s,
        },

        '& $clearButton': {
          display: 'flex',
        },
      },

      dateInput: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.tokens.colors.bg.light.primary,
        border: `${theme.tokens.border.s}px solid ${theme.tokens.colors.stroke.primary}`,
        cursor: 'pointer',
        padding: theme.tokens.spacing['16'],
        borderRadius: theme.tokens.radius.l,
        fontSize: theme.tokens.typography.variant.body2.fontSize,
        lineHeight: theme.tokens.typography.variant.body2.lineHeight,
        color: theme.tokens.colors.text.light.primary,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        position: 'relative',

        '&:hover': {
          borderColor: theme.tokens.colors.stroke.secondary,
        },
      },

      '&.Mui-error': {
        backgroundColor: theme.tokens.colors.accent.bg.error,

        '& fieldset': {
          borderColor: theme.tokens.colors.accent.stroke.error,
        },

        '&.Mui-focused': {
          '& fieldset': {
            borderColor: theme.tokens.colors.accent.text.error,
          },
        },

        '&:hover': {
          '& fieldset': {
            borderColor: theme.palette.error.hover,
          },
        },
      },

      '&.Mui-disabled': {
        backgroundColor: theme.tokens.colors.bg.greayscale.secondary,

        '& fieldset': {
          borderColor: theme.tokens.colors.stroke.primary,
        },
      },

      '& input': {
        padding: theme.tokens.spacing['16'],
        height: theme.px(24),
        fontSize: theme.tokens.typography.variant.body2.fontSize,
        lineHeight: theme.tokens.typography.variant.body2.lineHeight,
      },

      '& fieldset': {
        borderColor: theme.tokens.colors.stroke.primary,
      },

      '&:hover': {
        '& fieldset': {
          borderColor: theme.tokens.colors.stroke.secondary,
        },

        '& $clearButton': {
          display: 'flex',
        },
      },
    },

    '& .MuiFormHelperText-root.Mui-error': {
      marginLeft: 0,
      marginRight: 0,
    },
  },

  action: {
    color: theme.tokens.colors.original.brand.primary,
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  adornedEnd: {
    cursor: 'pointer',
  },

  icon: {
    position: 'absolute',
    bottom: theme.tokens.spacing['16'],
    right: theme.tokens.spacing['16'],
  },

  clearIcon: {
    display: 'none',
  },

  calendar: {
    '& .MuiPickersCalendarHeader-dayLabel': {
      color: 'blue',
    },
  },
}));
