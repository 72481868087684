import { injectable, inject } from 'inversify';

import { History } from '@vk-hr-tek/core/history';

import { EventsRouter } from '../../events/types';

@injectable()
export class EventsRouterService implements EventsRouter {
  constructor(@inject(History) private history: History) {}

  goToDetail(id: string) {
    this.history.push(`/events/${id}`);
  }

  goToList(query?: string) {
    if (!query || query === '?') {
      this.history.push('/');
      return;
    }

    this.history.push({
      pathname: '/',
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }

  goToPaperList(query?: string) {
    if (!query || query === '?') {
      this.history.push('/events/paper');
      return;
    }

    this.history.push({
      pathname: '/events/paper',
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }

  goToCreateType() {
    this.history.push('/events/create-type');
  }

  goToCreate(query: string) {
    this.history.push({
      pathname: 'events/create',
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }

  goToCreateBatch({
    companyId,
    eventTypeId,
    employeeId,
    parentEventId,
  }: {
    companyId: string;
    eventTypeId: string;
    employeeId?: string;
    parentEventId?: string;
  }) {
    let searchString = `?companyId=${companyId}&eventTypeId=${eventTypeId}`;

    if (employeeId) {
      searchString += `&employeeId=${employeeId}`;
    }

    if (parentEventId) {
      searchString += `&parentEventId=${parentEventId}`;
    }

    this.history.push({
      pathname: '/events/create-batch',
      search: searchString,
    });
  }
}
