import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ tokens, px, breakpoints }) => ({
  navigation: {
    display: 'flex',
    gap: tokens.spacing['32'],

    [breakpoints.down('md')]: {
      gap: tokens.spacing['16'],
    },
  },

  navigationItem: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    position: 'relative',
    color: tokens.colors.text.light.secondary,

    '&:hover span': {
      color: tokens.colors.text.light.primary,
    },

    '& .MuiListItem-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
    },

    [breakpoints.down('md')]: {
      fontSize: 14,
      fontWeight: 500,
    },
  },

  menuNavigationItem: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    position: 'relative',
    color: tokens.colors.text.light.secondary,

    '&:hover': {
      color: tokens.colors.original.brand.primary,
    },

    [breakpoints.down('md')]: {
      fontSize: 14,
      fontWeight: 500,
    },
  },

  listItem: {
    display: 'flex',
    gap: tokens.spacing['4'],
    width: 'auto',
    color: tokens.colors.text.light.secondary,
    paddingLeft: 0,
    paddingRight: 0,

    '&:hover': {
      color: tokens.colors.text.light.primary,
    },
  },

  navigationItem_active: {
    color: tokens.colors.original.brand.primary,

    '&:before': {
      content: "''",
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: -px(10),
      height: px(2),
      background: tokens.colors.original.brand.primary,
    },

    '&:hover span': {
      color: tokens.colors.original.brand.primary,
    },

    '& .MuiTypography-root': {
      color: tokens.colors.original.brand.primary,
    },
  },

  navigationSubItem_active: {
    color: tokens.colors.original.brand.primary,
  },

  listItemText: {
    fontSize: 16,
    '& span': {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
    },

    [breakpoints.down('md')]: {
      fontSize: 14,

      '& span': {
        fontSize: 14,
        fontWeight: 500,
      },
    },
  },

  menuList: {
    padding: 0,
  },
  menuListItem: {
    width: '100%',
    paddingTop: tokens.spacing['12'],
    paddingBottom: tokens.spacing['12'],
    paddingRight: tokens.spacing['16'],
    paddingLeft: tokens.spacing['16'],

    [breakpoints.down('md')]: {
      fontSize: 14,
    },
  },

  downIcon: {
    color: tokens.colors.text.light.secondary,

    '&:hover': {
      color: tokens.colors.text.light.primary,
    },
  },
  downIconActive: {
    color: tokens.colors.original.brand.primary,
  },
}));
