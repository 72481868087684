import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { FORM_ERROR } from 'final-form';

import { AuthPasswordService, AuthRouterService } from '../../../services';
import {
  ChangePasswordInitDto,
  ChangePasswordDto,
  VerifyChangePasswordPhoneCodeDto,
} from '../../../dto';
import {
  AuthState,
  startLoading,
  completeLoading,
  resetState,
  setError,
  normalizeError,
  ThunkExtra,
} from '../../auth.state';

export const changePasswordInit = createAsyncThunk<
  void,
  {
    values: ChangePasswordInitDto;
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'auth/changePasswordInit',
  async ({ values, actions }, { rejectWithValue, extra: { inject } }) => {
    try {
      await inject(AuthPasswordService).changePasswordInit(values);

      actions.resolve(null);
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(normalizeError(err));
    }
  },
);

export const verifyChangePasswordPhoneCode = createAsyncThunk<
  void,
  {
    values: VerifyChangePasswordPhoneCodeDto;
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'auth/verifyChangePasswordPhoneCode',
  async ({ values, actions }, { rejectWithValue, extra: { inject } }) => {
    try {
      await inject(AuthPasswordService).verifyChangePasswordPhoneCode(values);

      actions.resolve(null);
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(normalizeError(err));
    }
  },
);

export const changePassword = createAsyncThunk<
  void,
  {
    values: ChangePasswordDto;
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'auth/changePassword',
  async (
    { values, actions },
    { rejectWithValue, getState, extra: { inject } },
  ) => {
    try {
      const state = getState().auth;

      const { phone, code, snils } = state.values;

      await inject(AuthPasswordService).changePassword({
        password: values.password,
        passwordRepeat: values.passwordRepeat,
        phone: phone || undefined,
        code: code || '',
        snils: snils || undefined,
      });

      actions.resolve(null);
      inject(AuthRouterService).redirectToLogin();
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(normalizeError(err));
    }
  },
);

export const changePasswordReducers = (
  builder: ActionReducerMapBuilder<AuthState>,
) => {
  builder.addCase(changePasswordInit.pending, (state, action) => {
    startLoading(state);

    state.values.phone = action.meta.arg.values.phone;
    state.values.snils = action.meta.arg.values.snils;
  });
  builder.addCase(changePasswordInit.fulfilled, (state) => {
    state.stage = 'verification';

    completeLoading(state);
  });
  builder.addCase(changePasswordInit.rejected, (state, { payload }) => {
    setError(state, { payload });
  });

  builder.addCase(verifyChangePasswordPhoneCode.pending, (state) => {
    startLoading(state);
  });

  builder.addCase(verifyChangePasswordPhoneCode.fulfilled, (state, action) => {
    state.stage = 'finish';
    state.values.code = action.meta.arg.values.code;

    completeLoading(state);
  });
  builder.addCase(
    verifyChangePasswordPhoneCode.rejected,
    (state, { payload }) => {
      setError(state, { payload });
    },
  );

  builder.addCase(changePassword.pending, (state) => {
    startLoading(state);
  });
  builder.addCase(changePassword.fulfilled, (state) => {
    resetState(state);
  });
  builder.addCase(changePassword.rejected, (state, { payload }) => {
    setError(state, { payload });
  });
};
