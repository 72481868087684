import React from 'react';

import { useTheme } from '@material-ui/core';

import { StageStatus } from './Stage';

interface EndProps {
  last: boolean;
  status: StageStatus;
}

export const StageEnd = ({ last, status }: EndProps) => {
  const { tokens } = useTheme();

  const rounded = (
    <path d="M0 0H4C8.41828 0 12 3.58172 12 8V16C12 20.4183 8.41828 24 4 24H0V0Z" />
  );

  const angled = (
    <path d="M0 0H0.126542C1.31366 0 2.43945 0.527292 3.19943 1.43926L7.73215 6.87853C10.2045 9.84529 10.2045 14.1547 7.73215 17.1215L3.19942 22.5607C2.43945 23.4727 1.31366 24 0.126542 24H0V0Z" />
  );

  return (
    <svg
      width="12"
      height="24"
      viewBox="0 0 12 24"
      fill={
        status === 'initial'
          ? tokens.colors.bg.greayscale.primary
          : tokens.colors.original.brand.primary
      }
      fillOpacity={status === 'active' ? 0.6 : 1}
      xmlns="http://www.w3.org/2000/svg"
    >
      {last ? rounded : angled}
    </svg>
  );
};
