import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    boxShadow: 'none',
    borderRadius: `${theme.tokens.radius.m}px !important`,
    '& .MuiButtonGroup-groupedHorizontal:not(:first-child)': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    '& .MuiButtonGroup-groupedHorizontal:not(:last-child)': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  sizeLarge: {
    borderRadius: theme.tokens.radius.l,
  },
  buttonGroupSeparated: {
    '& .MuiButtonGroup-groupedContainedVertical': {
      borderRadius: `${theme.tokens.radius.m}px !important`,
    },
  },
  buttonGroupSilver: {
    border: `${theme.tokens.border.s}px solid ${theme.tokens.colors.stroke.primary}`,
  },
  buttonGroupSilverDisabled: {
    borderColor: theme.tokens.colors.bg.greayscale.primary,
  },
  element: {
    '& .MuiButton-endIcon > *:first-child': {
      fontSize: theme.tokens.typography.variant.h5.fontSize,
    },
  },
  elementContained: {
    backgroundColor: `${theme.tokens.colors.original.brand.primary} !important`,
    color: 'white !important',
    borderRight: 'none !important',
    borderBottom: 'none !important',
    '&.MuiButtonGroup-groupedHorizontal:not(:last-child)': {
      position: 'relative',
      '&::after': {
        content: "''",
        width: theme.px(1),
        height: '100%',
        position: 'absolute',
        right: 0,
        opacity: '0.2',
        backgroundColor: theme.tokens.colors.bg.light.primary,
      },
      '&.MuiButton-containedSizeLarge': {
        '&::after': {
          height: '50%',
          top: '25%',
        },
      },
    },
    '&.MuiButtonGroup-groupedVertical:not(:last-child)': {
      position: 'relative',
      '&::after': {
        content: "''",
        width: '100%',
        height: theme.px(1),
        position: 'absolute',
        bottom: 0,
        opacity: '0.2',
        backgroundColor: theme.tokens.colors.bg.light.primary,
      },
      '&.MuiButton-containedSizeLarge': {
        '&::after': {
          width: '50%',
          left: '25%',
        },
      },
    },
  },
  elementSilver: {
    backgroundColor: `${theme.tokens.colors.bg.greayscale.primary} !important`,
    color: theme.tokens.colors.original.brand.primary,
    position: 'relative',
    '&.MuiButtonGroup-groupedVertical': {
      borderBottom: 'none !important',
      '&:not(:last-child)': {
        '&::after': {
          content: "''",
          width: '60%',
          height: theme.px(1),
          position: 'absolute',
          left: '20%',
          bottom: 0,
          backgroundColor: theme.tokens.colors.stroke.primary,
        },
      },
    },
  },
  elementSeparated: {
    '&.MuiButtonGroup-groupedVertical + &': {
      marginTop: theme.tokens.spacing['8'],
    },
  },
  buttonContainedHover: {
    '&:hover': {
      backgroundColor: `${theme.tokens.colors.original.brand.tertirary} !important`,
      color: 'white !important',
    },
  },
  buttonSilverHover: {
    '&:hover': {
      backgroundColor: `${theme.tokens.colors.bg.greayscale.tertirary} !important`,
    },
  },
}));
