import { useIsDesktop } from '@vk-hr-tek/ui/hooks';

import { UserRoleEnum } from '../../app/types';
import { selectUserRepresentative } from '../../user';
import { useSelector } from '../../app/hooks';

import { User as UserDesktop } from './desktop';
import { User as UserMobile } from './mobile';

export const User = () => {
  const isDesktop = useIsDesktop();
  const userRepresentative = useSelector(selectUserRepresentative);

  const roles = [
    {
      label: 'Личный кабинет',
      value: UserRoleEnum.Employee,
    },
    {
      label: 'Кабинет компании',
      value: UserRoleEnum.Company,
    },
  ];

  const isRepresentativeUser =
    window.REACT_APP_VKHRTEK_LK_DISABLED && userRepresentative;

  const userRoles = isRepresentativeUser
    ? roles.filter(({ value }) => value === UserRoleEnum.Company)
    : roles;

  return isDesktop ? (
    <UserDesktop
      userRoles={userRoles}
      userRepresentative={isRepresentativeUser}
    />
  ) : (
    <UserMobile
      userRoles={userRoles}
      userRepresentative={isRepresentativeUser}
    />
  );
};
