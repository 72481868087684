import { inject, injectable } from 'inversify';

import { History } from '@vk-hr-tek/core/history';

import { SettingsSubstitutesRouter } from '../../settings/types';

@injectable()
export class SettingsSubstitutesRouterService
  implements SettingsSubstitutesRouter
{
  constructor(@inject(History) private history: History) {}

  goToCreate({
    employeeId,
    companyId,
  }: {
    employeeId: string;
    companyId: string;
  }) {
    this.history.push(
      `/settings/substitutes/new?employeeId=${employeeId}&companyId=${companyId}`,
    );
  }

  goToList(query?: string) {
    if (!query || query === '?') {
      this.history.push('/settings/substitutes');
      return;
    }

    this.history.push({
      pathname: '/settings/substitutes',
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }
}
