import { inject, injectable } from 'inversify';

import { History } from '@vk-hr-tek/core/history';

import { PolicyRouter } from '../../policy/types';

@injectable()
export class PolicyRouterService implements PolicyRouter {
  constructor(@inject(History) private history: History) {}

  goToCreate() {
    this.history.push('/policy/create');
  }

  goToDetail(id: string, query?: string) {
    if (!query || query === '?') {
      this.history.push(`/policy/${id}`);
      return;
    }

    this.history.push({
      pathname: `/policy/${id}`,
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }

  goToList(query?: string) {
    if (!query || query === '?') {
      this.history.push('/policy');
      return;
    }

    this.history.push({
      pathname: '/policy',
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }
}
