import { injectable, inject } from 'inversify';

import { History } from '@vk-hr-tek/core/history';

import { CandidateRouter } from '../../candidate/types';

@injectable()
export class CandidateRouterService implements CandidateRouter {
  constructor(@inject(History) private history: History) {}

  goToCandidateDetail() {
    this.history.push('/detail');
  }
}
