import React from 'react';

import { Form } from 'react-final-form';

import { useInject } from '@vk-hr-tek/core/ioc';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { Box } from '@vk-hr-tek/ui/Box';
import { FormButton, FormError, TextInput } from '@vk-hr-tek/ui/form';
import { Link } from '@vk-hr-tek/ui/Link';

import { useDispatch, useSelector } from '@app/hooks';

import {
  selectNotification,
  selectStatus,
  setSnilsAli,
  redirectToQr,
} from '../../../slice';
import { RegisterAliInitDto } from '../../../dto';
import { LegalLinks } from '../../../ui/links';

export const RegisterSnils = () => {
  const dispatch = useDispatch();
  const validator = useInject(ValidationService);

  const error = useSelector(selectNotification);
  const status = useSelector(selectStatus);

  const onSubmit = (values: RegisterAliInitDto) =>
    dispatch(setSnilsAli(values));

  const handleRedirectToQr = () => dispatch(redirectToQr());

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        validate={(values) =>
          validator.validate(values, RegisterAliInitDto, ['snils'])
        }
        render={({
          handleSubmit,
          submitting,
          pristine,
          hasValidationErrors,
          dirtySinceLastSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box mt="24">
              <TextInput
                name="snils"
                label="СНИЛС"
                placeholder="Введите свой СНИЛС"
                type="text"
                mask="999-999-999 99"
              />
            </Box>
            <Box mt="24" mb="16">
              <FormButton
                disabled={pristine || hasValidationErrors}
                loading={submitting}
              >
                Продолжить
              </FormButton>
            </Box>
            {!dirtySinceLastSubmit && status && status === 'failed' && (
              <FormError error={error} />
            )}

            <LegalLinks
              policyConfidentialityLink="policy_confidentiality"
              userAggrementLink="user_agreement"
            />

            <Box mt="32" display="flex" justifyContent="center">
              <Link
                onClick={handleRedirectToQr}
                size="small"
                variant="tertiary"
              >
                Выход к QR-коду
              </Link>
            </Box>
          </form>
        )}
      />
    </div>
  );
};
