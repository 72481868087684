import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ tokens, px }) => ({
  dropdown: {
    cursor: 'pointer',
  },
  icon: {
    fontSize: tokens.typography.variant.overline.fontSize,
  },
  menuList: {
    padding: 0,
  },
  menuPaper: {
    maxHeight: px(220),
    marginTop: tokens.spacing['8'],
  },
  menuItem: {
    padding: `${tokens.spacing['12']}px ${tokens.spacing['16']}px`,
  },
}));

export default useStyles;
