import React from 'react';

import { Box } from '../../../Box';
import { ColoredChip } from '../../../ColoredChip';
import { Tooltip } from '../../../Tooltip';

interface BadgeProps {
  title?: string;
  description?: string;
  color?: 'gray' | 'green' | 'orange' | 'red' | 'blue' | 'velvet';
  size?: 'extraSmall' | 'small';
}

export const Badge = ({
  title = '',
  description = '',
  color,
  size = 'small',
}: BadgeProps) => (
  <Box minWidth={74}>
    <Tooltip title={description} placement="left">
      <span>
        <ColoredChip
          fullWidth
          size={size}
          color={color === 'velvet' ? 'purple' : color || 'blue'}
          label={title}
        />
      </span>
    </Tooltip>
  </Box>
);
