import React from 'react';

import useStyles from './FormLinkButton.styles';

interface FormLinkButtonProps {
  children: React.ReactNode;
}

export const FormLinkButton = ({ children }: FormLinkButtonProps) => {
  const classes = useStyles();

  return (
    <button type="submit" className={classes.linkButton}>
      {children}
    </button>
  );
};
