import React from 'react';

export const XmlFileIcon = () => (
  <svg
    width="95"
    height="41"
    viewBox="0 0 95 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6564 32.9929C9.92395 32.9929 9.29611 32.7353 8.77291 32.2202C8.24971 31.705 7.99213 31.0772 8.00018 30.3366C7.99213 29.6122 8.24971 28.9924 8.77291 28.4773C9.29611 27.9621 9.92395 27.7045 10.6564 27.7045C11.3648 27.7045 11.9805 27.9621 12.5037 28.4773C13.035 28.9924 13.3046 29.6122 13.3127 30.3366C13.3046 30.8277 13.1758 31.2744 12.9263 31.6768C12.6848 32.0793 12.3629 32.4013 11.9604 32.6428C11.566 32.8762 11.1313 32.9929 10.6564 32.9929Z"
      fill="#226DFE"
    />
    <path
      d="M21.2614 8L26.7067 17.0554H26.8999L32.3693 8H37.4766L29.858 20.3636L37.5973 32.7273H32.4055L26.8999 23.7322H26.7067L21.201 32.7273H16.0334L23.8452 20.3636L16.13 8H21.2614Z"
      fill="#226DFE"
    />
    <path
      d="M40.4931 8H45.9746L53.3155 25.9176H53.6053L60.9462 8H66.4277V32.7273H62.1294V15.7393H61.9L55.0662 32.6548H51.8546L45.0208 15.7031H44.7914V32.7273H40.4931V8Z"
      fill="#226DFE"
    />
    <path
      d="M70.8056 32.7273V8H75.285V28.9723H86.1756V32.7273H70.8056Z"
      fill="#226DFE"
    />
  </svg>
);
