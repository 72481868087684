import React, { ReactNode, useMemo } from 'react';

import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { Box } from '@vk-hr-tek/ui/Box';
import { DropdownInput } from '@vk-hr-tek/ui/input';
import { Tooltip } from '@vk-hr-tek/ui/Tooltip';
import { Typography } from '@vk-hr-tek/ui/Typography';

interface ContentHeaderProps {
  actions?: ReactNode | ReactNode[];
  currentOption?: string;
  description?: ReactNode;
  handleSelectOption?: (id: string) => void;
  link?: ReactNode;
  options?: { label: string; value: string }[];
  subtitle?: string;
  title: string;
  titleActions?: ReactNode;
  titleTooltip?: string;
}

export const ContentHeader = ({
  actions,
  currentOption = '',
  description,
  handleSelectOption = () => {},
  link,
  options,
  subtitle,
  title,
  titleActions,
  titleTooltip,
}: ContentHeaderProps) => {
  const isDesktop = useIsDesktop();

  const optionsComponent = useMemo(() => {
    if (!options) {
      return null;
    }

    return options.length === 1 ? (
      <Typography variant="subtitle1" component="div" noWrap>
        {options[0].label}
      </Typography>
    ) : (
      <DropdownInput
        items={options}
        value={currentOption}
        onChange={handleSelectOption}
        testId="test-id-dropdown-input"
      />
    );
  }, [currentOption, handleSelectOption, options]);

  return (
    <Box py="24">
      {link && <Box mb="4">{link}</Box>}
      {subtitle && (
        <Box mb="4">
          <Typography color="textTertiary" variant="body3">
            {subtitle}
          </Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" gap="8">
          <Typography variant={isDesktop ? 'h5' : 'h6'}>{title}</Typography>
          {!!titleTooltip && (
            <Tooltip title={titleTooltip} placement="right">
              <ErrorOutlineOutlinedIcon color="primary" fontSize="small" />
            </Tooltip>
          )}
        </Box>
        {titleActions}
      </Box>
      {description && <Box mt={isDesktop ? '12' : '16'}>{description}</Box>}
      {(options || actions) && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={isDesktop ? '12' : '16'}
        >
          {optionsComponent}
          {actions}
        </Box>
      )}
    </Box>
  );
};
