import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ tokens, px }) => ({
  offsetToLeft: {
    marginLeft: px(-6), // TODO - fix нет в токенах
  },

  offsetToLeftSmall: {
    marginLeft: px(-7), // TODO - fix нет в токенах
  },

  content: {
    alignItems: 'center',
    backgroundColor: tokens.colors.bg.greayscale.primary,
    boxSizing: 'border-box',
    color: tokens.colors.text.light.primary,
    display: 'flex',
    height: px(24),
    justifyContent: 'center',
    minWidth: px(63),
    paddingLeft: tokens.spacing['2'],
    paddingRight: tokens.spacing['2'],
  },

  contentMedium: {
    minWidth: px(100),
    padding: tokens.spacing['4'],
  },

  fullWidthStageContent: {
    width: `calc(100% - ${px(24)}px)`,
  },

  completed: {
    backgroundColor: tokens.colors.original.brand.primary,
    color: tokens.colors.text.dark.primary,
  },

  active: {
    backgroundColor: tokens.colors.original.brand.primary,
    color: tokens.colors.text.dark.primary,
    opacity: 0.6,
  },
}));
