import { Type, Transform } from 'class-transformer';
import {
  IsInt,
  IsNumber,
  IsIn,
  IsArray,
  IsString,
  IsDateString,
  IsUUID,
  IsNotEmpty,
  ValidateNested,
  IsOptional,
  IsBoolean,
} from 'class-validator';

export type Phone = string;

export class CandidateItem {
  @IsNotEmpty()
  @IsUUID()
  id: string;

  @IsNotEmpty()
  @IsUUID()
  event_id: string;

  @IsNotEmpty()
  @IsString()
  first_name: string;

  @IsNotEmpty()
  @IsString()
  last_name: string;

  @IsOptional()
  @IsString()
  middle_name?: string;

  @IsNotEmpty()
  @IsString()
  phone: string;

  @IsOptional()
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  email?: string;
}

export class CandidateSimilarResponse {
  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => CandidateItem)
  candidates: CandidateItem[];
}

export class CandidateCreateEventResponse {
  @IsNotEmpty()
  @IsUUID()
  event_id: string;
}

export class EventTypeItem {
  @IsNotEmpty()
  @IsUUID()
  id: string;

  @IsNotEmpty()
  @IsString()
  name: string;

  @IsNotEmpty()
  @IsString()
  custom_type: string;
}

export class CandidateCreateEventTypeOptionsResponse {
  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => EventTypeItem)
  event_types: EventTypeItem[];
}

export class CompanyItem {
  @IsNotEmpty()
  @IsUUID()
  id: string;

  @IsNotEmpty()
  @IsString()
  name: string;

  @IsOptional()
  @IsString()
  tsp_url?: string;

  @IsOptional()
  @IsString()
  @IsIn(['api', 'browser_plugin'])
  hash_source?: 'api' | 'browser_plugin';
}

export class CandidateCreateEventTypeCompaniesResponse {
  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => CompanyItem)
  companies: CompanyItem[];
}

export class CandidateResponse {
  @IsNotEmpty()
  @ValidateNested()
  @Type(() => CandidateItem)
  candidate: CandidateItem;
}

export type AccessToken = string;

export class CandidateLoginResponse {
  @IsNotEmpty()
  @IsString()
  access_token: string;
}
