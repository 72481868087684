import { Tokens } from '../types';

export const generalTokens: Tokens = {
  name: 'general',
  typography: {
    fontFace: ['Inter', 'Arial', 'sans-serif'].join(','),
    variant: {
      h1: {
        fontWeight: 600,
        fontSize: '6rem',
        lineHeight: 1,
        letterSpacing: '-0.026em',
      },
      h2: {
        fontWeight: 300,
        fontSize: '3.75rem',
        lineHeight: 1.07,
        letterSpacing: '-0.025em',
      },
      h3: {
        fontWeight: 400,
        fontSize: '3rem',
        lineHeight: 1.08,
        letterSpacing: '0em',
      },
      h4: {
        fontWeight: 600,
        fontSize: '2.13rem',
        lineHeight: 1.06,
        letterSpacing: '-0.015em',
      },
      h5: {
        fontWeight: 600,
        fontSize: '1.5rem',
        lineHeight: 1.167,
        letterSpacing: '0em',
      },
      h6: {
        fontWeight: 600,
        fontSize: '1.125rem',
        lineHeight: 1.333,
        letterSpacing: '0em',
      },
      subtitle1: {
        fontWeight: 600,
        fontSize: '1rem',
        lineHeight: 1.5,
        letterSpacing: '0em',
      },
      subtitle2: {
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: 1.714,
        letterSpacing: '0em',
      },
      body1: {
        fontWeight: 400,
        fontSize: '1.125rem',
        lineHeight: 1.333,
        letterSpacing: '0em',
      },
      body2: {
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: 1.5,
        letterSpacing: '0em',
      },
      body3: {
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: 1.428,
        letterSpacing: '0em',
      },
      button: {
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: 1.143,
        letterSpacing: '0.071em',
        textTransform: 'uppercase',
      },
      caption: {
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: 1.333,
        letterSpacing: '0.033em',
      },
      overline: {
        fontWeight: 500,
        fontSize: '0.625rem',
        lineHeight: 1.6,
        letterSpacing: '0.05em',
        textTransform: 'uppercase',
      },
    },
  },
  density: 1,
  spacing: {
    '0': 0,
    '2': 2,
    '4': 4,
    '8': 8,
    '12': 12,
    '16': 16,
    '20': 20,
    '24': 24,
    '32': 32,
    '40': 40,
    '48': 48,
    '56': 56,
    '72': 72,
    '96': 96,
    '144': 144,
  },
  radius: {
    round: '50%',
    '0': 0,
    xs: 4,
    s: 8,
    m: 12,
    l: 16,
  },
  colors: {
    original: {
      brand: {
        primary: '#226dfe',
        secondary: '#edf3ff',
        tertirary: '#1159e5',
        quaternary: '#d8e5ff', // Not present in figma!
      },
    },
    text: {
      light: {
        primary: '#1e1e1e',
        secondary: '#5c5c5c',
        tertirary: '#8e8e8e',
      },
      dark: {
        primary: '#ffffff',
        secondary: '#8e8e8e',
        tertirary: '#5c5c5c',
      },
    },
    stroke: {
      primary: '#e2e6eb',
      secondary: '#bac6d1',
      tertirary: '#818c99',
    },
    bg: {
      light: {
        primary: '#ffffff',
        secondary: '#fafafa',
        tertirary: '#ffffff',
      },
      dark: {
        primary: '#1c1c1e',
        secondary: '#333336',
      },
      greayscale: {
        primary: '#f4f6f8',
        secondary: '#f8f8f8',
        tertirary: '#ebeff3',
      },
    },
    accent: {
      text: {
        error: '#e24141',
        success: '#3f8904',
        warning: '#e98c00',
        review: '#9d22fe',
        blue: '#226dfe',
      },
      stroke: {
        error: '#fad7d7',
        success: '#bfe1b7',
        warning: '#ffd89e',
        review: '#dec5ef',
        blue: '#b8cefa',
      },
      bg: {
        error: '#fff0f0',
        success: '#f0f6eb',
        warning: '#fef6eb',
        review: '#f8eeff',
        blue: '#edf3ff',
      },
    },
  },
  breakpoints: {
    xs: 0,
    sm: 640,
    md: 1024,
    lg: 1200,
    xl: 1536,
  },
  shadow: {
    xs: '0px 4px 16px rgba(0, 0, 0, 0.05)',
    s: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    m: '0px 4px 16px rgba(0, 0, 0, 0.15)',
    l: '0px 4px 24px rgba(0, 0, 0, 0.25)',
  },
  border: {
    s: 1,
    m: 2,
    l: 3,
  },
};
