import React from 'react';

import ReactInfiniteScroll, { Props } from 'react-infinite-scroll-component';

import { useStyles } from './InfiniteScroll.styles';

interface InfiniteScrollProps extends Props {
  withOverflow?: boolean;
}

export const InfiniteScroll = ({ children, ...props }: InfiniteScrollProps) => {
  const classes = useStyles();
  const { withOverflow } = props;

  return (
    <ReactInfiniteScroll
      {...props}
      {...(withOverflow && {
        className: classes.infiniteScroll,
      })}
    >
      {children}
    </ReactInfiniteScroll>
  );
};
