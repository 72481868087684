import React from 'react';

import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { useAbility } from '@casl/react';
import {
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { KeyboardArrowDown as DownIcon } from '@material-ui/icons';

import { useIsDesktopExtraLarge, useIsDesktopLarge } from '@vk-hr-tek/ui/hooks';

import { AppAbilities } from '@app/ability/ability';
import { AbilityContext } from '@app/ability/Can';

import { useStyles } from './Navigation.styles';

export interface NavigationProps {
  items: {
    href: string;
    title: string;
    resource: AppAbilities[1];
    isActive?: (path: string, href: string) => boolean;
  }[];
}

export const Navigation = ({ items }: NavigationProps) => {
  const isDesktopLarge = useIsDesktopLarge();
  const isDesktopExtraLarge = useIsDesktopExtraLarge();
  const classes = useStyles();
  const location = useLocation();
  const ability = useAbility(AbilityContext);
  const [anchorEl, setAnchorEl] = React.useState<
    null | (EventTarget & Element)
  >(null);

  const handleClick = (event: React.KeyboardEvent | React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const isActiveDefault = (path: string, href: string): boolean =>
    path.startsWith(href);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filteredItems = items.filter((item) =>
    ability.can('read', item.resource),
  );

  let maxItems = 2;

  if (isDesktopLarge) {
    maxItems = 3;
  }

  if (isDesktopExtraLarge) {
    maxItems = 4;
  }

  const minimisedActive =
    filteredItems.findIndex(({ isActive = isActiveDefault, href }) =>
      isActive(location.pathname, href),
    ) >
    maxItems - 1;

  return (
    <List component="nav" className={classes.navigation}>
      {filteredItems.map(
        ({ title, href, isActive = isActiveDefault }, index) => {
          return (
            <React.Fragment key={href}>
              {index <= maxItems - 1 && (
                <Link
                  to={href}
                  className={classNames(
                    classes.navigationItem,
                    isActive(location.pathname, href) &&
                      classes.navigationItem_active,
                  )}
                >
                  <ListItem button disableRipple>
                    <ListItemText className={classes.listItemText}>
                      {title}
                    </ListItemText>
                  </ListItem>
                </Link>
              )}
            </React.Fragment>
          );
        },
      )}
      {filteredItems.length > maxItems && (
        <>
          <ListItem
            button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            className={classNames(
              classes.listItem,
              minimisedActive && classes.navigationItem_active,
            )}
            disableRipple
          >
            <ListItemText className={classes.listItemText}>Еще</ListItemText>
            <DownIcon
              color="inherit"
              className={classNames(
                classes.downIcon,
                minimisedActive && classes.downIconActive,
              )}
            />
          </ListItem>
          <Menu
            anchorEl={anchorEl}
            classes={{
              list: classes.menuList,
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            getContentAnchorEl={undefined}
            data-testid="menu"
          >
            {filteredItems.map(
              ({ title, href, isActive = isActiveDefault }, index) =>
                index > maxItems - 1 && (
                  <Link
                    key={href}
                    to={href}
                    className={classNames(
                      classes.menuNavigationItem,
                      isActive(location.pathname, href) &&
                        classes.navigationSubItem_active,
                    )}
                  >
                    <MenuItem
                      className={classes.menuListItem}
                      onClick={handleClose}
                    >
                      {title}
                    </MenuItem>
                  </Link>
                ),
            )}
          </Menu>
        </>
      )}
    </List>
  );
};
