import React, { ReactNode } from 'react';

import classNames from 'classnames';
import MuiToggleButton from '@material-ui/lab/ToggleButton';
import { useTheme } from '@material-ui/core/styles';

import { useMatchQuery } from '../hooks';
import { Typography } from '../Typography';

import { useStyles } from './ToggleButton.styles';

interface ToggleButtonProps {
  label?: string;
  icon?: ReactNode;
  active?: boolean;
  value?: string;
  className?: string;
  disabled?: boolean;
}

export const ToggleButton = ({
  label,
  icon,
  active = false,
  className,
  disabled,
  ...rest
}: ToggleButtonProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMatchQuery(theme.breakpoints.up('md'));

  return (
    <MuiToggleButton
      {...rest}
      className={classNames(
        classes.toggleButton,
        className,
        active && classes.active,
        disabled && classes.disabled,
        'aqa_toggle_button',
      )}
    >
      {label && (
        <Typography variant={isDesktop ? 'subtitle2' : 'caption'} noWrap>
          {label}
        </Typography>
      )}
      {icon && icon}
    </MuiToggleButton>
  );
};
