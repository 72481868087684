import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints, tokens, px }) => ({
  dropzone: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: px(92),
    backgroundColor: tokens.colors.bg.light.primary,
    border: `${tokens.border.m}px dashed ${tokens.colors.stroke.primary}`,
    borderRadius: tokens.radius.l,
    cursor: 'pointer',

    '&:not($disabled):not($error):hover': {
      borderColor: tokens.colors.stroke.secondary,
    },

    [breakpoints.up('md')]: {
      height: px(140),
    },
  },

  dropzoneMini: {
    width: px(92),

    [breakpoints.up('md')]: {
      width: px(140),
    },
  },

  filePreviewPaper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },

  disabled: {
    backgroundColor: tokens.colors.bg.greayscale.secondary,
    borderStyle: 'solid',
    cursor: 'default',
  },

  error: {
    backgroundColor: tokens.colors.accent.bg.error,
    border: `solid ${tokens.colors.accent.text.error} ${tokens.border.s}px`,
  },
}));

export default useStyles;
