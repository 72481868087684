export interface EventsRouter {
  goToDetail(id: string): void;
  goToList(query?: string): void;
  goToPaperList(query?: string): void;
  goToCreateType(): void;
  goToCreate(search: string): void;
  goToCreateBatch(params: {
    companyId: string;
    eventTypeId: string;
    employeeId?: string;
    parentEventId?: string;
  }): void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventsRouter = Symbol('EventsRouter');
