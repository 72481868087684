import React from 'react';

import classNames from 'classnames';

import { Box } from '../Box';

import {
  CounterBackgroundColor,
  CounterColor,
  CounterSize,
} from './Counter.types';
import { useStyles } from './Counter.styles';

export type CounterProps = {
  backgroundColor?: keyof typeof CounterBackgroundColor;
  className?: string;
  color?: keyof typeof CounterColor;
  counter: number;
  signed?: boolean;
  moreThanMax?: boolean;
  size?: keyof typeof CounterSize;
};

export const Counter = ({
  backgroundColor = 'white',
  className,
  color,
  counter,
  signed = false,
  size = 'medium',
  moreThanMax = false,
}: CounterProps) => {
  const classes = useStyles({
    backgroundColor: CounterBackgroundColor[backgroundColor],
    color: color,
    size: CounterSize[size],
    signed,
  });

  const sign = signed && counter > 0 ? '+' : '';

  return (
    <Box className={classNames(classes.counter, className)}>
      {sign}
      {counter}
      {moreThanMax ? '+' : ''}
    </Box>
  );
};
