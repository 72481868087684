import React from 'react';

import { useTheme } from '@material-ui/core';

import { StageStatus } from './Stage';

interface StartProps {
  first: boolean;
  status: StageStatus;
}

export const StageStart = ({ first, status }: StartProps) => {
  const { tokens } = useTheme();

  const rounded = (
    <path d="M0 8C0 3.58172 3.58172 0 8 0L12 0V24H8C3.58172 24 0 20.4183 0 16V8Z" />
  );

  const angled = (
    <path d="M0 0H12V24H0L4.66513 18.4018C7.75552 14.6934 7.75551 9.30662 4.66513 5.59816L0 0Z" />
  );

  return (
    <svg
      width="12"
      height="24"
      viewBox="0 0 12 24"
      fill={
        status === 'initial'
          ? tokens.colors.bg.greayscale.primary
          : tokens.colors.original.brand.primary
      }
      fillOpacity={status === 'active' ? 0.6 : 1}
      xmlns="http://www.w3.org/2000/svg"
    >
      {first ? rounded : angled}
    </svg>
  );
};
