import React from 'react';

import { Badge } from '../common';
import { EllipsisText } from '../../EllipsisText';
import { Box } from '../../Box';
import { Typography } from '../../Typography';
import { Tooltip } from '../../Tooltip';

import { Option } from './dropdownOption.types';

interface ExtendedOption extends Option {
  subtitle?: string;
}

export const renderTwoLineOption = (option: Option) => {
  const [subtitle, titleFirst, titleSecond] = option.label.split(', ');
  const { badge } = option;

  const isBottom = badge?.position === 'bottom';

  let title = '';

  if (subtitle && !titleFirst) {
    title = subtitle;
  } else {
    title = `${titleFirst || ''}${titleSecond ? ', ' + titleSecond : ''}`;
  }

  return (
    <Box py={badge ? '8' : '12'}>
      {title && (
        <Box>
          <Typography variant="body3">{title}</Typography>
        </Box>
      )}
      <Box
        display="flex"
        alignItems="center"
        flexDirection="row"
        gap="8"
        mt="4"
      >
        {badge && (
          <Box>
            <Badge
              title={badge.title}
              description={badge.description}
              color={badge.color}
            />
          </Box>
        )}
        {title !== subtitle && (
          <Box order={isBottom ? 0 : 1}>
            <Typography
              component="div"
              variant={title ? 'caption' : 'body3'}
              color="textTertiary"
            >
              {subtitle}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const renderOptionWithBadge = (option: Option) => {
  const { badge, label } = option;

  return (
    <Box py={badge ? '8' : '12'}>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="row"
        gap="8"
        mt="4"
      >
        {badge && (
          <Box>
            <Badge
              title={badge.title}
              description={badge.description}
              color={badge.color}
            />
          </Box>
        )}
        <Box>
          <Typography component="div" variant="body3" color="textPrimary">
            {label}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const renderTwoLineOptionEllipsis = (option: Option) => {
  const [title, ...rest] = option.label.split(',');

  return (
    <Box py="12">
      <Box>
        <Typography variant="body3">{title}</Typography>
      </Box>
      <Box mt="2">
        <Typography variant="caption" color="textTertiary">
          <EllipsisText>{rest.join(', ')}</EllipsisText>
        </Typography>
      </Box>
    </Box>
  );
};

export const renderLineOptionWithTooltip = (option: Option) => {
  const { label } = option;

  return (
    <Tooltip title={label} placement="right">
      <Box py="12">
        <Box py="2">
          <Typography variant="body3">
            <EllipsisText rows={1}>{label}</EllipsisText>
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
};

export const renderTwoLineOptionWithSeparateSubtitle = (
  option: ExtendedOption,
) => {
  const { subtitle, label } = option;

  return (
    <Box py="12">
      <Box>
        <Typography variant="body3">{label}</Typography>
      </Box>
      <Box
        display="flex"
        alignItems={'center'}
        flexDirection={'row'}
        gap="8"
        mt="4"
      >
        <Box>
          <Typography component="div" variant="caption" color="textTertiary">
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
