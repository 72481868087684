import React, { useState, useCallback } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import Drawer from '@material-ui/core/Drawer';
import Close from '@material-ui/icons/Close';

import { useInject } from '@vk-hr-tek/core/ioc';
import { Avatar } from '@vk-hr-tek/ui/Avatar';
import { Box } from '@vk-hr-tek/ui/Box';
import { Button } from '@vk-hr-tek/ui/Button';
import { Divider } from '@vk-hr-tek/ui/Divider';
import { IconButton } from '@vk-hr-tek/ui/IconButton';
import { ToggleButton } from '@vk-hr-tek/ui/ToggleButton';
import { ToggleButtonGroup } from '@vk-hr-tek/ui/ToggleButtonGroup';
import { loadChat, selectStatus } from '@vk-hr-tek/core/chat';
import { Dialog } from '@vk-hr-tek/ui/Dialog';
import { CircularProgress } from '@vk-hr-tek/ui/CircularProgress';

import { useDispatch, useSelector, useRole } from '@app/hooks';
import { UserRoleEnum } from '@app/types';

import {
  selectFullName,
  selectHasCompanySide,
  setUserRole,
  getUserPermissions,
} from '../../../user';
import { Logo } from '../../ui/Logo';
import { UserCard } from '../common/UserCard';
import { UserRouter } from '../../../user/types';

import { LogoutConfirmation } from './LogoutConfirmation';
import { useStyles } from './User.styles';

const stringAvatar = (name: string) =>
  `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;

interface UserProps {
  userRepresentative: boolean;
  userRoles: {
    label: string;
    value: string;
  }[];
}

export const User = ({ userRoles, userRepresentative }: UserProps) => {
  const [role, changeRole] = useRole();
  const dispatch = useDispatch();
  const router = useInject<UserRouter>(UserRouter);
  const classes = useStyles();

  const userFullName = useSelector(selectFullName);
  const hasCompanySide = useSelector(selectHasCompanySide);
  const chatStatus = useSelector(selectStatus);

  const [isDrawerOpened, setIsDrawerOpened] = useState<boolean | null>(null);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const toggleDrawer = useCallback(
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setIsDrawerOpened(open);
    },
    [],
  );

  const handleMoveToProfile = useCallback(() => {
    setIsDrawerOpened(false);
    router.goToProfile();
  }, [router]);

  const handleSetRole = useCallback(
    (newRole: UserRoleEnum) => {
      setIsDrawerOpened(false);
      dispatch(setUserRole(newRole));
      changeRole();
      dispatch(getUserPermissions());
    },
    [dispatch, changeRole],
  );

  const handleMenuQuitClick = useCallback(() => {
    setIsDrawerOpened(false);
    setIsConfirmationDialogOpen(true);
  }, []);

  const handleChatSupportClick = () => {
    if (chatStatus === 'complete') {
      window.webim.api.chat.start();
    } else {
      dispatch(loadChat());
    }
    setIsDrawerOpened(false);
  };

  const renderUserRoleButtons = useCallback(() => {
    if (window.REACT_APP_VKHRTEK_LK_DISABLED && !userRepresentative) {
      return null;
    } else {
      return (
        <Box mr="8">
          <ToggleButtonGroup
            value={role}
            exclusive
            onChange={(_, value) => {
              if (value) {
                handleSetRole(value as UserRoleEnum);
              }
            }}
          >
            {userRoles.map(({ value, label }, index) => (
              <ToggleButton
                key={value}
                value={value}
                disabled={userRepresentative}
                aria-label={value}
                label={label}
                className={index ? 'aqa_button_kk' : 'aqa_button_lk'}
              />
            ))}
          </ToggleButtonGroup>
        </Box>
      );
    }
  }, [handleSetRole, role, userRoles, userRepresentative]);

  return (
    <>
      <Box ml="8">
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          onClick={toggleDrawer(true)}
          size="small"
        >
          <Avatar children={stringAvatar(userFullName || '')} />
        </IconButton>
        <Drawer
          open={isDrawerOpened || false}
          onClose={toggleDrawer(false)}
          anchor="right"
        >
          <Box
            maxWidth={305}
            height="100%"
            display="flex"
            flexDirection="column"
            alignItems="stretch"
          >
            <Box mx="16" mt="8" mb="16">
              <Box
                mb="20"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center">
                  <Box mr="12" display="flex" alignItems="center">
                    <Logo variant="24" />
                  </Box>
                </Box>
                <Button
                  size="medium"
                  variant="tertiaryBlue"
                  onClick={toggleDrawer(false)}
                  icon={<Close />}
                />
              </Box>

              {hasCompanySide && renderUserRoleButtons()}
            </Box>
            <Divider />
            <Box
              my="20"
              display="flex"
              flexDirection="column"
              height="100%"
              gap="12"
            >
              {(!window.REACT_APP_VKHRTEK_LK_DISABLED ||
                userRepresentative) && (
                <UserCard
                  onClick={handleMoveToProfile}
                  userFullName={userFullName || ''}
                />
              )}
              <MenuItem onClick={handleChatSupportClick}>
                Чат поддержки
              </MenuItem>
              <MenuItem onClick={handleMenuQuitClick} className={classes.link}>
                <Box display="inline" mr="8" lineHeight={1}>
                  <LogoutIcon />
                </Box>
                Выйти
              </MenuItem>
            </Box>
          </Box>
        </Drawer>
        <LogoutConfirmation
          onClose={() => setIsConfirmationDialogOpen(false)}
          open={isConfirmationDialogOpen}
        />
      </Box>
      <Dialog
        open={chatStatus === 'loading'}
        classes={{ paper: classes.dialog }}
      >
        <Box display="flex" alignItems="center" justifyContent="center" p="16">
          <CircularProgress size={40} />
        </Box>
      </Dialog>
    </>
  );
};
