import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { Dialog } from '@vk-hr-tek/ui/Dialog';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Button } from '@vk-hr-tek/ui/Button';

import { useLogout } from '../../../auth';

import { useStyles } from './User.styles';

type LogoutConfirmationProps = {
  onClose(): void;
  open: boolean;
};

export const LogoutConfirmation = ({
  onClose,
  open,
}: LogoutConfirmationProps) => {
  const logout = useLogout();
  const classes = useStyles();

  return (
    <Dialog classes={{ paper: classes.dialog }} onClose={onClose} open={open}>
      <Box display="flex" flexDirection="column">
        <Box p="48" pb="4" flexGrow={1}>
          <Box mb="24">
            <Typography variant="h5" color="textPrimary">
              Выйти из аккаунта
            </Typography>
          </Box>
          <Typography color="textTertiary">
            Вы точно хотите выйти из своей учетной записи?
          </Typography>
        </Box>
        <Box
          p="16"
          display="flex"
          justifyContent="flex-end"
          bgcolor="bg.greayscale.primary"
        >
          <Box mr="16">
            <Button
              size="large"
              variant="tertiaryLight"
              onClick={() => onClose()}
            >
              Отмена
            </Button>
          </Box>
          <Button size="large" variant="warning" onClick={() => logout()}>
            Выйти
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
