import React from 'react';

import MUIAvatar, {
  AvatarProps as MUIAvatarProps,
} from '@material-ui/core/Avatar';
import MUIBadge from '@material-ui/core/Badge';
import classNames from 'classnames';

import { useStyles } from './Avatar.styles';

export type AvatarSize =
  | 'extraLarge'
  | 'large'
  | 'medium'
  | 'small'
  | 'extraSmall';

type AvatarProps = MUIAvatarProps & {
  withBadge?: boolean;
  size?: AvatarSize;
};

export const Avatar = (props: AvatarProps) => {
  const { withBadge, size = 'medium', children, src, alt } = props;

  const classes = useStyles();

  return withBadge ? (
    <MUIBadge
      className={`aqa_avatar_badge ${classes.badge}`}
      overlap="circular"
      variant="dot"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Avatar
        className="aqa_avatar"
        alt={alt}
        children={children}
        src={src}
        size={size}
      />
    </MUIBadge>
  ) : (
    <MUIAvatar
      src={src}
      alt={alt}
      children={children}
      data-testid="avatar"
      className={classNames('aqa_avatar', classes.avatar, classes[size])}
    />
  );
};
