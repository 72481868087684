import React from 'react';

import { useInject } from '@vk-hr-tek/core/ioc';

import { AuthConfigService } from './services/common/auth.config.service';
import { GeneralAuth, VKAuth, AliAuth, CandidateAuth, OAuth2 } from './pages';

export const Auth = () => {
  const config = useInject(AuthConfigService);

  if (config.getAuthType() === 'ali') {
    return <AliAuth />;
  }

  if (config.getAuthType() === 'oauth2') {
    return <OAuth2 />;
  }

  if (config.getAuthType() === 'candidate') {
    return <CandidateAuth />;
  }

  if (config.getAuthType() === 'general') {
    return <GeneralAuth />;
  }

  return <VKAuth />;
};
