import { Palette } from '@material-ui/core/styles/createPalette';

export enum CounterSize {
  large = 'large',
  medium = 'medium',
  small = 'small',
  extraSmall = 'extraSmall',
}

export type SizeScheme = {
  [key in keyof typeof CounterSize]: {
    height: number;
  };
};

export enum CounterColor {
  green = 'green',
  orange = 'orange',
  red = 'red',
  blue = 'blue',
  purple = 'purple',
  black = 'black',
}

export type ColorScheme = {
  [key in keyof typeof CounterColor]: (palette: Palette) => string;
};

export enum CounterBackgroundColor {
  gray = 'gray',
  green = 'green',
  orange = 'orange',
  red = 'red',
  blue = 'blue',
  lightBlue = 'lightBlue',
  purple = 'purple',
  white = 'white',
}

export type BackgroundColorScheme = {
  [key in keyof typeof CounterBackgroundColor]: (palette: Palette) => string;
};
