import { inject, injectable } from 'inversify';

import { History } from '@vk-hr-tek/core/history';

import { SettingsTemplatesRouter } from '../../settings/types';

@injectable()
export class SettingsTemplatesRouterService implements SettingsTemplatesRouter {
  constructor(@inject(History) private history: History) {}

  goToDetail({ id, companyId }: { id: string; companyId: string }) {
    this.history.push(`/settings/templates/${id}?companyId=${companyId}`);
  }

  goToDetailPreview({ id, companyId }: { id: string; companyId: string }) {
    this.history.push(
      `/settings/templates/${id}/preview?companyId=${companyId}`,
    );
  }

  goToDetailPreviewUpload({
    id,
    companyId,
  }: {
    id: string;
    companyId: string;
  }) {
    this.history.push(
      `/settings/templates/${id}/preview-upload?companyId=${companyId}`,
    );
  }

  goToList(query?: string) {
    if (!query || query === '?') {
      this.history.push('/settings/templates');
      return;
    }

    this.history.push({
      pathname: '/settings/templates',
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }
}
