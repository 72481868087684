import React from 'react';

import { Button } from '@vk-hr-tek/ui/Button';
import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';

import { useDispatch } from '../../../../app/hooks';
import { redirectToQr } from '../../../slice';
import { Page } from '../../../ui/page';

import useStyles from './styles';

export const Greeting = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Box className={classes.greeting}>
      <Page title="Добро пожаловать!" theme="ali">
        <Typography align="center" className="aqa_reg_header">
          Это сервис для электронного оформления кадровых документов (заявления
          на отпуск и любые другие)
        </Typography>
        <Box pt="40" display="flex" justifyContent="center">
          <Box width={215}>
            <Button
              fullWidth
              onClick={() => dispatch(redirectToQr())}
              size="large"
            >
              Войти
            </Button>
          </Box>
        </Box>
      </Page>
      <div className={classes.mock} />
    </Box>
  );
};
