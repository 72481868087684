import React from 'react';

import { SvgIcon } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';

import { useTheme } from '@vk-hr-tek/ui/Theme';

import { useStyles } from './LogoEmptyIcon.styles';

type LogoIconVariants = '24' | '32' | '72' | '80';

type LogoIconProps = {
  variant?: LogoIconVariants;
};

const getSizeClass = (variant: LogoIconVariants, classes: ClassNameMap) => {
  switch (variant) {
    case '24':
      return classes.icon24;
    case '72':
      return classes.icon72;
    case '80':
      return classes.icon80;
    default:
      return classes.icon32;
  }
};

const LogoEmptyIcon = ({ variant = '32' }: LogoIconProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const sizeClass = getSizeClass(variant, classes);

  if (theme === 'otp') {
    return (
      <SvgIcon className={sizeClass} htmlColor="#ffffff" viewBox="0 0 32 33">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 15.5122C0 7.24105 6.6983 0.515625 14.9966 0.515625V5.93935C9.70847 5.93935 5.42373 10.2241 5.42373 15.5122C5.42373 20.8004 9.70847 25.0851 14.9966 25.0851C20.2576 25.0851 24.5695 20.8275 24.5695 15.458H29.9932C29.9932 23.7834 23.2678 30.4817 14.9966 30.4817C6.72542 30.5088 0 23.8105 0 15.5122Z"
          fill="#006C3F"
        />
        <path
          d="M8.37891 15.5113C8.37891 11.8231 11.362 8.86719 15.023 8.86719C18.7111 8.86719 21.6942 11.8502 21.6942 15.5113C21.6942 19.1994 18.7111 22.1553 15.0501 22.1553C11.362 22.1824 8.37891 19.1994 8.37891 15.5113Z"
          fill="#50B848"
        />
        <path
          d="M19.6602 7.07764C19.6602 4.96238 21.3686 3.25391 23.4839 3.25391C25.5991 3.25391 27.3076 4.96238 27.3076 7.07764C27.3076 9.19289 25.5991 10.9014 23.4839 10.9014C21.3686 10.9014 19.6602 9.19289 19.6602 7.07764Z"
          fill="#006C3F"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon className={sizeClass} htmlColor="#ffffff" viewBox="0 0 32 33">
      <mask
        id="mask0_5868_54827"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <path
          d="M0 15.3346C0 8.10447 0 4.49117 2.24734 2.24734C4.49468 0.00351155 8.10447 0 15.3346 0H16.6689C23.8955 0 27.5088 0 29.7562 2.24734C32.0035 4.49468 32 8.10447 32 15.3346V16.6689C32 23.8955 32 27.5088 29.7562 29.7562C27.5123 32.0035 23.8955 32 16.6689 32H15.3346C8.10447 32 4.49117 32 2.24734 29.7562C0.00351155 27.5123 0 23.8955 0 16.6689V15.3346Z"
          fill="#212121"
        />
      </mask>
      <g mask="url(#mask0_5868_54827)">
        <path
          d="M0 15.3346C0 8.10447 0 4.49117 2.24734 2.24734C4.49468 0.00351155 8.10447 0 15.3346 0H16.6689C23.8955 0 27.5088 0 29.7562 2.24734C32.0035 4.49468 32 8.10447 32 15.3346V16.6689C32 23.8955 32 27.5088 29.7562 29.7562C27.5123 32.0035 23.8955 32 16.6689 32H15.3346C8.10447 32 4.49117 32 2.24734 29.7562C0.00351155 27.5123 0 23.8955 0 16.6689V15.3346Z"
          fill="#0077FF"
        />
        <path d="M32 0H10V12H20V22H32V0Z" fill="#00D9ED" />
        <path d="M6 26H15.375L15.375 16.625H6L6 26Z" fill="white" />
      </g>
    </SvgIcon>
  );
};

export default LogoEmptyIcon;
