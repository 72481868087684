import React, { useCallback } from 'react';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { useInject } from '@vk-hr-tek/core/ioc';
import { Box } from '@vk-hr-tek/ui/Box';
import { DotIcon } from '@vk-hr-tek/ui/icons/DotIcon';
import { Link } from '@vk-hr-tek/ui/Link';
import { Typography } from '@vk-hr-tek/ui/Typography';

import { AttorneyType } from '@app/types';

import { UserRouter } from '../../../types';

export const Attorney = ({
  id,
  issuer,
  status,
  readableStatus,
  validFrom,
  validTo,
  companyId,
  typographyColor,
}: AttorneyType) => {
  const router = useInject<UserRouter>(UserRouter);

  const goToAttorneyDetail = useCallback(() => {
    router.goToProfileAttorney({ id, companyId });
  }, [id, companyId, router]);

  return (
    <Link variant="simple" onClick={goToAttorneyDetail}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        radius="l"
        bgcolor="bg.greayscale.primary"
        px="16"
        py="8"
      >
        <Box>
          <Box mb="4">
            <Typography variant="body3">{issuer}</Typography>
          </Box>
          <Box display="flex" mr="4">
            <Box mr="2">
              <Typography variant="caption" color={typographyColor}>
                {readableStatus}
              </Typography>
            </Box>

            {status !== 'new' && (
              <>
                <Box display="flex" alignItems="center">
                  <DotIcon color="disabled" />
                </Box>
                <Box ml="2">
                  <Typography variant="caption" color="textTertiary">
                    {validFrom} - {validTo}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>

        <Box
          fontSize={20}
          color="text.light.secondary"
          display="flex"
          alignItems="center"
        >
          <ChevronRightIcon fontSize="inherit" color="inherit" />
        </Box>
      </Box>
    </Link>
  );
};
