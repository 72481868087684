import { inject, injectable } from 'inversify';

import { History } from '@vk-hr-tek/core/history';

import { VacationsRouter } from '../../vacations/types';

@injectable()
export class VacationsRouterService implements VacationsRouter {
  constructor(@inject(History) private history: History) {}

  goToDetail(id: string) {
    this.history.push(`/vacations/${id}`);
  }

  goToList(query?: string) {
    if (!query || query === '?') {
      this.history.push('/vacations');
      return;
    }

    this.history.push({
      pathname: '/vacations',
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }
}
