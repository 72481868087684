import React, { ReactNode } from 'react';

import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';

interface PageHeaderProps {
  actions?: ReactNode | ReactNode[];
  additionalActions?: ReactNode | ReactNode[];
  link?: ReactNode;
  showTitleMobile?: boolean;
  title: ReactNode;
}

export const PageHeader = ({
  actions,
  additionalActions,
  link,
  showTitleMobile = false,
  title: titleNode,
}: PageHeaderProps) => {
  const isDesktop = useIsDesktop();

  if (isDesktop) {
    return !!titleNode || actions || additionalActions ? (
      <Box pb="20" pt="32">
        <Box display="flex" justifyContent="space-between">
          <Box flexGrow={1} mr="40">
            {link}
            <Box mt={link ? '4' : '24'}>
              <Box my="2">
                <Typography className="aqa_entity_header" variant="h4">
                  {titleNode}
                </Typography>
              </Box>
            </Box>
          </Box>
          {actions ? (
            <Box alignItems="flex-end" display="flex">
              {actions}
            </Box>
          ) : (
            <Box alignItems="flex-end" display="flex">
              {additionalActions}
            </Box>
          )}
        </Box>
        {actions && additionalActions && (
          <Box mt={!!titleNode || !!actions ? '20' : '0'}>
            {additionalActions}
          </Box>
        )}
      </Box>
    ) : null;
  }

  return showTitleMobile || additionalActions ? (
    <Box pb="20" pt="32" px="16">
      {showTitleMobile ? (
        <Box display="flex" justifyContent="space-between">
          <Box flexGrow={1} mr="16">
            {link}
            <Box mt={link ? '4' : '0'}>
              <Box my="2">
                <Typography className="aqa_entity_header" variant="h5">
                  {titleNode}
                </Typography>
              </Box>
            </Box>
          </Box>
          {actions ? (
            <Box alignItems="flex-end" display="flex">
              {actions}
            </Box>
          ) : (
            <Box alignItems="flex-end" display="flex">
              {additionalActions}
            </Box>
          )}
        </Box>
      ) : (
        <Box position="absolute" width="100%" height="100%" right="100%">
          <Box
            display="flex"
            mb="24"
            position="sticky"
            zIndex={100}
            top="calc(100% - 86px)"
            transform="translateX(100%)"
            justifyContent="center"
          >
            {actions}
          </Box>
        </Box>
      )}
      {additionalActions && (
        <Box mt={showTitleMobile ? '16' : '0'}>{additionalActions}</Box>
      )}
    </Box>
  ) : null;
};
