import { useState, useCallback } from 'react';

import { useForm, useFormState } from 'react-final-form';
import { isEqual } from 'lodash';

interface InitialValuesType {
  queue: Array<unknown>;
  currentQueuePosition: number;
}

export const useUndo = () => {
  const formApi = useForm();
  const [state, setState] = useState<InitialValuesType>({
    queue: [],
    currentQueuePosition: 0,
  });

  const onValuesChange = useCallback(
    (formState) => {
      setState((prevState) => {
        if (
          !isEqual(
            prevState.queue[prevState.currentQueuePosition],
            formState.values,
          )
        ) {
          const newQueue = prevState.queue.slice(
            prevState.currentQueuePosition,
            prevState.queue.length,
          );
          return {
            ...prevState,
            currentQueuePosition: 0,
            queue: [formState.values, ...newQueue],
          };
        } else {
          return prevState;
        }
      });
    },
    [setState],
  );

  const onInitialValuesChange = useCallback(
    ({ initialValues }) => {
      setState({
        currentQueuePosition: 0,
        queue: [initialValues],
      });
    },
    [setState],
  );

  useFormState({
    subscription: { values: true },
    onChange: onValuesChange,
  });

  useFormState({
    subscription: { initialValues: true },
    onChange: onInitialValuesChange,
  });

  return {
    undo: () => {
      setState((prevState) => ({
        ...prevState,
        currentQueuePosition: prevState.currentQueuePosition + 1,
      }));
      formApi.change('', state.queue[state.currentQueuePosition + 1]);
    },
    redo: () => {
      setState((prevState) => ({
        ...prevState,
        currentQueuePosition: prevState.currentQueuePosition - 1,
      }));
      formApi.change('', state.queue[state.currentQueuePosition - 1]);
    },
    canUndo: state.queue.length - 1 > state.currentQueuePosition,
    canRedo: 0 < state.currentQueuePosition,
    queue: state.queue,
  };
};
