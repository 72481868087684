import { Type, Transform } from 'class-transformer';
import {
  IsInt,
  IsNumber,
  IsIn,
  IsArray,
  IsString,
  IsDateString,
  IsUUID,
  IsNotEmpty,
  ValidateNested,
  IsOptional,
  IsBoolean,
} from 'class-validator';

export class AttorneyXMLHash {
  @IsNotEmpty()
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  attorney_hash: string;

  @IsOptional()
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  revocation_hash?: string;

  @IsNotEmpty()
  @IsUUID()
  attorney_id: string;
}

export class GetAttorneyXMLHashesResponse {
  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => AttorneyXMLHash)
  hashes: AttorneyXMLHash[];
}

export class Company {
  @IsNotEmpty()
  @IsUUID()
  id: string;

  @IsNotEmpty()
  @IsString()
  name: string;
}

export type AttorneyStatus = string;

export class Attorney {
  @IsNotEmpty()
  @IsUUID()
  id: string;

  @IsNotEmpty()
  @IsString()
  @IsIn(['new', 'signed', 'expired', 'revoking', 'revoked', 'canceled'])
  status: 'new' | 'signed' | 'expired' | 'revoking' | 'revoked' | 'canceled';

  @IsNotEmpty()
  @IsString()
  author_full_name: string;

  @IsNotEmpty()
  @IsUUID()
  author_user_id: string;

  @IsNotEmpty()
  @ValidateNested()
  @Type(() => Company)
  company: Company;

  @IsNotEmpty()
  @IsBoolean()
  is_default: boolean;

  @IsNotEmpty()
  @IsDateString()
  creation_date: string;

  @IsNotEmpty()
  @IsDateString()
  valid_from: string;

  @IsNotEmpty()
  @IsDateString()
  valid_to: string;

  @IsNotEmpty()
  @IsString()
  powers: string;

  @IsNotEmpty()
  @IsUUID()
  user_id: string;

  @IsNotEmpty()
  @IsString()
  full_name: string;

  @IsNotEmpty()
  @IsString()
  snils: string;

  @IsNotEmpty()
  @IsString()
  issuer: string;

  @IsNotEmpty()
  @IsBoolean()
  imported: boolean;

  @IsOptional()
  @IsString()
  revocation_reason?: string;
}

export class AttorneysResponse {
  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => Attorney)
  attorneys: Attorney[];
}

export type CompanyEIO = string;

export type CompanyType = string;

export class AttorneyCompany {
  @IsNotEmpty()
  @IsString()
  @IsIn(['legal', 'foreign', 'individual'])
  type: 'legal' | 'foreign' | 'individual';

  @IsOptional()
  @IsString()
  @IsIn(['management_company', 'person', 'individual'])
  eio?: 'management_company' | 'person' | 'individual';

  @IsNotEmpty()
  @ValidateNested()
  @Type(() => Company)
  company: Company;

  @IsNotEmpty()
  @IsBoolean()
  can_sign: boolean;

  @IsNotEmpty()
  @IsBoolean()
  can_edit: boolean;
}

export class AttorneyCompanyListResponse {
  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => AttorneyCompany)
  companies: AttorneyCompany[];
}

export class FilterSelectOption {
  @IsNotEmpty()
  @IsString()
  label: string;

  @IsNotEmpty()
  @IsString()
  value: string;
}

export type AttorneyListAvailableFiltersEmployeeOptionsResponse =
  FilterSelectOption[];

export class FilterTimeRange {
  @IsNotEmpty()
  @IsString()
  key: string;

  @IsNotEmpty()
  @IsString()
  name: string;

  @IsNotEmpty()
  @IsString()
  @IsIn(['timerange'])
  type: 'timerange';

  @IsNotEmpty()
  @IsString()
  @IsIn(['till_now', 'any', 'from_now'])
  allow_dates: 'till_now' | 'any' | 'from_now';
}

export class FilterBool {
  @IsNotEmpty()
  @IsString()
  key: string;

  @IsNotEmpty()
  @IsString()
  name: string;

  @IsNotEmpty()
  @IsString()
  @IsIn(['bool'])
  type: 'bool';
}

export class FilterSearch {
  @IsNotEmpty()
  @IsString()
  key: string;

  @IsNotEmpty()
  @IsString()
  name: string;

  @IsNotEmpty()
  @IsString()
  @IsIn(['search'])
  type: 'search';

  @IsNotEmpty()
  @IsString()
  url: string;

  @IsNotEmpty()
  @IsString()
  placeholder: string;
}

export class FilterText {
  @IsNotEmpty()
  @IsString()
  key: string;

  @IsNotEmpty()
  @IsString()
  name: string;

  @IsNotEmpty()
  @IsString()
  @IsIn(['text'])
  type: 'text';
}

export class FilterMultiple {
  @IsNotEmpty()
  @IsString()
  key: string;

  @IsNotEmpty()
  @IsString()
  name: string;

  @IsNotEmpty()
  @IsString()
  @IsIn(['multiple'])
  type: 'multiple';

  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => FilterSelectOption)
  options: FilterSelectOption[];
}

export class FilterSelect {
  @IsNotEmpty()
  @IsString()
  key: string;

  @IsNotEmpty()
  @IsString()
  name: string;

  @IsNotEmpty()
  @IsString()
  @IsIn(['select'])
  type: 'select';

  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => FilterSelectOption)
  options: FilterSelectOption[];
}

export class AttorneyUserAvailableFilters {
  @IsNotEmpty()
  @IsArray()
  filters: (
    | FilterSelect
    | FilterMultiple
    | FilterText
    | FilterSearch
    | FilterBool
    | FilterTimeRange
  )[];
}

export class AttorneyUser {
  @IsNotEmpty()
  @IsUUID()
  id: string;

  @IsNotEmpty()
  @IsString()
  full_name: string;

  @IsNotEmpty()
  @ValidateNested()
  @Type(() => Company)
  company: Company;

  @IsNotEmpty()
  @IsString()
  snils: string;

  @IsNotEmpty()
  @IsArray()
  groups: string[];

  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => Attorney)
  attorneys: Attorney[];
}

export class AttorneyUserListResponse {
  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => AttorneyUser)
  attorney_users: AttorneyUser[];

  @IsNotEmpty()
  @IsInt()
  total: number;
}

export class AttorneyAvailableFilters {
  @IsNotEmpty()
  @IsArray()
  filters: (
    | FilterSelect
    | FilterMultiple
    | FilterText
    | FilterSearch
    | FilterBool
    | FilterTimeRange
  )[];
}

export class AttorneyListResponse {
  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => Attorney)
  attorneys: Attorney[];

  @IsNotEmpty()
  @IsInt()
  total: number;
}

export class UserTelegramInitResponse {
  @IsNotEmpty()
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  redirect_url: string;
}

export type OneCVKAccessTokenStatus = string;

export class OneCVKAccessTokenStatusResponse {
  @IsNotEmpty()
  @IsString()
  @IsIn(['not_issued', 'active', 'expired'])
  status: 'not_issued' | 'active' | 'expired';

  @IsOptional()
  @IsDateString()
  expires_at?: string;
}

export type AccessToken = string;

export class GenerateOneCVKAccessTokenResponse {
  @IsNotEmpty()
  @IsString()
  access_token: string;

  @IsNotEmpty()
  @IsDateString()
  expires_at: string;
}

export class UserLoginResponse {
  @IsNotEmpty()
  @IsString()
  access_token: string;
}

export class UserRegisterPhoneVerifiedResponse {
  @IsNotEmpty()
  @IsString()
  access_token: string;
}

export class UserRegisterResponse {
  @IsNotEmpty()
  @IsString()
  access_token: string;
}

export class CompanyEventTypeItem {
  @IsNotEmpty()
  @IsUUID()
  id: string;

  @IsNotEmpty()
  @IsString()
  name: string;
}

export class GetUserManagerSubstitutesCreateOptionsResponse {
  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => CompanyEventTypeItem)
  event_types: CompanyEventTypeItem[];
}

export type UnepType = string;

export class CompanyItem {
  @IsNotEmpty()
  @IsUUID()
  id: string;

  @IsNotEmpty()
  @IsString()
  name: string;

  @IsOptional()
  @IsString()
  tsp_url?: string;

  @IsOptional()
  @IsString()
  @IsIn(['api', 'browser_plugin'])
  hash_source?: 'api' | 'browser_plugin';
}

export class CompanyEmployee {
  @IsNotEmpty()
  @IsUUID()
  id: string;

  @IsNotEmpty()
  @IsString()
  name: string;

  @IsNotEmpty()
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  first_name: string;

  @IsNotEmpty()
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  middle_name: string;

  @IsNotEmpty()
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  last_name: string;

  @IsNotEmpty()
  @ValidateNested()
  @Type(() => CompanyItem)
  company: CompanyItem;

  @IsOptional()
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  email?: string;

  @IsOptional()
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  phone?: string;

  @IsNotEmpty()
  @IsString()
  personnel_number: string;

  @IsOptional()
  @IsString()
  position?: string;

  @IsOptional()
  @IsString()
  @IsIn(['kontur', 'goskey', 'cryptopro_simple', 'cryptopro_local', 'disabled'])
  unep_type?:
    | 'kontur'
    | 'goskey'
    | 'cryptopro_simple'
    | 'cryptopro_local'
    | 'disabled';
}

export class ManagerSubstitutesEventType {
  @IsNotEmpty()
  @ValidateNested()
  @Type(() => CompanyEventTypeItem)
  event_type: CompanyEventTypeItem;

  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => CompanyEmployee)
  substitutes: CompanyEmployee[];
}

export class GetUserManagerSubstitutesResponse {
  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => ManagerSubstitutesEventType)
  substitutes: ManagerSubstitutesEventType[];
}

export class UnitManagerSetting {
  @IsNotEmpty()
  @ValidateNested()
  @Type(() => CompanyEventTypeItem)
  event_type: CompanyEventTypeItem;

  @IsNotEmpty()
  @IsBoolean()
  direct_only: boolean;
}

export class Unit {
  @IsNotEmpty()
  @IsUUID()
  id: string;

  @IsNotEmpty()
  @IsString()
  name: string;

  @IsOptional()
  @IsUUID()
  parent_id?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => CompanyEmployee)
  manager?: CompanyEmployee;

  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => UnitManagerSetting)
  manager_settings?: UnitManagerSetting[];
}

export class ManageableCompanyUnit {
  @IsNotEmpty()
  @ValidateNested()
  @Type(() => Unit)
  unit: Unit;

  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => CompanyEventTypeItem)
  direct_setting_event_types?: CompanyEventTypeItem[];

  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => CompanyEventTypeItem)
  all_setting_event_types?: CompanyEventTypeItem[];
}

export class ManageableCompanyItem {
  @IsNotEmpty()
  @IsUUID()
  id: string;

  @IsNotEmpty()
  @IsString()
  name: string;

  @IsNotEmpty()
  @ValidateNested()
  @Type(() => CompanyEmployee)
  employee: CompanyEmployee;

  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => ManagerSubstitutesEventType)
  substitutes: ManagerSubstitutesEventType[];

  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => ManageableCompanyUnit)
  functional_manageable_units?: ManageableCompanyUnit[];
}

export class GetManageableCompaniesResponse {
  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => ManageableCompanyItem)
  companies: ManageableCompanyItem[];
}

export class UserPermissions {
  @IsNotEmpty()
  @IsBoolean()
  view_events: boolean;

  @IsNotEmpty()
  @IsBoolean()
  view_event_to_paper: boolean;

  @IsNotEmpty()
  @IsBoolean()
  view_employees: boolean;

  @IsNotEmpty()
  @IsBoolean()
  view_policies: boolean;

  @IsNotEmpty()
  @IsBoolean()
  view_company: boolean;

  @IsNotEmpty()
  @IsBoolean()
  view_company_profiles: boolean;

  @IsNotEmpty()
  @IsBoolean()
  view_settings: boolean;

  @IsNotEmpty()
  @IsBoolean()
  view_attorneys: boolean;

  @IsNotEmpty()
  @IsBoolean()
  view_candidates: boolean;

  @IsNotEmpty()
  @IsBoolean()
  view_vacations: boolean;

  @IsNotEmpty()
  @IsBoolean()
  view_vacations_settings: boolean;

  @IsNotEmpty()
  @IsBoolean()
  view_payslips: boolean;

  @IsNotEmpty()
  @IsBoolean()
  view_smev_errors: boolean;
}

export class UkepCertificate {
  @IsNotEmpty()
  @IsDateString()
  valid_from: string;

  @IsNotEmpty()
  @IsDateString()
  valid_to: string;

  @IsNotEmpty()
  @IsString()
  issued_by: string;

  @IsNotEmpty()
  @IsString()
  owner: string;

  @IsNotEmpty()
  @IsString()
  serial_number: string;

  @IsNotEmpty()
  @IsUUID()
  company_id: string;

  @IsNotEmpty()
  @IsString()
  company_name: string;
}

export class UserResponseNon_representative {
  @IsNotEmpty()
  @IsString()
  text: string;

  @IsOptional()
  @IsString()
  linktext?: string;

  @IsOptional()
  @IsString()
  link?: string;
}

export type CertificateReleaseMethod = string;

export type CertificateStatus = string;

export class UserResponseCertificate {
  @IsNotEmpty()
  @IsString()
  @IsIn(['kontur', 'goskey', 'cryptopro_simple', 'cryptopro_local', 'disabled'])
  unep_type:
    | 'kontur'
    | 'goskey'
    | 'cryptopro_simple'
    | 'cryptopro_local'
    | 'disabled';

  @IsNotEmpty()
  @IsBoolean()
  exists: boolean;

  @IsNotEmpty()
  @IsString()
  @IsIn([
    'waiting_to_create',
    'need_verify',
    'waiting_confirmation',
    'verifying',
    'released',
    'expired',
    'error',
    'release_paused',
  ])
  status:
    | 'waiting_to_create'
    | 'need_verify'
    | 'waiting_confirmation'
    | 'verifying'
    | 'released'
    | 'expired'
    | 'error'
    | 'release_paused';

  @IsOptional()
  @IsString()
  @IsIn(['sms', 'esia'])
  release_method?: 'sms' | 'esia';

  @IsOptional()
  @IsDateString()
  valid_from?: string;

  @IsOptional()
  @IsDateString()
  valid_to?: string;
}

export class ProfileItem {
  @IsNotEmpty()
  @IsUUID()
  id: string;

  @IsNotEmpty()
  @IsString()
  name: string;
}

export class UserGroup {
  @IsNotEmpty()
  @IsInt()
  id: number;

  @IsNotEmpty()
  @IsString()
  name: string;

  @IsNotEmpty()
  @IsBoolean()
  is_admin: boolean;

  @IsNotEmpty()
  @IsBoolean()
  is_recruiter: boolean;

  @IsNotEmpty()
  @ValidateNested()
  @Type(() => Company)
  company: Company;

  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => ProfileItem)
  profiles: ProfileItem[];
}

export type Snils = string;

export class EmployeePermissions {
  @IsNotEmpty()
  @IsBoolean()
  edit_substitutes: boolean;
}

export class AuthEmployeeManager {
  @IsNotEmpty()
  @IsUUID()
  id: string;

  @IsNotEmpty()
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  name: string;
}

export class AuthEmployee {
  @IsNotEmpty()
  @IsUUID()
  id: string;

  @IsOptional()
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  email?: string;

  @IsNotEmpty()
  @ValidateNested()
  @Type(() => Company)
  company: Company;

  @IsOptional()
  @IsDateString()
  dismissed_at?: string;

  @IsNotEmpty()
  @IsString()
  personnel_number: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => AuthEmployeeManager)
  manager?: AuthEmployeeManager;

  @IsNotEmpty()
  @IsString()
  position: string;

  @IsNotEmpty()
  @IsString()
  unit: string;

  @IsNotEmpty()
  @ValidateNested()
  @Type(() => EmployeePermissions)
  permissions: EmployeePermissions;

  @IsOptional()
  @IsInt()
  vacation_days?: number;

  @IsNotEmpty()
  @IsBoolean()
  import_vacation_days: boolean;

  @IsNotEmpty()
  @IsString()
  @IsIn(['kontur', 'goskey', 'cryptopro_simple', 'cryptopro_local', 'disabled'])
  unep_type:
    | 'kontur'
    | 'goskey'
    | 'cryptopro_simple'
    | 'cryptopro_local'
    | 'disabled';
}

export class UserResponse {
  @IsNotEmpty()
  @IsUUID()
  id: string;

  @IsNotEmpty()
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  first_name: string;

  @IsNotEmpty()
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  last_name: string;

  @IsNotEmpty()
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  middle_name: string;

  @IsNotEmpty()
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  phone: string;

  @IsNotEmpty()
  @IsBoolean()
  has_company_side: boolean;

  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => AuthEmployee)
  employees: AuthEmployee[];

  @IsNotEmpty()
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsDateString()
  birth_date: string;

  @IsNotEmpty()
  @IsString()
  snils: string;

  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => UserGroup)
  groups: UserGroup[];

  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => Attorney)
  attorneys: Attorney[];

  @IsNotEmpty()
  @ValidateNested()
  @Type(() => UserResponseCertificate)
  certificate: UserResponseCertificate;

  @IsNotEmpty()
  @IsBoolean()
  telegram_enabled: boolean;

  @IsNotEmpty()
  @IsBoolean()
  representative: boolean;

  @IsOptional()
  @ValidateNested()
  @Type(() => UserResponseNon_representative)
  non_representative?: UserResponseNon_representative;

  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => UkepCertificate)
  ukep_certificates?: UkepCertificate[];
}

export type EmployeeStatus = string;

export class UserEmployeeListItem {
  @IsNotEmpty()
  @IsUUID()
  id: string;

  @IsNotEmpty()
  @ValidateNested()
  @Type(() => CompanyItem)
  company: CompanyItem;

  @IsNotEmpty()
  @IsString()
  personnel_number: string;

  @IsOptional()
  @IsDateString()
  dismissed_at?: string;

  @IsNotEmpty()
  @IsString()
  position: string;

  @IsNotEmpty()
  @IsString()
  unit: string;

  @IsNotEmpty()
  @IsString()
  @IsIn([
    'data_received',
    'documents_verification',
    'unep_application_signing',
    'unep_releasing',
    'unep_release_failed',
    'unep_release_paused',
    'invite_sent',
    'invite_expired',
    'active',
    'dismissed',
    'wait_registration',
  ])
  status:
    | 'data_received'
    | 'documents_verification'
    | 'unep_application_signing'
    | 'unep_releasing'
    | 'unep_release_failed'
    | 'unep_release_paused'
    | 'invite_sent'
    | 'invite_expired'
    | 'active'
    | 'dismissed'
    | 'wait_registration';

  @IsNotEmpty()
  @IsDateString()
  status_changed_at: string;

  @IsNotEmpty()
  @IsDateString()
  employee_created_at: string;
}

export class UserListItem {
  @IsNotEmpty()
  @IsUUID()
  id: string;

  @IsNotEmpty()
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  first_name: string;

  @IsNotEmpty()
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  middle_name: string;

  @IsNotEmpty()
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  last_name: string;

  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => UserEmployeeListItem)
  employees: UserEmployeeListItem[];
}

export class UserListResponse {
  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => UserListItem)
  users: UserListItem[];
}

export type EmployeeAvailableFiltersEmployeeOptionsResponse =
  FilterSelectOption[];

export class FilterMultipleSearch {
  @IsNotEmpty()
  @IsString()
  key: string;

  @IsNotEmpty()
  @IsString()
  name: string;

  @IsNotEmpty()
  @IsString()
  @IsIn(['multiple-search'])
  type: 'multiple-search';

  @IsNotEmpty()
  @IsString()
  url: string;

  @IsNotEmpty()
  @IsString()
  placeholder: string;
}

export class AvailableFilters {
  @IsNotEmpty()
  @IsArray()
  filters: (
    | FilterSelect
    | FilterMultiple
    | FilterText
    | FilterSearch
    | FilterMultipleSearch
    | FilterBool
    | FilterTimeRange
  )[];
}

export class EmployeeXlsxResponse {
  @IsNotEmpty()
  @IsUUID()
  download_id: string;
}

export class SmevError {
  @IsNotEmpty()
  @IsUUID()
  id: string;

  @IsNotEmpty()
  @IsString()
  code: string;

  @IsNotEmpty()
  @IsString()
  @IsIn(['fatal', 'warning'])
  level: 'fatal' | 'warning';

  @IsNotEmpty()
  @IsString()
  description: string;

  @IsNotEmpty()
  @IsBoolean()
  ignore: boolean;
}

export class EmployeeListItem {
  @IsNotEmpty()
  @IsUUID()
  id: string;

  @IsOptional()
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  email?: string;

  @IsNotEmpty()
  @ValidateNested()
  @Type(() => Company)
  company: Company;

  @IsNotEmpty()
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  first_name: string;

  @IsNotEmpty()
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  middle_name: string;

  @IsNotEmpty()
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  last_name: string;

  @IsNotEmpty()
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsDateString()
  birth_date: string;

  @IsNotEmpty()
  @IsString()
  personnel_number: string;

  @IsNotEmpty()
  @IsDateString()
  created_at: string;

  @IsNotEmpty()
  @IsBoolean()
  has_invite: boolean;

  @IsOptional()
  @IsDateString()
  dismissed_at?: string;

  @IsNotEmpty()
  @IsString()
  position: string;

  @IsNotEmpty()
  @IsString()
  unit: string;

  @IsNotEmpty()
  @IsString()
  @IsIn([
    'data_received',
    'documents_verification',
    'unep_application_signing',
    'unep_releasing',
    'unep_release_failed',
    'unep_release_paused',
    'invite_sent',
    'invite_expired',
    'active',
    'dismissed',
    'wait_registration',
  ])
  status:
    | 'data_received'
    | 'documents_verification'
    | 'unep_application_signing'
    | 'unep_releasing'
    | 'unep_release_failed'
    | 'unep_release_paused'
    | 'invite_sent'
    | 'invite_expired'
    | 'active'
    | 'dismissed'
    | 'wait_registration';

  @IsNotEmpty()
  @IsDateString()
  status_changed_at: string;

  @IsOptional()
  @IsString()
  @IsIn(['kontur', 'goskey', 'cryptopro_simple', 'cryptopro_local', 'disabled'])
  unep_type?:
    | 'kontur'
    | 'goskey'
    | 'cryptopro_simple'
    | 'cryptopro_local'
    | 'disabled';

  @IsOptional()
  @ValidateNested()
  @Type(() => SmevError)
  smev_error?: SmevError;
}

export class EmployeeListResponse {
  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => EmployeeListItem)
  employees: EmployeeListItem[];

  @IsNotEmpty()
  @IsInt()
  total_count: number;
}

export class EmployeeInviteVerifyResponse {
  @IsNotEmpty()
  @IsString()
  @IsIn(['active', 'registration', 'login', 'phone_change'])
  status: 'active' | 'registration' | 'login' | 'phone_change';

  @IsOptional()
  @IsString()
  phone?: string;
}

export class OAuth2VerifyResponse {
  @IsOptional()
  @IsString()
  access_token?: string;

  @IsNotEmpty()
  @IsString()
  @IsIn(['registration', 'login', 'phone_change'])
  status: 'registration' | 'login' | 'phone_change';
}

export class CheckEmployeeInviteByFiltersResponse {
  @IsNotEmpty()
  @IsNumber()
  total_invitations_available: number;
}
