import { inject, injectable } from 'inversify';

import { History } from '@vk-hr-tek/core/history';

import { SettingsEventTypesRouter } from '../../settings/types';

@injectable()
export class SettingsEventTypesRouterService
  implements SettingsEventTypesRouter
{
  constructor(@inject(History) private history: History) {}

  goToDetail({ id, companyId }: { id: string; companyId: string }) {
    this.history.push(`/settings/event-types/${id}?companyId=${companyId}`);
  }
}
