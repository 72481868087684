import React, { forwardRef } from 'react';

import { useTheme } from '@material-ui/core';
import {
  borders,
  display,
  flexbox,
  grid,
  positions,
  shadows,
  sizing,
  typography,
} from '@material-ui/system';
import styled from 'styled-components';
import { get } from 'lodash';

import { Spacing, BorderRadius } from '../Theme';

import {
  BoxProps,
  StyledBoxProps,
  SpacingValues,
  RadiusSize,
} from './Box.types';

const getRadiusString = (radius?: RadiusSize, radiusTokens?: BorderRadius) => {
  if (!radius || !radiusTokens) {
    return undefined;
  }

  return Array.isArray(radius)
    ? radius.map((el) => `${radiusTokens[el]}px`).join(' ')
    : radiusTokens[radius];
};

export const Box = styled(
  forwardRef(({ component, testId = 'box', ...props }: BoxProps, ref) => {
    const {
      children,
      className,
      style,
      id,
      tabIndex,
      cursor,
      onClick,
      onFocus,
      onBlur,
      onChange,
      onContextMenu,
      onDrag,
      onDragEnd,
      onDragEnter,
      onDragExit,
      onDragLeave,
      onDragOver,
      onDragStart,
      onDrop,
      onKeyDown,
      onKeyPress,
      onKeyUp,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      onMouseMove,
      onMouseOut,
      onMouseOver,
      onMouseUp,
    } = props;

    const htmlProps = {
      ref,
      children,
      className: `aqa_box${className ? ` ${className}` : ''}`,
      style,
      id,
      tabIndex,
      cursor,
      onClick,
      onFocus,
      onBlur,
      onChange,
      onContextMenu,
      onDrag,
      onDragEnd,
      onDragEnter,
      onDragExit,
      onDragLeave,
      onDragOver,
      onDragStart,
      onDrop,
      onKeyDown,
      onKeyPress,
      onKeyUp,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      onMouseMove,
      onMouseOut,
      onMouseOver,
      onMouseUp,
      'data-testid': testId,
    };

    if (!component) {
      return React.createElement('div', htmlProps);
    } else if (typeof component === 'string') {
      return React.createElement(component, htmlProps);
    } else {
      const Component = component;

      return (
        <Component
          ref={ref}
          {...props}
          className={`aqa_box${className ? ` ${className}` : ''}`}
        />
      );
    }
  }),
)<StyledBoxProps>(
  ({
    gap,
    transform,
    m,
    mt,
    mr,
    mb,
    ml,
    mx,
    my,
    p,
    pt,
    pr,
    pb,
    pl,
    px,
    py,
    margin,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    marginX,
    marginY,
    padding,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    paddingX,
    paddingY,
    bgcolor,
    color,
    borderColor,
    radius,
    cursor,
    ...props
  }: StyledBoxProps) => {
    const theme = useTheme();

    const styles = {
      ...borders({
        ...props,
        borderRadius: getRadiusString(radius, theme.tokens.radius),
        borderColor: undefined,
      }),
      ...display(props),
      ...flexbox(props),
      ...grid(props),
      ...positions(props),
      ...shadows(props),
      ...sizing(props),
      ...typography(props),
      gap:
        gap &&
        theme.tokens.spacing[
          Math.abs(parseInt(gap, 10)).toString() as keyof Spacing
        ],
      transform,
      cursor,
      ...(bgcolor && {
        backgroundColor: get(theme.tokens.colors, bgcolor),
      }),
      ...(color && {
        color: get(theme.tokens.colors, color),
      }),
      ...(borderColor && {
        borderColor: get(theme.tokens.colors, borderColor),
      }),

      createMediaQuery(
        breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
        propertyName: string,
        value: SpacingValues | undefined,
      ) {
        if (typeof value === 'undefined' || value === null) return;

        this[theme.breakpoints.up(breakpoint)] = {
          ...this[theme.breakpoints.up(breakpoint)],
          [propertyName]:
            value === 'auto' || value === 'unset'
              ? value
              : (value.startsWith('-') ? -1 : 1) *
                theme.tokens.spacing[
                  Math.abs(parseInt(value, 10)).toString() as keyof Spacing
                ],
        };
      },
      createSpacingStyles(
        propertyName: string,
        value:
          | SpacingValues
          | {
              xs?: SpacingValues;
              sm?: SpacingValues;
              md?: SpacingValues;
              lg?: SpacingValues;
              xl?: SpacingValues;
            },
      ) {
        if (typeof value === 'undefined' || value === null) return;

        if (typeof value === 'string') {
          this[propertyName] =
            value === 'auto' || value === 'unset'
              ? value
              : (value.startsWith('-') ? -1 : 1) *
                theme.tokens.spacing[
                  Math.abs(parseInt(value, 10)).toString() as keyof Spacing
                ];
        } else {
          if (!Object.keys(value).length || Array.isArray(value)) return;

          const { xs, sm, md, lg, xl } = value;
          this.createMediaQuery('xs', propertyName, xs);
          this.createMediaQuery('sm', propertyName, sm);
          this.createMediaQuery('md', propertyName, md);
          this.createMediaQuery('lg', propertyName, lg);
          this.createMediaQuery('xl', propertyName, xl);
        }
      },
    };

    styles.createSpacingStyles('margin', margin || m);
    styles.createSpacingStyles('marginTop', marginTop || mt);
    styles.createSpacingStyles('marginRight', marginRight || mr);
    styles.createSpacingStyles('marginBottom', marginBottom || mb);
    styles.createSpacingStyles('marginLeft', marginLeft || ml);
    styles.createSpacingStyles('marginLeft', marginX || mx);
    styles.createSpacingStyles('marginRight', marginX || mx);
    styles.createSpacingStyles('marginTop', marginY || my);
    styles.createSpacingStyles('marginBottom', marginY || my);
    styles.createSpacingStyles('padding', padding || p);
    styles.createSpacingStyles('paddingTop', paddingTop || pt);
    styles.createSpacingStyles('paddingRight', paddingRight || pr);
    styles.createSpacingStyles('paddingBottom', paddingBottom || pb);
    styles.createSpacingStyles('paddingLeft', paddingLeft || pl);
    styles.createSpacingStyles('paddingLeft', paddingX || px);
    styles.createSpacingStyles('paddingRight', paddingX || px);
    styles.createSpacingStyles('paddingTop', paddingY || py);
    styles.createSpacingStyles('paddingBottom', paddingY || py);

    return styles;
  },
);
