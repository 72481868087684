import { injectable, inject } from 'inversify';

import { History } from '@vk-hr-tek/core/history';

import { CandidatesRouter } from '../../candidates/types';

@injectable()
export class CandidatesRouterService implements CandidatesRouter {
  constructor(@inject(History) private history: History) {}

  goToCreate(values: { companyId: string; profileType: string }) {
    this.history.push({
      pathname: '/candidates/create',
      search: `?${new URLSearchParams(values).toString()}`,
    });
  }

  goToDetail(id: string) {
    this.history.push(`/candidates/${id}`);
  }

  goToList(query?: string) {
    if (!query || query === '?') {
      this.history.push('/candidates');
      return;
    }

    this.history.push({
      pathname: '/candidates',
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }
}
