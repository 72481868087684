import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const ScheduleSuccessIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    htmlColor="#ffffff"
    viewBox="0 0 16 16"
    fontSize="inherit"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8 4.75L8.50521 4.19569C8.21836 3.93425 7.77943 3.93484 7.49329 4.19706L8 4.75ZM10.2515 7.80688C9.80158 7.40098 9.24486 6.896 8.75 6.44638V11.25C8.75 11.6642 8.41421 12 8 12C7.58579 12 7.25 11.6642 7.25 11.25V6.4506C6.75728 6.89928 6.20249 7.4028 5.75103 7.80809C5.4428 8.0848 4.96862 8.05926 4.69191 7.75103C4.41519 7.4428 4.44074 6.96862 4.74897 6.69191C5.28535 6.21037 5.97107 5.58699 6.52365 5.08312C6.79973 4.83138 7.0422 4.6098 7.21564 4.45118L7.41956 4.26459L7.47436 4.2144L7.48851 4.20143L7.4921 4.19815L7.493 4.19733L7.49322 4.19712L7.49327 4.19707L8 4.75L8.50523 4.19571L8.50529 4.19576L8.50551 4.19596L8.50642 4.19679L8.51004 4.20009L8.5243 4.21309L8.57945 4.26333L8.78448 4.45006C8.95879 4.60878 9.20238 4.83048 9.47945 5.08238C10.0338 5.58637 10.7214 6.21056 11.2563 6.69312C11.5638 6.97057 11.5882 7.44482 11.3108 7.75237C11.0333 8.05993 10.5591 8.08434 10.2515 7.80688Z"
      fill="#3F8904"
    />
  </SvgIcon>
);
