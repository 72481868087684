/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';

import { ClassNameMap } from '@material-ui/styles';

import { FilePreview } from '@vk-hr-tek/ui/FilePreview';
import { useInject } from '@vk-hr-tek/core/ioc';

import { useRole } from '@app/hooks';

import { LayoutService } from '../../services';

import LogoEmptyIcon from './LogoEmptyIcon/LogoEmptyIcon';
import { useStyles } from './Logo.styles';

type LogoIconVariants = '24' | '32' | '72' | '80';

interface LogoProps {
  variant?: LogoIconVariants;
}

const getSizeClass = (variant: LogoIconVariants, classes: ClassNameMap) => {
  switch (variant) {
    case '24':
      return classes.icon24;
    case '72':
      return classes.icon72;
    case '80':
      return classes.icon80;
    default:
      return classes.icon32;
  }
};

export const Logo = ({ variant = '32' }: LogoProps) => {
  const classes = useStyles();
  const sizeClass = getSizeClass(variant, classes);
  const [role] = useRole();

  const [error, setError] = useState<Error>();
  const layout = useInject(LayoutService);

  const loadDocument = useCallback(async () => {
    const res = await layout.getLogo();

    return res.file;
  }, [role]);

  useEffect(() => {
    setError(undefined);
  }, [role]);

  if (error) {
    return <LogoEmptyIcon variant={variant} />;
  }

  return (
    <div className={sizeClass}>
      <FilePreview onLoad={loadDocument} hideLoader onError={setError} />
    </div>
  );
};
