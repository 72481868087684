import React, { ReactNode } from 'react';

import useStyles from './EllipsisText.styles';

interface EllipsisTextProps {
  rows?: number;
  children: ReactNode;
  wordBreak?: 'normal' | 'break-all' | 'keep-all' | 'break-word';
}

export const EllipsisText = ({
  rows = 2,
  wordBreak = 'normal',
  children,
}: EllipsisTextProps) => {
  const classes = useStyles({ rows, wordBreak });

  return (
    <span className={`${classes.ellipsis} aqa_ellipsis_text`}>{children}</span>
  );
};
