import React, { useMemo } from 'react';

import classNames from 'classnames';
import {
  Dialog as MUIDialog,
  DialogProps as MUIDialogProps,
} from '@material-ui/core';

import { useStyles } from './Dialog.styles';

export const Dialog = ({
  fullScreen = false,
  children,
  classes,
  open,
  ...props
}: MUIDialogProps) => {
  const styles = useStyles();

  const modalClasses = useMemo(
    () => ({
      ...classes,
      paper: classNames(styles.dialogPaper, classes?.paper),
    }),
    [classes, styles],
  );

  if (open) {
    return (
      <MUIDialog
        classes={modalClasses}
        fullScreen={fullScreen}
        className={classNames('aqa_dialog', props.className)}
        open={open}
        {...props}
      >
        {children}
      </MUIDialog>
    );
  }

  return null;
};
