import { injectable } from 'inversify';

import { FormatService } from '@vk-hr-tek/core/format';
import { BoxColors } from '@vk-hr-tek/ui/Box';

import { EventListItem as EventListEntity } from '@app/gen/events';

import { EventsMapper } from './events.mapper';

@injectable()
export class EventsListMapper {
  constructor(private format: FormatService, private transform: EventsMapper) {}

  processListEntity(event: EventListEntity) {
    const stage = this.transform.processStage(event);
    const daysOnNode = event?.days_on_node;
    const activeStages = this.transform.processActiveStages(event);

    return {
      id: event.id,
      stage: {
        ...stage,
        count: event.active_nodes.length,
        ...(daysOnNode
          ? {
              daysOnNode,
            }
          : {}),
        ...(activeStages ? { activeStages } : {}),
      },
      side: {
        name: event.active_nodes
          .map(({ responsible }) => responsible)
          .filter((responsible) => responsible)
          .join(', '),
        count: event.active_nodes.length,
        ...(activeStages ? { activeStages } : {}),
      },
      eventType: {
        name: event.event_type.name,
        vacation: this.transform.processVacation(event),
        businessTrip: this.transform.processBusinessTrip(event),
      },
      documents: {
        items: event.documents
          .map(({ document_type_name }) => document_type_name)
          .join(', '),
        count: event.documents.length,
      },
      documentsToPrint: event.documents.map(({ id }) => id),
      company: event.company.name,
      companyTsp: event.company.tsp_url || '',
      createdAt: this.format.toDate(event.created_at),
      employee: this.transform.processEmployee(event),
      deadline: event.deadline &&
        !(stage?.name === 'Отменено' || stage?.name === 'Завершено') && {
          date: this.transform.processDeadline(event.deadline),
          color: 'text.light.primary' as BoxColors,
        },
      canSign: !!(
        event.permissions &&
        event.permissions.company_sign &&
        event.permissions.company_sign.length
      ),
      nodesToSign: event.permissions && event.permissions.company_sign,
      vacation: this.transform.processVacation(event),
    };
  }

  findFormRoleFields(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formValuesObj: Record<string, any>,
    getFullFieldName?: boolean,
  ) {
    return Object.keys(formValuesObj)
      .map((key) => {
        const regexpResultArray = key.match(/^roles_([0-9a-zA-Z-]+)$/);
        return regexpResultArray
          ? getFullFieldName
            ? regexpResultArray[0]
            : regexpResultArray[1]
          : null;
      })
      .filter((roleId) => roleId);
  }

  gatherAssignedRoles(formValuesObj: Record<string, any>) {
    return this.findFormRoleFields(formValuesObj).reduce(
      (acc: { role_id: string; group_id: string }[], roleId) => {
        return !roleId
          ? acc
          : [
              ...acc,
              { role_id: roleId, group_id: formValuesObj[`roles_${roleId}`] },
            ];
      },
      [],
    );
  }

  gatherAssignedRolesForBatch(formValuesObj: Record<string, any>) {
    return this.findFormRoleFields(formValuesObj).reduce(
      (acc: string[], roleId) => {
        return !roleId
          ? acc
          : [...acc, `${roleId}:${formValuesObj[`roles_${roleId}`]}`];
      },
      [],
    );
  }
}
