import { Tokens } from '../types';

declare module '@material-ui/core/styles/createPalette' {
  interface TypeText {
    primary: string;
    secondary: string;
    disabled: string;
    hint: string;
    tertiary: string;
  }

  interface TypeBackground {
    default: string;
    paper: string;
    primary: string;
    dark: string;
    lightPrimary: string;
    lightSecondary: string;
    darkPrimary: string;
    darkSecondary: string;
    greyscalePrimary: string;
    greyscaleSecondary: string;
    greyscaleTertiary: string;
    gradientPurple: string;
  }

  interface PaletteOptions {
    review: {
      main: string;
      light: string;
      stroke: string;
    };
    blue: {
      main: string;
      light: string;
      stroke: string;
    };
    stroke: {
      primary: string;
      secondary: string;
      tertiary: string;
    };
  }

  interface Palette {
    review: {
      main: string;
      light: string;
      stroke: string;
    };
    blue: {
      main: string;
      light: string;
      stroke: string;
    };
    stroke: {
      primary: string;
      secondary: string;
      tertiary: string;
    };
  }

  interface SimplePaletteColorOptions {
    light?: string;
    main: string;
    dark?: string;
    contrastText?: string;
    stroke?: string;
    hover?: string;
    black?: string;
    quaternary?: string;
  }

  interface PaletteColor {
    light: string;
    main: string;
    dark: string;
    contrastText: string;
    stroke: string;
    hover: string;
    black: string;
    quaternary: string;
  }
}

declare module '@material-ui/core/styles/createTheme' {
  export interface ThemeOptions {
    tokens: Tokens;
    px: (px: number) => number;
  }
  export interface Theme {
    tokens: Tokens;
    px: (px: number) => number;
  }
}

export const createPalette = (tokens: Tokens) => {
  return {
    primary: {
      main: tokens.colors.original.brand.primary,
      dark: tokens.colors.original.brand.tertirary,
      light: tokens.colors.original.brand.secondary,
      contrastText: tokens.colors.text.dark.primary,
      quaternary: tokens.colors.original.brand.quaternary,
    },
    secondary: {
      main: tokens.colors.accent.text.error,
      contrastText: tokens.colors.text.dark.primary,
    },
    error: {
      main: tokens.colors.accent.text.error,
      light: tokens.colors.accent.bg.error,
      stroke: tokens.colors.accent.stroke.error,
      hover: '#ffbcbc', // TODO - fix нет в токенах
      dark: '#cd2c2c', // TODO - fix нет в токенах
    },
    success: {
      main: tokens.colors.accent.text.success,
      light: tokens.colors.accent.bg.success,
      stroke: tokens.colors.accent.stroke.success,
    },
    review: {
      main: tokens.colors.accent.text.review,
      light: tokens.colors.accent.bg.review,
      stroke: tokens.colors.accent.stroke.review,
    },
    warning: {
      main: tokens.colors.accent.text.warning,
      light: tokens.colors.accent.bg.warning,
      stroke: tokens.colors.accent.stroke.warning,
    },
    blue: {
      main: tokens.colors.accent.text.blue,
      light: tokens.colors.accent.bg.blue,
      stroke: tokens.colors.accent.stroke.blue,
    },
    background: {
      paper: tokens.colors.bg.light.primary,
      default: tokens.colors.bg.light.secondary,
      primary: tokens.colors.original.brand.secondary,
      lightPrimary: tokens.colors.bg.light.primary,
      lightSecondary: tokens.colors.bg.light.secondary,
      darkPrimary: tokens.colors.bg.dark.primary,
      darkSecondary: tokens.colors.bg.dark.secondary,
      greyscalePrimary: tokens.colors.bg.greayscale.primary,
      greyscaleSecondary: tokens.colors.bg.greayscale.secondary,
      greyscaleTertiary: tokens.colors.bg.greayscale.tertirary,
      gradientPurple: '#8349ff', // TODO - fix нет в токенах
    },
    text: {
      primary: tokens.colors.text.light.primary,
      secondary: tokens.colors.text.light.secondary,
      tertiary: tokens.colors.text.light.tertirary,
      disabled: tokens.colors.text.light.tertirary,
    },
    stroke: {
      primary: tokens.colors.stroke.primary,
      secondary: tokens.colors.stroke.secondary,
      tertiary: tokens.colors.stroke.tertirary,
    },
  } as const;
};
