import { inject, injectable } from 'inversify';

import { History } from '@vk-hr-tek/core/history';

import { UserRouter } from '../../user/types';

@injectable()
export class UserRouterService implements UserRouter {
  constructor(@inject(History) private history: History) {}

  goBack(prevState: { pathname: string; search: string }) {
    this.history.push(prevState);
  }

  redirectToHome() {
    this.history.push('/');
  }

  goToCreateCertificate() {
    this.history.push('/create-certificate');
  }

  goToProfile(sectionId?: string) {
    if (sectionId) {
      this.history.push(`/profile#${sectionId}`);
    } else {
      this.history.push('/profile');
    }
  }

  goToProfileAttorney({ id, companyId }: { id: string; companyId: string }) {
    this.history.push(`/profile/${companyId}/attorney/${id}`);
  }

  goToProfileAttorneySettings(companyId: string) {
    this.history.push(`/profile/${companyId}/attorney`);
  }

  goToProfileSubstitutes({
    employeeId,
    companyId,
  }: {
    employeeId: string;
    companyId: string;
  }) {
    this.history.push(
      `/profile/substitutes?employeeId=${employeeId}&companyId=${companyId}`,
    );
  }

  goToProfileApprovalSettings({
    employeeId,
    companyId,
    unitId,
  }: {
    employeeId: string;
    companyId: string;
    unitId: string;
  }) {
    const params = [
      `employeeId=${employeeId}`,
      `companyId=${companyId}`,
      `unitId=${unitId}`,
    ].join('&');

    this.history.push(`/profile/approval-settings?${params}`);
  }
}
