import React from 'react';

import { useStyles } from './Content.styles';

interface ContentProps {
  children: React.ReactNode | React.ReactNode[];
}

export const Content = ({ children }: ContentProps) => {
  const classes = useStyles();

  return <div className={`${classes.content} aqa_content`}>{children}</div>;
};
