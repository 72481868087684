import React from 'react';

import MuiToggleButtonGroup, {
  ToggleButtonGroupProps as MuiToggleButtonGroupProps,
} from '@material-ui/lab/ToggleButtonGroup';
import classNames from 'classnames';

import { useStyles } from './ToggleButtonGroup.styles';

export const ToggleButtonGroup = ({
  children,
  className,
  ...rest
}: MuiToggleButtonGroupProps) => {
  const classes = useStyles();

  return (
    <MuiToggleButtonGroup
      {...rest}
      className={classNames(
        classes.toggleButtonGroup,
        className,
        'aqa_toggle_button_group',
      )}
    >
      {children}
    </MuiToggleButtonGroup>
  );
};
