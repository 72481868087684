import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ tokens, px }) => ({
  fileInput: {
    marginBottom: tokens.spacing['16'],
    alignItems: 'end',

    '& .dropzone:focus': {
      outline: 'none',

      '& $dropzone:not($error), & $previewDropzone:not($error)': {
        borderColor: tokens.colors.original.brand.primary,
      },
    },
  },

  dropzone: {
    backgroundColor: tokens.colors.bg.light.primary,
    border: `${tokens.border.m}px dashed ${tokens.colors.stroke.primary}`,
    padding: tokens.spacing['16'] - tokens.border.m,
    borderRadius: tokens.radius.l,
    color: tokens.colors.text.light.secondary,
    cursor: 'pointer',

    '&:not($disabled):not($error):hover': {
      borderColor: tokens.colors.stroke.secondary,
    },
  },

  inputText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  previewDropzone: {
    backgroundColor: tokens.colors.bg.light.primary,
    border: `${tokens.border.m}px dashed ${tokens.colors.stroke.primary}`,
    borderRadius: tokens.radius.l,
    cursor: 'pointer',

    '&:not($disabled):not($error):hover': {
      borderColor: tokens.colors.stroke.secondary,
    },
  },

  filePreview: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: tokens.colors.bg.light.primary,
    border: `${tokens.border.s}px solid ${tokens.colors.stroke.primary}`,
    borderRadius: tokens.radius.l,
    overflow: 'hidden',
  },

  previewButtons: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: px(48),
    padding: tokens.spacing['16'],
    width: '100%',
    backgroundColor: tokens.colors.bg.light.primary,
    borderTop: `${tokens.border.s}px solid ${tokens.colors.stroke.primary}`,

    // TODO should be removed after merge with the Link component
    '& .MuiTypography-root': {
      display: 'flex',
      alignItems: 'center',
    },
  },

  icon: {
    transform: 'rotate(45deg)',
  },

  rotateIcon: {
    width: px(18),
    height: px(18),
  },

  disabled: {
    backgroundColor: tokens.colors.bg.greayscale.secondary,
    borderStyle: 'solid',
    cursor: 'default',
  },

  error: {
    padding: tokens.spacing['16'] - tokens.border.s,
    backgroundColor: tokens.colors.accent.bg.error,
    border: `solid ${tokens.colors.accent.text.error} ${tokens.border.s}px`,
  },
}));

export default useStyles;
