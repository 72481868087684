import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';

@injectable()
export class LayoutService {
  constructor(
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async getLogo() {
    const result = this.http.getFile(`/company/logo`, {
      withSide: true,
    });

    return result;
  }
}
