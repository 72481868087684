import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  icon_size_extraSmall: {
    fontSize: 14,
    verticalAlign: 'text-top',
  },
  icon_size_small: {
    fontSize: 16,
  },
  icon_size_normal: {
    fontSize: 24,
  },
  icon_color_primary: {
    color: theme.tokens.colors.original.brand.primary,
  },
  icon_color_disabled: {
    color: theme.tokens.colors.stroke.secondary,
  },
  icon_color_secondary: {
    color: theme.tokens.colors.text.light.tertirary,
  },
  icon_color_success: {
    color: theme.tokens.colors.accent.text.success,
  },
  icon_color_warning: {
    color: theme.tokens.colors.accent.text.warning,
  },
  icon_color_error: {
    color: theme.tokens.colors.accent.text.error,
  },
  icon_color_textPrimary: {
    color: theme.tokens.colors.text.light.primary,
  },

  icon_color_action: {
    color: theme.palette.common.black,
  },
}));
