import { MetaTitle, NavigationItem } from './router.types';

export const navigationItems: NavigationItem[] = [
  {
    title: 'Заявки',
    href: '/',
    resource: 'Events',
    isActive: (path: string) =>
      path === '/' ||
      (!path.startsWith('/events/paper') && path.startsWith('/events')),
  },
  {
    title: 'Кандидаты',
    href: '/candidates',
    resource: 'Candidates',
  },
  {
    title: 'Сотрудники',
    href: '/employees',
    resource: 'Employees',
  },
  {
    title: 'В бумагу',
    href: '/events/paper',
    resource: 'PaperEvents',
  },
  {
    title: 'Корп. документы',
    href: '/policy',
    resource: 'Policy',
  },
  {
    title: 'Доходы',
    href: '/payslips',
    resource: 'Payslips',
  },
  {
    title: 'Компания',
    href: '/organization',
    resource: 'Organization',
  },
  {
    title: 'Настройки',
    href: '/settings',
    resource: 'Settings',
  },
  {
    title: 'Графики отпусков',
    href: '/vacations',
    resource: 'Vacations',
  },
];

export const metaTitles: MetaTitle[] = [
  ...navigationItems.map(({ title, href }) => ({ title, href })),
  { title: 'Вход в личный кабинет', href: '/auth' },
  { title: 'Заявки', href: '/events' },
  { title: 'Профиль', href: '/profile' },
  { title: 'Профиль', href: '/create-certificate' },
];
