import { IsString, IsNotEmpty, IsIn, Length, Matches } from 'class-validator';

import { IsSnils } from '@vk-hr-tek/core/validation';

const targetValidationOptions = {
  groups: ['query'],
  message: 'Неверная операция',
};

export class SendInviteDto {
  @IsSnils({
    groups: ['form'],
    message: 'Неверный СНИЛС',
  })
  @IsString({
    groups: ['form'],
    message: 'Вы забыли ввести СНИЛС',
  })
  @IsNotEmpty({
    groups: ['form'],
    message: 'Вы забыли ввести СНИЛС',
  })
  snils: string;

  @Length(8, 128, {
    message: 'Должен содержать не менее 8 символов',
    groups: ['form'],
  })
  @Matches(/^[A-Za-z0-9!#{}@()%^\[\]~$\-_]+$/, {
    message: 'Должен содержать только буквы, цифры или символы !#{}@$()%^[]~-_',
    groups: ['form'],
  })
  @Matches(/[A-Z]/, {
    message: 'Должен содержать не менее 1 заглавной буквы',
    groups: ['form'],
  })
  @Matches(/[a-z]/, {
    message: 'Должен содержать не менее 1 строчной буквы',
    groups: ['form'],
  })
  @Matches(/[0-9\^\[\]~!#{}\/@\$()`%]/, {
    message: 'Должен содержать не менее 1 цифры либо 1 символа',
    groups: ['form'],
  })
  @IsString({
    message: 'Вы забыли ввести пароль',
    groups: ['form'],
  })
  @IsNotEmpty({
    message: 'Вы забыли ввести пароль',
    groups: ['form'],
  })
  password: string;

  @IsIn(['registration', 'phone_change'], targetValidationOptions)
  @IsString(targetValidationOptions)
  @IsNotEmpty(targetValidationOptions)
  target: 'registration' | 'phone_change' = 'registration';
}
