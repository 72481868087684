import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { EllipsisText } from '@vk-hr-tek/ui/EllipsisText';
import { RedirectCard } from '@vk-hr-tek/ui/RedirectCard';
import { Avatar } from '@vk-hr-tek/ui/Avatar';
import { Typography } from '@vk-hr-tek/ui/Typography';

interface UserCardProps {
  onClick: () => void;
  userFullName: string;
}

export const UserCard = ({ onClick, userFullName }: UserCardProps) => {
  const stringAvatar = (name: string) =>
    `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;

  return (
    <Box
      width={268}
      mt={{ md: '16', xs: '0' }}
      mb={{ md: '8', xs: '0' }}
      mx="16"
    >
      <RedirectCard onClick={onClick}>
        <Avatar children={stringAvatar(userFullName)} />

        <EllipsisText>
          <Typography variant="body3">{userFullName}</Typography>
        </EllipsisText>
      </RedirectCard>
    </Box>
  );
};
