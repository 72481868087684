import React, { ReactNode } from 'react';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { Box } from '@vk-hr-tek/ui/Box';

export const Callout = ({ children }: { children: ReactNode }) => (
  <Box
    p={{ xs: '16', md: '24' }}
    radius="l"
    border="1px solid"
    borderColor="bg.greayscale.primary"
    bgcolor="bg.greayscale.primary"
  >
    <Box mb="8">
      <InfoOutlinedIcon fontSize="medium" color="primary" />
    </Box>
    {children}
  </Box>
);
