import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ px }) => ({
  icon24: {
    '& > div': {
      height: px(32),
    },
    height: px(24),
    '& img': {
      height: px(24),
      width: 'auto',
    },
  },
  icon32: {
    '& > div': {
      height: px(32),
    },
    height: px(32),
    '& img': {
      height: px(32),
      width: 'auto',
    },
  },
  icon72: {
    '& > div': {
      height: px(32),
    },
    height: px(72),
    '& img': {
      height: px(72),
      width: 'auto',
    },
  },
  icon80: {
    '& > div': {
      height: px(32),
    },
    '& img': {
      height: px(80),
      width: 'auto',
    },
    height: px(80),
  },
}));
