import React from 'react';

import { Router } from 'react-router-dom';

import { history } from '@vk-hr-tek/core/history';

import { init } from '@app/init';
import { AbilityContext } from '@app/ability/Can';
import createAbilityFor from '@app/ability/ability';
import { useSelector } from '@app/hooks';
import { metaTitles, navigationItems } from '@app/router';
import { InjectReducerContextProvider } from '@app/context';

import {
  GeneralLayout,
  Navigation,
  CandidateLayout,
  ThemeProvider,
} from './layout';
import { useRole } from './app/hooks';
import { InjectReducer } from './app/types';
import { useApplication } from './auth/hooks/useApplication';
import { usePermissions, selectUserRepresentative } from './user';
import CandidateApp from './CanidateApp';
import MainApp from './MainApp';

init({ history });

interface AppProps {
  injectReducer: InjectReducer;
}

function App({ injectReducer }: AppProps) {
  const [role] = useRole();
  const appType = useApplication();
  const userRepresentative = useSelector(selectUserRepresentative);
  const {
    view_events: canSeeEvents,
    view_event_to_paper: canSeeEventsToPaper,
    view_employees: canSeeEmployees,
    view_policies: canSeePolicies,
    view_company: canSeeCompany,
    view_company_profiles: canSeeCompanyProfiles,
    view_settings: canSeeSettings,
    view_attorneys: canSeeAttorneys,
    view_candidates: canSeeCandidates,
    view_vacations: canSeeVacations,
    view_vacations_settings: canSeeVacationsSettings,
    view_payslips: canSeePayslips,
    view_smev_errors: canSeeSmevErrors,
  } = usePermissions();

  const ability = createAbilityFor({
    role,
    userRepresentative,
    canSeeEvents,
    canSeeEventsToPaper,
    canSeeEmployees,
    canSeePolicies,
    canSeeCompany,
    canSeeCompanyProfiles,
    canSeeSettings,
    canSeeCandidates,
    canSeeAttorneys,
    canSeeVacations,
    canSeeVacationsSettings,
    canSeePayslips,
    canSeeSmevErrors,
  });

  return (
    <AbilityContext.Provider value={ability}>
      <InjectReducerContextProvider injectReducer={injectReducer}>
        <ThemeProvider>
          <Router history={history}>
            {appType === 'candidateApp' ? (
              <CandidateLayout>
                <CandidateApp />
              </CandidateLayout>
            ) : (
              <GeneralLayout
                navigation={
                  <>
                    <Navigation items={navigationItems} />
                  </>
                }
                metaTitles={metaTitles}
              >
                <MainApp />
              </GeneralLayout>
            )}
          </Router>
        </ThemeProvider>
      </InjectReducerContextProvider>
    </AbilityContext.Provider>
  );
}

export default App;
