import React from 'react';

import { useLocation } from 'react-router-dom';

import { Link } from '@vk-hr-tek/ui/Link';

interface BaseBackLinkProps {
  title: string;
  compareHistoryState?: (prevStateObj: {
    pathname: string;
    search: string;
  }) => boolean;
}

interface BackLinkOnClickProps extends BaseBackLinkProps {
  onClick: () => void;
}

interface BackLinkRouterProps extends BaseBackLinkProps {
  router: {
    goToList(query?: string): void;
  };
  queryParams?: Record<string, any>;
}

type BackLinkProps = BackLinkOnClickProps | BackLinkRouterProps;

const isOnClickProps = (props: BackLinkProps): props is BackLinkOnClickProps =>
  Object.prototype.hasOwnProperty.call(props, 'onClick');

const isRouterProps = (props: BackLinkProps): props is BackLinkRouterProps =>
  Object.prototype.hasOwnProperty.call(props, 'router');

export const BackLink = (props: BackLinkProps) => {
  const location =
    useLocation<{ prev: { pathname: string; search: string }[] }>();

  const { compareHistoryState } = props;

  const handleClick = () => {
    if (isOnClickProps(props)) {
      props.onClick();
      return;
    }

    if (isRouterProps(props)) {
      if (location.state?.prev) {
        if (compareHistoryState) {
          const idx = location.state.prev.findIndex(compareHistoryState);

          props.router.goToList(
            idx !== -1 ? location.state.prev?.[idx].search : undefined,
          );
        } else {
          props.router.goToList(location.state.prev?.[0]?.search);
        }
      } else {
        const query =
          props.queryParams &&
          `?${new URLSearchParams(props.queryParams).toString()}`;

        props.router.goToList(query);
      }
    }
  };

  return (
    <Link size="small" onClick={handleClick} stroke={false}>
      {props.title}
    </Link>
  );
};
