import { injectable, inject } from 'inversify';

import { History } from '@vk-hr-tek/core/history';
import { LocalStorage } from '@vk-hr-tek/core/local-storage';

import { AuthRouterService } from './auth.router.service';

@injectable()
export class AuthConfigService {
  private readonly key = 'authType';

  constructor(
    @inject(History) private history: History,
    private authRouterService: AuthRouterService,
    @inject(LocalStorage) private readonly localStorage: LocalStorage,
  ) {}

  getAuthType():
    | 'general'
    | 'ali'
    | 'oauth2'
    | 'candidate'
    | 'internal'
    | 'internalVpnOff' {
    if (
      window.location.hostname === window.REACT_APP_VKHRTEK_ALI_DOMAIN ||
      this.localStorage.getItem(this.key) === 'ali'
    ) {
      return 'ali';
    }

    if (
      window.location.hostname === window.REACT_APP_VKHRTEK_CANDIDATE_DOMAIN ||
      this.localStorage.getItem(this.key) === 'candidate'
    ) {
      return 'candidate';
    }

    if (
      window.REACT_APP_VKHRTEK_OAUTH2.toString() === 'true' ||
      this.localStorage.getItem(this.key) === 'oauth2'
    ) {
      return 'oauth2';
    }

    if (
      !window.REACT_APP_VKHRTEK_INTERNAL_AUTH ||
      this.localStorage.getItem(this.key) === 'general' ||
      this.localStorage.getItem(this.key) === 'internalVpnOff'
    ) {
      if (this.authRouterService.isVKUser()) {
        return 'internalVpnOff';
      }
      return 'general';
    }

    return 'internal';
  }

  getApiUrl() {
    return window.REACT_APP_VKHRTEK_API_URL;
  }
}
