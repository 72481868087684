import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette, spacing, ...theme }) => ({
  root: {
    width: theme.px(280),
    paddingLeft: theme.tokens.spacing['16'],
    paddingBottom: theme.tokens.spacing['8'],
    paddingRight: theme.tokens.spacing['16'],
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.tokens.typography.variant.body3.fontSize,

    '& .react-calendar__month-view__weekdays': {
      display: 'flex',
      alignItems: 'center',
      gap: theme.tokens.spacing['4'],

      '& .react-calendar__month-view__weekdays__weekday': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: theme.px(32),
        height: theme.px(32),
        fontSize: theme.tokens.typography.variant.caption.fontSize,
        color: theme.tokens.colors.text.light.tertirary,
        textTransform: 'uppercase',

        '&:nth-child(6):disabled, &:last-child:disabled': {
          color: theme.tokens.colors.text.light.tertirary,
        },

        '& > *': {
          textDecoration: 'none',
        },
      },
    },

    '& .react-calendar__month-view__days': {
      gap: theme.tokens.spacing['4'],
    },
  },

  monthName: {
    padding: theme.tokens.spacing['16'],
    paddingBottom: theme.tokens.spacing['12'],
    display: 'flex',
    justifyContent: 'space-between',
  },

  day: {
    position: 'relative',
    maxWidth: theme.px(32),
    height: theme.px(32),
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: theme.tokens.radius.s,
    overflow: 'visible !important',
    fontSize: theme.tokens.typography.variant.body3.fontSize,
    color: theme.tokens.colors.text.light.primary,

    '&:disabled:not($selectedRangeDay)': {
      color: theme.tokens.colors.text.light.tertirary,
    },

    '&:disabled$selectedRangeDay': {
      color: theme.tokens.colors.text.light.primary,
    },

    '&:not($selectedRangeDay):not(:disabled):hover:not($readOnly)': {
      backgroundColor: theme.tokens.colors.original.brand.secondary,
      border: `${theme.tokens.border.s}px solid ${theme.tokens.colors.original.brand.primary}`,
      cursor: 'pointer',
    },

    '&.react-calendar__month-view__days__day--weekend:not(.react-calendar__month-view__days__day--neighboringMonth)':
      {
        '&:not($selectedRangeDay):disabled': {
          color: theme.tokens.colors.text.light.tertirary,
        },
      },

    '&.react-calendar__month-view__days__day--neighboringMonth': {
      color: theme.tokens.colors.text.light.tertirary,
    },
  },

  readOnly: {
    cursor: 'default',
  },

  selectedRangeDay: {
    cursor: 'default',

    '&:before': {
      position: 'absolute',
      top: 0,
      left: -theme.tokens.spacing['2'],
      zIndex: -1,
      height: theme.px(32),
      width: theme.px(36),
      backgroundColor: theme.tokens.colors.original.brand.quaternary,
      content: "''",
    },

    '&$rangeStart$rangeEnd': {
      '&:before': {
        width: theme.px(32),
        left: 0,
        right: 0,
        borderTopLeftRadius: theme.tokens.radius.s,
        borderBottomLeftRadius: theme.tokens.radius.s,
        borderTopRightRadius: theme.tokens.radius.s,
        borderBottomRightRadius: theme.tokens.radius.s,
      },
    },

    '&$selectedRangeDayStart$selectedRangeDayEnd': {
      '&:before': {
        width: theme.px(32),
      },
    },

    '&.react-calendar__month-view__days__day--neighboringMonth': {
      '&:before': {
        opacity: 0.3,
      },
    },
  },

  rangeStart: {
    color: theme.tokens.colors.text.dark.primary,
    backgroundColor: theme.tokens.colors.original.brand.primary,

    '&.react-calendar__month-view__days__day--neighboringMonth': {
      color: theme.tokens.colors.text.dark.primary,
    },

    '&:not($rangeEnd)': {
      '&:before': {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
        height: theme.px(32),
        width: theme.px(34),
        backgroundColor: theme.tokens.colors.original.brand.quaternary,
        borderTopLeftRadius: theme.tokens.radius.s,
        borderBottomLeftRadius: theme.tokens.radius.s,
        content: "''",
      },
    },
  },

  rangeEnd: {
    color: theme.tokens.colors.text.dark.primary,
    backgroundColor: theme.tokens.colors.original.brand.primary,

    '&.react-calendar__month-view__days__day--neighboringMonth': {
      color: theme.tokens.colors.text.dark.primary,
    },

    '&:not($rangeStart)': {
      '&:before': {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: -1,
        height: theme.px(32),
        width: theme.px(34),
        backgroundColor: theme.tokens.colors.original.brand.quaternary,
        borderTopRightRadius: theme.tokens.radius.s,
        borderBottomRightRadius: theme.tokens.radius.s,
        content: "''",
      },
    },
  },

  selectedRangeDayStart: {
    '&:not($rangeStart)': {
      '&:before': {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
        height: theme.px(32),
        width: theme.px(34),
        backgroundColor: theme.tokens.colors.original.brand.quaternary,
        borderTopLeftRadius: theme.tokens.radius.s,
        borderBottomLeftRadius: theme.tokens.radius.s,
        content: "''",
      },
    },
  },

  selectedRangeDayEnd: {
    '&:not($rangeEnd)': {
      '&:before': {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: -1,
        height: theme.px(32),
        width: theme.px(34),
        borderTopRightRadius: theme.tokens.radius.s,
        borderBottomRightRadius: theme.tokens.radius.s,
        backgroundColor: theme.tokens.colors.original.brand.quaternary,
        content: "''",
      },
    },
  },

  holiday: {
    color: theme.tokens.colors.accent.text.error,
    '&:disabled': {
      color: theme.tokens.colors.text.light.tertirary,
    },
  },

  deletedRangeDay: {
    cursor: 'default',

    '&:not($selectedRangeDay)': {
      '&::before': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: -2,
        zIndex: -1,
        height: 32,
        width: 36,
        background: theme.tokens.colors.bg.greayscale.tertirary,
      },
    },

    '&$selectedRangeDay': {
      '&::before': {
        backgroundColor: '#c8d7fa', // TODO fix color
      },
    },

    '&.react-calendar__month-view__days__day--neighboringMonth': {
      '&::before': {
        opacity: 0.3,
      },
    },
  },

  deletedRangeDayStart: {
    '&:not($selectedRangeDay)': {
      '&::before': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
        height: 32,
        width: 34,
        backgroundColor: theme.tokens.colors.bg.greayscale.tertirary,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
      },
    },
  },

  deletedRangeDayEnd: {
    '&:not($selectedRangeDay)': {
      '&::before': {
        content: "''",
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: -1,
        height: 32,
        width: 34,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        backgroundColor: theme.tokens.colors.bg.greayscale.tertirary,
      },
    },
  },
}));

export default useStyles;
