import { inject, injectable } from 'inversify';

import { History } from '@vk-hr-tek/core/history';

import { SettingsAttorneysRouter } from '../../settings/types';

@injectable()
export class SettingsAttorneysRouterService implements SettingsAttorneysRouter {
  constructor(@inject(History) private history: History) {}

  goToDetail(id: string) {
    this.history.push(`/settings/attorneys/${id}`);
  }

  goToList(query?: string) {
    if (!query || query === '?') {
      this.history.push('/settings/attorneys');
      return;
    }

    this.history.push({
      pathname: '/settings/attorneys',
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }
}
