import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const ScheduleTypeIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    htmlColor="#ffffff"
    viewBox="0 0 16 16"
    fontSize="inherit"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM6.92214 10.901L10.1729 7.65021C10.2297 7.59341 10.2297 7.50131 10.1729 7.44451L8.55547 5.82707C8.49867 5.77027 8.40657 5.77027 8.34977 5.82707L5.09899 9.07783C4.89689 9.27993 4.83523 9.34818 4.77239 9.43092C4.70956 9.51367 4.66204 9.59441 4.62023 9.68953C4.57841 9.78464 4.5487 9.87168 4.47017 10.1465L4.00701 11.7676C3.99766 11.8003 3.99766 11.835 4.00701 11.8677C4.03468 11.9645 4.13559 12.0206 4.2324 11.9929L5.85346 11.5298C6.12828 11.4513 6.21533 11.4215 6.31044 11.3797C6.40555 11.3379 6.4863 11.2904 6.56904 11.2276C6.65179 11.1647 6.72004 11.1031 6.92214 10.901ZM10.2853 4.02932C10.1563 4.06841 10.049 4.12783 9.81915 4.35769L9.32897 4.84787C9.27217 4.90468 9.27217 4.99677 9.32897 5.05358L10.9464 6.67101C11.0032 6.72782 11.0953 6.72782 11.1521 6.67101L11.6423 6.18083C11.8722 5.95097 11.9316 5.84369 11.9707 5.71471C12.0098 5.58573 12.0098 5.45383 11.9707 5.32486C11.9316 5.19588 11.8722 5.0886 11.6423 4.85873L11.1413 4.35769C10.9114 4.12783 10.8041 4.06841 10.6751 4.02932C10.5462 3.99023 10.4143 3.99023 10.2853 4.02932Z"
      fill="#226DFE"
    />
  </SvgIcon>
);
