import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { useInject } from '@vk-hr-tek/core/ioc';
import { Link } from '@vk-hr-tek/ui/Link';
import { Typography } from '@vk-hr-tek/ui/Typography';

import { useLogout } from '../../../auth';
import { AuthConfigService } from '../../../auth/services/common/auth.config.service';
import { Page } from '../../ui/page';

export const CertificateExpired = () => {
  const logout = useLogout();
  const config = useInject(AuthConfigService);

  return (
    <Page title="Закончился срок действия УНЭП" theme="notification">
      <Box mb="20">
        <Typography variant="body3" color="textSecondary">
          Перевыпуск будет сделан автоматически в течение дня
        </Typography>
      </Box>
      {config.getAuthType() === 'ali' && (
        <Box mt="20">
          <Link onClick={logout} size="small" variant="tertiary">
            Выход к QR-коду
          </Link>
        </Box>
      )}
    </Page>
  );
};
