import React, { ReactElement } from 'react';

import {
  Accordion as MUIAccordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
} from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';

import useStyles from './Accordion.styles';

type AccordionPadding = 'small' | 'medium';

export interface AccordionProps {
  children: React.ReactNode | React.ReactNode[];
  title: React.ReactNode | React.ReactNode[];
  defaultExpanded?: boolean;
  onClick?: () => void;
  onChangeIfExpanded?: () => void;
  padding?: AccordionPadding;
  expanded?: boolean;
  iconVerticalMargin?: number;
  control?: ReactElement;
  controlLabel?: string;
}

export const Accordion = ({
  children,
  title,
  defaultExpanded = false,
  onClick,
  onChangeIfExpanded,
  padding = 'medium',
  expanded: expandedProps,
  iconVerticalMargin = 0,
  control,
  controlLabel = '',
}: AccordionProps) => {
  const classes = useStyles({ iconVerticalMargin });

  const accordionSummaryClasses = (() => {
    if (padding === 'small') {
      return {
        root: classes.AccordionSummaryRootSmall,
        content: classes.AccordionSummaryContentSmall,
      };
    }

    return {
      root: classes.AccordionSummaryRootMedium,
      content: classes.AccordionSummaryContentMedium,
    };
  })();

  return (
    <MUIAccordion
      defaultExpanded={defaultExpanded}
      className={`aqa_accordion ${classes.accordion}`}
      expanded={expandedProps}
      onChange={(e, expanded) => {
        if (onClick) {
          onClick();
        }
        if (expanded && onChangeIfExpanded) {
          onChangeIfExpanded();
        }
      }}
    >
      <AccordionSummary
        classes={{
          ...accordionSummaryClasses,
          expandIcon: classes.AccordionSummaryExpandIcon,
        }}
        className="aqa_accorrdion_summary"
        IconButtonProps={{ size: 'small' }}
        expandIcon={<ArrowDropDownIcon color="primary" />}
      >
        {control && (
          <FormControlLabel
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={control}
            label={controlLabel}
          />
        )}
        {title}
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: classes.AccordionDetailsRoot,
        }}
        className="aqa_accorrdion_details"
      >
        {children}
      </AccordionDetails>
    </MUIAccordion>
  );
};
