/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from 'class-transformer';
import { IsArray, IsNotEmpty, ValidateNested } from 'class-validator';

import {
  Filter,
  BaseFilter,
  TextFilter,
  SelectFilter,
  MultipleFilter,
  BoolFilter,
} from './filter.entity';

export class FiltersResponse {
  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => BaseFilter, {
    discriminator: {
      property: 'type',
      subTypes: [
        { value: TextFilter, name: 'text' },
        { value: SelectFilter, name: 'select' },
        { value: MultipleFilter, name: 'multiple' },
        { value: BoolFilter, name: 'bool' },
      ],
    },
    keepDiscriminatorProperty: true,
  })
  filters: Filter[];
}
