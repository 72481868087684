import { injectable, inject } from 'inversify';

import { History } from '@vk-hr-tek/core/history';

import { EmployeesRouter } from '../../employees/types';

@injectable()
export class EmployeesRouterService implements EmployeesRouter {
  constructor(@inject(History) private history: History) {}

  goToList(query?: string) {
    if (!query || query === '?') {
      this.history.push('/employees');
      return;
    }

    this.history.push({
      pathname: '/employees',
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }
}
