import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, breakpoints, tokens, px }) => ({
  radio: {
    display: 'none',
  },

  row: {
    gap: tokens.spacing['8'],
    [breakpoints.up('md')]: {
      gap: tokens.spacing['12'],
    },
  },

  label: {
    marginLeft: 0,
    marginRight: 0,
  },

  chip: {
    borderColor: tokens.colors.stroke.primary,

    '&.MuiChip-outlined:hover': {
      backgroundColor: tokens.colors.bg.greayscale.primary,
    },

    '& .MuiChip-label': {
      display: 'flex',
      padding: 0,
      color: tokens.colors.text.light.primary,
      whiteSpace: 'normal',
    },
  },

  large: {
    height: px(48),
    paddingLeft: tokens.spacing['16'],
    paddingRight: tokens.spacing['16'],
    paddingTop: px(14), // TODO - fix нет в токенах
    paddingBottom: px(14), // TODO - fix нет в токенах
  },

  medium: {
    height: px(40),
    paddingLeft: tokens.spacing['16'],
    paddingRight: tokens.spacing['16'],
    paddingTop: px(10), // TODO - fix нет в токенах
    paddingBottom: px(10), // TODO - fix нет в токенах
  },

  small: {
    height: px(32),
    paddingLeft: tokens.spacing['12'],
    paddingRight: tokens.spacing['12'],
    paddingTop: px(6), // TODO - fix нет в токенах
    paddingBottom: px(6), // TODO - fix нет в токенах
  },

  active: {
    backgroundColor: tokens.colors.original.brand.secondary,
    borderColor: tokens.colors.original.brand.primary,

    '& .MuiChip-label': {
      color: tokens.colors.original.brand.primary,
    },

    '&.MuiChip-outlined': {
      '&:hover': {
        backgroundColor: tokens.colors.original.brand.quaternary,
      },

      '&:focus': {
        backgroundColor: tokens.colors.original.brand.secondary,
      },
    },
  },

  error: {
    backgroundColor: tokens.colors.accent.bg.error,
    borderColor: tokens.colors.accent.stroke.error,

    '&.MuiChip-outlined': {
      '&:hover': {
        borderColor: palette.error.hover,
        backgroundColor: tokens.colors.accent.bg.error,
      },

      '&:focus': {
        backgroundColor: tokens.colors.accent.bg.error,
        borderColor: tokens.colors.accent.stroke.error,
      },
    },

    '& .MuiChip-label': {
      color: tokens.colors.text.light.primary,
    },
  },

  disabled: {
    borderColor: tokens.colors.bg.greayscale.primary,

    '& .MuiChip-label': {
      color: tokens.colors.text.light.tertirary,
    },

    '&.Mui-disabled': {
      opacity: 1,
    },
  },

  activeDisabled: {
    backgroundColor: tokens.colors.bg.greayscale.secondary,
    borderColor: tokens.colors.text.light.tertirary,
  },
}));
