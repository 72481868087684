import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import { useStyles } from './VacationIcon.styles';

export const VacationIcon = (props: SvgIconProps) => {
  const classes = useStyles();

  return (
    <SvgIcon
      {...props}
      viewBox="0 0 16 16"
      fill="none"
      className={classes.icon}
    >
      <path
        d="M6.48939 13.7654L7.3311 10.3109L7.92678 7.98852"
        stroke="#226DFE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.93979 2.22567C10.0007 1.97731 9.84865 1.72662 9.60028 1.66576C9.35191 1.6049 9.10123 1.7569 9.04037 2.00527L9.00069 2.16717C9.00038 2.16842 9.00008 2.16968 8.99978 2.17094C5.971 1.5282 3.01422 2.78658 1.7105 5.44844C1.41056 6.06085 1.80975 6.74873 2.46756 6.92839L7.7743 8.3778L13.081 9.82722C13.7389 10.0069 14.4323 9.61743 14.4853 8.93758C14.7152 5.98865 12.817 3.40653 9.89224 2.41586C9.89514 2.40658 9.89777 2.39715 9.90011 2.38757L9.93979 2.22567ZM2.54215 5.85576C3.46616 3.96915 5.40309 2.87768 7.59596 2.93227C6.51393 3.62511 5.39377 4.81957 5.07772 6.68135L2.71155 6.03508C2.62428 6.01125 2.56951 5.95719 2.54695 5.9149C2.53665 5.89559 2.53533 5.88344 2.53518 5.87947L2.53518 5.87926C2.53509 5.87719 2.53482 5.87072 2.54215 5.85576ZM8.01829 7.48449L5.97631 6.92677C6.32337 4.6459 8.11836 3.52974 9.19149 3.18307C9.9394 4.02709 10.9178 5.90076 10.0573 8.0414L8.01829 7.48449ZM13.325 8.9339L10.9559 8.28683C11.6303 6.52226 11.2724 4.92373 10.6924 3.77697C12.6108 4.84426 13.7255 6.76989 13.5621 8.86559C13.5608 8.88221 13.5572 8.88764 13.5561 8.88937L13.556 8.88954C13.5539 8.89289 13.5465 8.90268 13.5279 8.91408C13.4869 8.93903 13.4123 8.95774 13.325 8.9339Z"
        fill="#226DFE"
      />
      <path
        d="M3.70215 14.0299L6.29923 14.0299H10.7152"
        stroke="#226DFE"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};
