import { inject, injectable } from 'inversify';

import { History } from '@vk-hr-tek/core/history';

import { SettingsCompanyRouter } from '../../settings/types';

@injectable()
export class SettingsCompanyRouterService implements SettingsCompanyRouter {
  constructor(@inject(History) private history: History) {}

  goToCompanySettings() {
    this.history.push('/settings/company');
  }
}
