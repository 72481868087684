import { inject, injectable } from 'inversify';

import { History } from '@vk-hr-tek/core/history';

import { SettingsGroupsRouter } from '../../settings/types';

@injectable()
export class SettingsGroupsRouterService implements SettingsGroupsRouter {
  constructor(@inject(History) private history: History) {}

  goToAddRole(companyId: string) {
    this.history.push(`/settings/groups/${companyId}/add-role`);
  }

  goToList(query?: string) {
    if (!query || query === '?') {
      this.history.push('/settings/groups');
      return;
    }

    this.history.push({
      pathname: '/settings/groups',
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }
}
