import { SUBMIT_ERROR_TEXT } from '@vk-hr-tek/ui/form';

import { ThunkExtra as AppThunkExtra } from '../../app/store';

type AuthError = {
  title: string;
  description: string;
  errorCode?: string;
  traceId?: string | null;
};

export type ThunkExtra = Omit<AppThunkExtra, 'rejectValue'> & {
  rejectValue: AuthError;
};

export interface AuthState {
  status: 'idle' | 'loading' | 'complete' | 'failed';
  notification: {
    title: string;
    description: string;
    traceId?: string | null;
  } | null;
  inviteCode: string | null;
  authCode: string | null;
  passCode: string | null;
  values: {
    phone: string | null;
    password: string | null;
    snils: string | null;
    code: string | null;
  };
  stage: 'snils' | 'init' | 'verification' | 'finish' | 'error';
}

export const initialState: AuthState = {
  status: 'idle',
  notification: null,
  inviteCode: null,
  authCode: null,
  passCode: null,
  stage: 'init',
  values: {
    phone: null,
    password: null,
    snils: null,
    code: null,
  },
};

export const clearState = (state: AuthState) => {
  state.status = 'idle';
  state.notification = null;
  state.stage = 'init';
};

export const resetState = (state: AuthState) => {
  state.status = initialState.status;
  state.notification = initialState.notification;
  state.inviteCode = initialState.inviteCode;
  state.authCode = initialState.authCode;
  state.passCode = initialState.passCode;
  state.stage = initialState.stage;
  state.values = initialState.values;
};

export const startLoading = (state: AuthState) => {
  state.status = 'loading';
  state.notification = null;
};

export const completeLoading = (state: AuthState) => {
  state.status = 'complete';
  state.notification = null;
};

export const setError = (
  state: AuthState,
  {
    payload,
  }: {
    payload?: { title: string; description: string; traceId?: string | null };
  },
) => {
  state.status = 'failed';
  state.notification = {
    description:
      (payload && payload.description) ||
      'Что-то пошло не так. Попробуйте еще раз',
    title:
      (payload && payload.title) || 'Что-то пошло не так. Попробуйте еще раз',
    traceId: payload?.traceId || null,
  };
};

/* eslint-disable-next-line @typescript-eslint/ban-types */
const hasOwnProperty = <X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y,
): obj is X & Record<Y, unknown> => {
  return obj.hasOwnProperty(prop);
};

export const normalizeError = (error: unknown) => {
  let title = SUBMIT_ERROR_TEXT;
  let description = SUBMIT_ERROR_TEXT;
  let errorCode = '';
  let traceId = null;

  if (error && typeof error === 'object') {
    if (hasOwnProperty(error, 'title') && typeof error.title === 'string') {
      title = error.title;
    }

    if (hasOwnProperty(error, 'traceId') && typeof error.traceId === 'string') {
      traceId = error.traceId;
    }

    if (
      hasOwnProperty(error, 'description') &&
      typeof error.description === 'string'
    ) {
      description = error.description;
    }

    if (
      hasOwnProperty(error, 'errorCode') &&
      typeof error.errorCode === 'string'
    ) {
      errorCode = error.errorCode;
    }
  }

  return {
    description,
    title,
    ...(traceId ? { traceId } : {}),
    ...(errorCode ? { errorCode } : {}),
  };
};
