import React from 'react';

import { SvgIcon } from '@material-ui/core';

export const VkPointerIcon = () => (
  <SvgIcon htmlColor="#ffffff" viewBox="0 0 54 56" fontSize="inherit">
    <path
      d="M39.625 6.50003L2.875 22.0609L23.3526 31.7013L51.25 2.75L33.0491 53.375L25.1875 36.6875"
      stroke="#226DFE"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
