import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ tokens, breakpoints }) => ({
  menuItem: {
    width: '100%',
    paddingTop: tokens.spacing['12'],
    paddingBottom: tokens.spacing['12'],
    paddingRight: tokens.spacing['16'],
    paddingLeft: tokens.spacing['16'],

    [breakpoints.down('md')]: {
      fontSize: tokens.typography.variant.body3.fontSize,
    },

    '&:hover': {
      backgroundColor: tokens.colors.bg.greayscale.secondary,
    },
  },
}));
