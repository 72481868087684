import { Type, Transform } from 'class-transformer';
import {
  IsInt,
  IsNumber,
  IsIn,
  IsArray,
  IsString,
  IsDateString,
  IsUUID,
  IsNotEmpty,
  ValidateNested,
  IsOptional,
  IsBoolean,
} from 'class-validator';

export type DateType = string;

export class WorkCalendarDate {
  @IsNotEmpty()
  @IsDateString()
  date: string;

  @IsNotEmpty()
  @IsString()
  @IsIn(['workday', 'weekend', 'holiday'])
  type: 'workday' | 'weekend' | 'holiday';
}

export class WorkCalendar {
  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => WorkCalendarDate)
  dates: WorkCalendarDate[];
}
