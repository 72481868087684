import { openDB, DBSchema, IDBPDatabase } from 'idb';
import { injectable } from 'inversify';

import { Logger } from '@vk-hr-tek/core/logger';

const EVENT_CACHE_TABLE_NAME = 'events' as const;

const EXPIRATION_DURATION = 1000 * 60 * 60 * 24 * 14;

interface AttributeToSave {
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: Record<string, any>;
  timestamp: number;
}

interface EventsDB extends DBSchema {
  [EVENT_CACHE_TABLE_NAME]: {
    key: string;
    value: AttributeToSave | null;
    indexes: { timestamp: number };
  };
}

@injectable()
export class EventCacheService {
  private database = 'eventsData';

  private db: IDBPDatabase<EventsDB>;

  private tableName = EVENT_CACHE_TABLE_NAME;

  constructor(private logger: Logger) {}

  async init(tableName = this.tableName) {
    try {
      this.db = await openDB<EventsDB>(this.database, 1, {
        upgrade(db: IDBPDatabase<EventsDB>) {
          if (!db.objectStoreNames.contains(tableName)) {
            const store = db.createObjectStore(tableName, {
              keyPath: 'id',
            });
            store.createIndex('timestamp', 'timestamp');
          }
        },
      });
      await this.clearTableIfExpired(tableName);
    } catch (error) {
      this.logger.error(error, {
        tags: {
          vkdoc_error_type: 'idb',
        },
      });
      return null;
    }
  }

  async clearTableIfExpired(tableName = this.tableName) {
    try {
      const tx = this.db.transaction(tableName, 'readwrite');
      const range = IDBKeyRange.upperBound(Date.now() - EXPIRATION_DURATION);
      const cursor = await tx
        .objectStore(tableName)
        .index('timestamp')
        .openCursor(range);

      if (cursor) {
        await this.db.clear(tableName);
      }
      return null;
    } catch (error) {
      this.logger.error(error, {
        tags: {
          vkdoc_error_type: 'idb',
        },
      });
      return null;
    }
  }

  public async getValue(id: string, tableName = this.tableName) {
    try {
      await this.init(tableName);
      const tx = this.db?.transaction(tableName, 'readonly');
      const store = tx?.objectStore(tableName);
      return (await store?.get(id)) || null;
    } catch (error) {
      this.logger.error(error, {
        tags: {
          vkdoc_error_type: 'idb',
        },
      });
      return null;
    }
  }

  public async saveValue(
    id: string,
    value: AttributeToSave,
    tableName = this.tableName,
  ) {
    try {
      await this.init(tableName);
      const tx = this.db.transaction(tableName, 'readwrite');
      const store = tx.objectStore(tableName);
      return await store.put({ ...value, id });
    } catch (error) {
      this.logger.error(error, {
        tags: {
          vkdoc_error_type: 'idb',
        },
      });
      return null;
    }
  }

  public async deleteValue(id: string, tableName = this.tableName) {
    try {
      await this.init(tableName);
      const tx = this.db?.transaction(tableName, 'readwrite');
      const store = tx?.objectStore(tableName);
      return await store?.delete(id);
    } catch (error) {
      this.logger.error(error, {
        tags: {
          vkdoc_error_type: 'idb',
        },
      });
      return null;
    }
  }

  public async clearTable(tableName = this.tableName) {
    try {
      await this.init(tableName);
      return await this.db.clear(tableName);
    } catch (error) {
      this.logger.error(error, {
        tags: {
          vkdoc_error_type: 'idb',
        },
      });
      return null;
    }
  }
}
