import { IsString, IsNotEmpty, Length, Matches, IsUUID } from 'class-validator';

import { IsSnils } from '@vk-hr-tek/core/validation';

import { REQUIRED_FIELD_VALIDATION_MESSAGE } from '../../auth.constants';

export class RegisterWithVerifiedPhoneDto {
  @IsNotEmpty({
    message: 'Вы забыли ввести пароль',
    groups: ['form'],
  })
  @Length(8, 128, {
    message: 'Должен содержать не менее 8 символов',
    groups: ['form'],
  })
  @Matches(/^[A-Za-z0-9!#{}@()%^\[\]~$\-_]+$/, {
    message: 'Должен содержать только буквы, цифры или символы !#{}@$()%^[]~-_',
    groups: ['form'],
  })
  @Matches(/[A-Z]/, {
    message: 'Должен содержать не менее 1 заглавной буквы',
    groups: ['form'],
  })
  @Matches(/[a-z]/, {
    message: 'Должен содержать не менее 1 строчной буквы',
    groups: ['form'],
  })
  @Matches(/[0-9\^\[\]~!#{}\/@\$()`%]/, {
    message: 'Должен содержать не менее 1 цифры либо 1 символа',
    groups: ['form'],
  })
  @IsString({
    message: 'Вы забыли ввести пароль',
    groups: ['form'],
  })
  password: string;

  @IsNotEmpty(REQUIRED_FIELD_VALIDATION_MESSAGE)
  @IsSnils({
    message: 'Неверный СНИЛС',
  })
  @IsString()
  snils: string;

  @IsNotEmpty(REQUIRED_FIELD_VALIDATION_MESSAGE)
  @IsUUID()
  @IsString()
  inviteCode: string;
}
