/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { useField } from 'react-final-form';

import { FileInput as Input } from '../../input';

type ValidateType = (
  value: any,
  allValues?: any,
) => (string | undefined) | Promise<string | undefined>;

type FileInputProps = {
  label: string;
  tooltip?: React.ReactNode;
  name: string;
  variant?: 'input' | 'preview';
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  loading?: boolean;
  validate?: ValidateType[];
  cache?: (file: File) => Promise<string>;
  fullWidth?: boolean;
  bannerText?: string;
  helperText?: string;
  testId?: string;
};

export const FileInput = ({
  label,
  tooltip,
  name,
  variant = 'input',
  placeholder,
  required = false,
  disabled = false,
  loading = false,
  validate,
  cache,
  fullWidth,
  bannerText,
  helperText,
  testId,
}: FileInputProps) => {
  const composeValidators =
    (validators: ValidateType[]) =>
    async (
      value: Record<string, any> | any,
      allValues: Record<string, any>,
    ) => {
      const result = await validators.reduce(
        (error: any, validator) => error || validator(value, allValues),
        undefined,
      );
      return result;
    };

  const { input, meta } = useField(name, {
    validate: composeValidators(validate || []),
  });

  return (
    <Input
      {...input}
      label={label}
      value={input.value}
      onChange={input.onChange}
      variant={variant}
      tooltip={tooltip}
      required={required}
      error={meta.touched && meta.error}
      errorText={meta.error}
      helperText={helperText}
      disabled={disabled}
      loading={loading}
      onBlur={input.onBlur}
      cache={cache}
      fullWidth={fullWidth}
      placeholder={placeholder}
      bannerText={bannerText}
      testId={testId}
    />
  );
};
