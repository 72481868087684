import { useEffect, useRef } from 'react';

import { useQuery } from '@vk-hr-tek/core/query';
import { showError, showNotification } from '@vk-hr-tek/core/notifications';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks/media';

import { GetPolicyDto } from '../dto';
import {
  getEmployeeFilteredPolicies,
  selectFilteredPoliciesEmployee,
  selectPoliciesIdWithInProgressStatus,
  setAcceptInProgressIds,
} from '../../policy/slice';
import { useDispatch, useSelector } from '../../app/hooks';

const TIME_BETWEEN_POLLS = 10000;

export const usePolicyPoll = () => {
  const dispatch = useDispatch();

  const isDesktop = useIsDesktop();

  const intervalRef = useRef<null | NodeJS.Timer>(null);

  const policiesIdWithInProgressStatus = useSelector(
    selectPoliciesIdWithInProgressStatus,
  );

  const filteredPolicies = useSelector(selectFilteredPoliciesEmployee);

  const [filter] = useQuery<GetPolicyDto>(GetPolicyDto);

  useEffect(() => {
    if (intervalRef.current && !!filteredPolicies.length) {
      clearInterval(intervalRef.current);
    }

    if (policiesIdWithInProgressStatus.length && filteredPolicies.length) {
      const intervalId = setInterval(async () => {
        const onlyInProgressPoliciesIds = filteredPolicies
          .filter(({ status }) => status === 'in_progress')
          .map(({ policyVersionId, employeeId }) => ({
            policyVersionId,
            employeeId,
          }));

        dispatch(setAcceptInProgressIds(onlyInProgressPoliciesIds));
      }, TIME_BETWEEN_POLLS);

      intervalRef.current = intervalId;

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [
    dispatch,
    filteredPolicies,
    isDesktop,
    policiesIdWithInProgressStatus,
    filter,
  ]);

  useEffect(() => {
    if (policiesIdWithInProgressStatus.length) {
      dispatch(
        getEmployeeFilteredPolicies({
          dto: filter,
          policies: policiesIdWithInProgressStatus.map(
            ({ policyVersionId, employeeId }) => ({
              policyVersionId,
              employeeId,
            }),
          ),
        }),
      );
    }
  }, [dispatch, filter, policiesIdWithInProgressStatus]);

  useEffect(() => {
    if (filteredPolicies.length) {
      filteredPolicies.forEach(({ status, name }) => {
        if (status === 'signed') {
          dispatch(showNotification(`Ознакомление подтверждено! ${name}`));
        } else if (status === 'error') {
          dispatch(
            showError(
              `Ознакомление не подтверждено! Попробуйте${
                isDesktop ? ', пожалуйста,' : ''
              } еще раз ${name}`,
            ),
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filteredPolicies]);
};
