import { injectable, inject } from 'inversify';

import { History } from '@vk-hr-tek/core/history';

import { OrganizationRouter } from '../../organization/types';

@injectable()
export class OrganizationRouterService implements OrganizationRouter {
  constructor(@inject(History) private history: History) {}

  goCreateUnit(query: string) {
    this.history.push({
      pathname: '/organization/create-unit',
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }

  goEditUnit(query: string) {
    this.history.push({
      pathname: '/organization/edit-unit',
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }

  goToList(query?: string) {
    if (!query || query === '?') {
      this.history.push('/organization');
      return;
    }

    this.history.push({
      pathname: '/organization',
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }
}
